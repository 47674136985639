import React, { useEffect, useState } from 'react';
import { Header } from '../../common/Header/Header';
import GoogleAuth from '../../../images/google-auth.svg';
import { GoogleLogin } from '@react-oauth/google';

function EmailAuth() {
  const [tokenClient, setTokenClient] = useState(null);

  const CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_AUTH_API_KEY;
  useEffect(() => {
    const DISCOVERY_DOC =
      'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest';
    const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';

    let gapiInited = false;
    let gisInited = false;

    const gapiLoaded = () => {
      window.gapi.load('client', initializeGapiClient);
    };

    const initializeGapiClient = async () => {
      await window.gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
      gapiInited = true;
      maybeEnableButtons();
    };

    const gisLoaded = () => {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
      setTokenClient(client);
      gisInited = true;
      maybeEnableButtons();
    };

    const maybeEnableButtons = () => {
      if (gapiInited && gisInited) {
        document.getElementById('authorize_button').style.visibility =
          'visible';
      }
    };

    const loadScripts = () => {
      const gapiScript = document.createElement('script');
      gapiScript.src = 'https://apis.google.com/js/api.js';
      gapiScript.async = true;
      gapiScript.defer = true;
      gapiScript.onload = gapiLoaded;
      document.body.appendChild(gapiScript);

      const gisScript = document.createElement('script');
      gisScript.src = 'https://accounts.google.com/gsi/client';
      gisScript.async = true;
      gisScript.defer = true;
      gisScript.onload = gisLoaded;
      document.body.appendChild(gisScript);
    };

    loadScripts();
  }, []);

  const handleAuthClick = async () => {
    if (!tokenClient) return;

    tokenClient.callback = async resp => {
      if (resp.error !== undefined) {
        throw resp;
      }
      document.getElementById('signout_button').style.visibility = 'visible';
      document.getElementById('authorize_button').innerText = 'Refresh';
      // await listLabels();
    };

    if (window.gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      tokenClient.requestAccessToken({ prompt: '' });
    }
  };

  const handleSignoutClick = () => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      window.google.accounts.oauth2.revoke(token.access_token);
      window.gapi.client.setToken('');
      document.getElementById('content').innerText = '';
      document.getElementById('authorize_button').innerText = 'Authorize';
      document.getElementById('signout_button').style.visibility = 'hidden';
    }
  };

  // const listLabels = async () => {
  //   let response;
  //   try {
  //     response = await window.gapi.client.gmail.users.labels.list({
  //       userId: 'me',
  //     });
  //   } catch (err) {
  //     document.getElementById('content').innerText = err.message;
  //     return;
  //   }
  //   const labels = response.result.labels;
  //   if (!labels || labels.length === 0) {
  //     document.getElementById('content').innerText = 'No labels found.';
  //     return;
  //   }
  //   const output = labels.reduce((str, label) => `${str}${label.name}\n`, 'Labels:\n');
  //   document.getElementById('content').innerText = output;
  // };

  return (
    <>
      <Header />
      <div style={{ display: 'flex', marginTop: '5vh' }}>
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h2>Autorizar Gmail</h2>
          <h4>Configure una cuenta de gmail para validar pagos con Zelle</h4>
          <div
            id="authorize_button"
            onClick={handleAuthClick}
            style={{
              visibility: 'hidden',
              backgroundColor: '#ed8917',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '50px',
              cursor: 'pointer',
              marginTop: '5vh',
              width: '70%',
            }}
          >
            <span
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
              }}
            >
              <img src={GoogleAuth} alt="Google Auth" />{' '}
              <span>Iniciar sesión con Google</span>
            </span>
          </div>
          <div
            id="signout_button"
            style={{
              backgroundColor: '#ed8917',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '50px',
              cursor: 'pointer',
              marginTop: '1vh',
              width: '40%',
              fontSize: '1.1rem',
              fontWeight: 'bold',
            }}
            onClick={handleSignoutClick}
          >
            Cerrar Sesión
          </div>
          <pre id="content" style={{ whiteSpace: 'pre-wrap' }}></pre>
        </div>
      </div>
    </>
  );
}

export default EmailAuth;
