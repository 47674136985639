import { fixResponseJSON, filterParams } from '../assets/helpers/funciones';
import { authInstance } from './axios';
import axios from 'axios';
let url = process.env.REACT_APP_API_URL;

export const membershipService = {
  getMembership: async searchParam => {
    const response = await authInstance
      .get(`${url}/memberships/search?searchParam=${searchParam}&limit=10`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  selfRenew: async (data, membershipId) => {
    const response = await authInstance
      .post(`${url}/memberships/${membershipId}/selfRenewal`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  getDocumentsData: async data => {
    const response = await authInstance
      .post(`${url}/memberships/getDocumentsData`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  ZipRCV: async params => {
    axios({
      url:
        `${url}/memberships/downloadRcvDocuments?` +
        new URLSearchParams(filterParams(params)),
      method: 'GET',
      responseType: 'blob', // Set the response type to blob
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
      },
    })
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'zip-carga-de-flotas.zip'); // Set the desired file name
        document.body.appendChild(link);
        link.click();
        return fixResponseJSON(response);
      })
      .catch(error => {
        console.error('Error downloading zip file:', error);
      });
  },

  getMembershipById: async (membershipId, moduleType) => {
    const response = await authInstance
      .get(`${url}/memberships/${moduleType}/${membershipId}`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  calculateRenewalPrice: async (params, membershipId) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
      },
      method: 'GET',
    };

    const response = await fetch(
      `${url}/memberships/${membershipId}/calculateRenewal?` +
        new URLSearchParams(filterParams(params)),
      myInit
    )
      .then(async response => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  getLastServices: async membershipId => {
    const response = await authInstance
      .get(`${url}/services/getLatest?membership=${membershipId}`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getCancelReason: async membershipType => {
    const response = await authInstance
      .get(
        `${url}/cancellationReasons/search?type=${membershipType}&limit=1000`
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createMembership: async data => {
    const response = await authInstance
      .post(`${url}/memberships/create`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  sendDocuments: async memebershipId => {
    const response = await authInstance
      .post(`${url}/memberships/${memebershipId}/sendDocuments`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getMembershipsByStatus: async params => {
    const response = await authInstance
      .get(
        `${url}/memberships/findAll?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getMembershipsCountByCondition: async params => {
    const response = await authInstance
      .get(
        `${url}/memberships/countByConditions?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getAccountsReceivable: async params => {
    const response = await authInstance
      .get(
        `${url}/memberships/getAccountsReceivable?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getMembershipsCountRenewal: async (params, data) => {
    const response = await authInstance
      .post(
        `${url}/memberships/countRenewals?` +
          new URLSearchParams(filterParams(params)),
        data
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getRCVs: async params => {
    const response = await authInstance
      .get(
        `${url}/memberships/getRcv?` + new URLSearchParams(filterParams(params))
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  upgradeMembership: async (data, memebershipId) => {
    const response = await authInstance
      .put(`${url}/memberships/${memebershipId}/upgrade`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  addRCV: async (data, memebershipId) => {
    const response = await authInstance
      .put(`${url}/memberships/${memebershipId}/addRCV`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  upgradeMembershipPaymet: async (data, memebershipId) => {
    const response = await authInstance
      .put(`${url}/memberships/${memebershipId}/payments`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  changeCustomer: async data => {
    const response = await authInstance
      .post(`${url}/memberships/changeCustomer`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  changeVehicle: async data => {
    const response = await authInstance
      .post(`${url}/memberships/changeVehicle`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getAdminTableData: async params => {
    const response = await authInstance
      .get(
        `${url}/payments/exportableAdmin?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  getMembershipsToBeRenewed: async (params, data) => {
    const response = await authInstance
      .post(
        `${url}/memberships/findAllRenewal?` +
          new URLSearchParams(filterParams(params)),
        data
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getMembershipCountByStatus: async searchParam => {
    const response = await authInstance
      .get(`${url}/memberships/totalByStatus`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updateMembershipStatus: async membershipId => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
    };

    const response = await fetch(
      `${url}/memberships/${membershipId}/updateStatus`,
      myInit
    )
      .then(async response => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updatePaymentTypeInRenewal: async (membershipId, data) => {
    const response = await authInstance
      ?.put(`${url}/memberships/${membershipId}/paymentType`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getSalesMembershipById: async membershipId => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'GET',
    };

    const response = await fetch(
      `${url}/memberships/sales/${membershipId}`,
      myInit
    )
      .then(async response => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updateImages: async (file, membershipId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: file,
    };

    const response = await fetch(
      `${url}/memberships/${membershipId}/updateImages`,
      myInit
    )
      .then(async response => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updateMembership: async (data, membershipId) => {
    const response = await authInstance
      .put(`${url}/memberships/${membershipId}/update`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  approveMembership: async membershipId => {
    const response = await authInstance
      .post(`${url}/memberships/${membershipId}/approve`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
  cancelMembership: async (data, membershipId) => {
    const response = await authInstance
      .put(`${url}/memberships/${membershipId}/cancel`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  renewMembership: async membershipId => {
    const response = await authInstance
      .post(`${url}/memberships/${membershipId}/renew`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getStatuses: async () => {
    const response = await authInstance
      .get(`${url}/memberships/status`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  reativateMembership: async membershipId => {
    const response = await authInstance
      .put(`${url}/memberships/${membershipId}/reactivate`, {})
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
  rescueMembership: async membershipId => {
    const response = await authInstance
      .post(`${url}/memberships/${membershipId}/rescue`, {})
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  removeCode: async membershipId => {
    const response = await authInstance
      .put(`${url}/memberships/${membershipId}/removeCode`, {})
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updatePaymentDues: async (data, membershipId) => {
    const response = await authInstance
      .put(`${url}/memberships/${membershipId}/updatePaymentDues`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  updateFromGrid: async (membershipId, data) => {
    const response = await authInstance
      .put(`${url}/memberships/${membershipId}/updateFromGrid`, data)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  renewFilterOptions: async (params, data) => {
    const response = await authInstance
      .post(
        `${url}/memberships/renewalFilterOptions?` +
          new URLSearchParams(filterParams(params)),
        data
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },
};
