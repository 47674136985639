import { useState, useEffect } from "react";
import { Header } from "../../common/Header/Header.jsx";
import { SelectPerPage } from "../../common/Forms/Input/Input";
import { notify } from "../../../assets/helpers/toast";
import { salesRoles } from "../../../assets/helpers/roles.js";
import { dbHistoryService } from "../../../services/dbHistoryService.js";
import { useLocation } from "react-router-dom";
import { FilterDatePicker } from "../../common/Forms/Input/Input";
import moment from "moment";
import { capitalize, displayChange } from "../../../assets/helpers/funciones";
import Pagination from "@material-ui/lab/Pagination";
import "./UpdateHistory.scss";
import authService from "../../../services/authService.js";

const itemsPerpage = [10, 25, 50, 100, 500];

export const UpdateHistory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const [editHistory, setEditHistory] = useState({
    isOpen: false,
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    isLoading: true,
  });

  const handleDateFrom = (e) => {
    setEditHistory((prevState) => {
      return {
        ...prevState,
        dateFrom: e,
      };
    });
  };
  const handleDateTo = (e) => {
    setEditHistory((prevState) => {
      return {
        ...prevState,
        dateTo: e,
      };
    });
  };

  const selectitemsPerpage = (event) => {
    setEditHistory((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setEditHistory((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * editHistory?.limit,
      };
    });
  };

  const getHistory = () => {
    let params = {
      page: editHistory?.page,
      limit: editHistory?.limit,
      from: editHistory?.dateFrom,
      to: editHistory?.dateTo,
    };

    if (type) {
      params.module = type;
    }

    dbHistoryService
      .getMembershipHistory("", params)
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setEditHistory((prevState) => {
            return {
              ...prevState,
              data: response?.data?.history,
              count: response?.data?.total,
            };
          });
        } else {
          notify(`${response.message}`, "error");
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getHistory();
  }, [
    editHistory?.page,
    editHistory?.offset,
    editHistory?.limit,
    editHistory?.type,
    editHistory?.dateFrom,
    editHistory?.dateTo,
  ]);

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Historial de Actualizaciones: {editHistory?.count}
          </h1>
        </div>
        <div className="breadcrumb-container">
          <div></div>
          <div className="exportables-buttons-container">
            <div style={{ width: "100%" }} className="row">
              <>
                <FilterDatePicker
                  bootstrap={"col-12 col-md-2"}
                  label={"Desde"}
                  type={"date"}
                  name={"dateFrom"}
                  placeholder={""}
                  onChange={handleDateFrom}
                  value={new Date(editHistory?.dateFrom)}
                />
                <FilterDatePicker
                  bootstrap={"col-12 col-md-2"}
                  label={"Hasta"}
                  type={"date"}
                  name={"dateTo"}
                  placeholder={""}
                  onChange={handleDateTo}
                  value={new Date(editHistory?.dateTo)}
                />
              </>
              <div
                style={{
                  marginTop: "24px",
                  marginLeft: "auto",
                  width: "350px",
                }}
              >
                {![
                  salesRoles?.SALES_FARMER,
                  salesRoles?.SALES_HUNTER,
                  salesRoles?.SALES_COUNTER,
                  salesRoles?.SALES_ADMIN_AUX,
                ].includes(authService?.getUserType()) && (
                  <SelectPerPage
                    label={"Registros por página"}
                    options={itemsPerpage}
                    value={editHistory?.limit}
                    onChange={selectitemsPerpage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <table style={{ textAlign: "center" }} className="table">
          <tr>
            <td>Fecha y hora del cambio</td>
            <td>Asesor</td>
            <td>N. Orden</td>
            <td>Campo modificado</td>
            <td>Dato anterior</td>
            <td>Dato actualizado</td>
          </tr>
          {editHistory?.data?.length > 0 &&
            editHistory?.data?.map((d) => (
              <tr key={d?._id}>
                <td>
                  {d?.date ? moment(d?.date).format("DD/MM/YYYY LTS") : ""}
                </td>
                <td>
                  {capitalize(d?.user?.firstName) +
                    " " +
                    capitalize(d?.user?.lastName)}
                </td>
                <td>{d?.memId?.membershipId}</td>
                <td>
                  {d?.changes?.map((c) => (
                    <>
                      {c?.field} <br />
                    </>
                  ))}
                </td>
                <td>
                  {d?.changes?.map((c) => (
                    <>
                      {displayChange(c, c?.field, "from")} <br />
                    </>
                  ))}
                </td>
                <td>
                  {d?.changes?.map((c) => (
                    <>
                      {displayChange(c, c?.field, "to")} <br />
                    </>
                  ))}
                </td>
              </tr>
            ))}
          {editHistory?.data?.length === 0 && (
            <tr>
              <td colSpan="6">No hay registros</td>
            </tr>
          )}
        </table>
        <div className="pagination-container">
          <Pagination
            count={Math.ceil(editHistory?.count / editHistory?.limit)}
            variant="outlined"
            shape="rounded"
            page={editHistory?.page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};
