import React, { useState } from 'react';
import threedots from '../../../images/threedots.svg';
import logoGold from '../../../images/logoGold.svg';
import rideryLogo from '../../../images/rideryLogo.svg';
import membershipRetro from '../../../images/membershipRetro.svg';
import membershipGoldPLUS from '../../../images/membershipGoldPLUS.svg';
import logoClassic from '../../../images/logoClassic.svg';
import logoPlus from '../../../images/logoPlus.svg';
import logoMotoRider from '../../../images/logoMotoRider.png';
import { AltButton, ActionButton } from '../Buttons/Buttons';
import {
  paintCard,
  exists,
  capitalize,
} from '../../../assets/helpers/funciones';
import {
  membershipCodes,
  planStatus,
  enumStatus,
} from '../../../assets/helpers/options';
import { caseType } from '../../../assets/helpers/options';
import { SMSServiceLogo } from '../../views/Incidence/IndicenceButtons';
import moment from 'moment';
import './Cards.scss';

export const MembershipLogo = (props) => {
  const { code } = props;


  if (code === membershipCodes.RIDERY) {
    return (
      <img className="case-membership-logo" src={rideryLogo} alt={'logo'}></img>
    );
  }
  if (code === membershipCodes.GOLDENP) {
    return (
      <img className="case-membership-logo" src={membershipGoldPLUS} alt={'logo'}></img>
    );
  }
  if (code === membershipCodes.RETRO) {
    return (
      <img className="case-membership-logo" src={membershipRetro} alt={'logo'}></img>
    );
  }
  if (code === membershipCodes.GOLD) {
    return (
      <img className="case-membership-logo" src={logoGold} alt={'logo'}></img>
    );
  }
  if (code === membershipCodes.PLUS) {
    return (
      <img className="case-membership-logo" src={logoPlus} alt={'logo'}></img>
    );
  }

  if (code === membershipCodes.CLASSIC) {
    return (
      <img
        className="case-membership-logo"
        src={logoClassic}
        alt={'logo'}
      ></img>
    );
  }
  if (code === membershipCodes.MOTORIDER) {
    return (
      <img
        className="case-membership-logo"
        src={logoMotoRider}
        alt={'logo'}
      ></img>
    );
  } else return null;
};

export const PlanStatus = (props) => {
  const { label } = props;
  let status = 'false';
  let newLabel = label;

  if ([planStatus.ACTIVE, planStatus.TO_BE_RENEW].includes(label)) {
    status = 'true';
    newLabel = 'Activo';
  }
  if ([planStatus.EXPIRED, planStatus.TO_BE_EXPIRED].includes(label)) {
    status = 'false';
    newLabel = 'Vencido';
  }

  if ([planStatus.WAITING].includes(label)) {
    status = 'false';
    newLabel = label;
  }

  return (
    <div className={`plan-card-status plan-card-status-${status}`}>
      {capitalize(newLabel)}
    </div>
  );
};

export const IncidenceColumn = (props) => {
  const {
    cases,
    options,
    actionButtons,
    statusButtons,
    title,
    caseStatus,
    status,
    handleSMSModal,
  } = props;

  if (caseStatus !== enumStatus.FINISHED && status == enumStatus.FINISHED) {
    return null;
  }

  if (caseStatus && caseStatus !== status) {
    return null;
  } else {
    return (
      <div className="vertical-incidence-container">
        <IndicenceHeaderCard title={title} cases={cases} />
        <div className="vertical-incidence-break-container">
          {cases.map((c) => (
            <IncidenceCards
              key={c?._id}
              _case={c}
              options={options}
              actionButtons={actionButtons}
              statusButtons={statusButtons}
              handleSMSModal={handleSMSModal}
            />
          ))}
        </div>
      </div>
    );
  }
};

export const IndicenceHeaderCard = (props) => {
  const { title, cases } = props;

  return (
    <div
      className={`facturacion-component card indicator-component-header incidence-card-${paintCard(
        cases
      )}`}
    >
      <h6>{title}</h6>
      <div className="facturacion-component-quantity">
        <span>{cases?.length}</span>
      </div>
    </div>
  );
};

export const IncidenceCards = (props) => {
  let { _case, options, statusButtons, actionButtons, handleSMSModal } = props;
  const [showOptions, setShowOptions] = useState(false);

  if (!_case?.lastService?.provider?.isInternal) {
    options = options?.filter((o) => o?.name !== 'Cambiar Gruero');
  }

  return (
    <>
      <div className={`card incidence-card-${paintCard([_case])}`}>
        <div className="incidence-component-header">
          <h6>Nro. {_case?.caseId}</h6>
          <div className="incidence-component-code-header">
            <h6 style={{ textAlign: 'right' }}>
              {_case?.caseType === caseType.AFFILIATED ? (
                <>
                  {exists(_case?.membership?.code?.prefixCode)}-
                  {exists(_case?.membership?.saleChannel?.shortName)}-
                  {exists(_case?.membership?.membershipId)}
                </>
              ) : (
                'Cliente no Afiliado'
              )}

              <br />
              {_case?.caseType}
            </h6>
            <StatusHandler
              _case={_case}
              options={options}
              show={showOptions}
              handleOpen={setShowOptions}
            />
          </div>
        </div>
        <br />
        <div className="row card-row-container">
          <div className="col-10">
            <h5>
              {capitalize(
                _case?.membership?.customer?.firstName ||
                _case?.particularCustomer?.firstName
              ) +
                '  ' +
                capitalize(
                  _case?.membership?.customer?.lastName ||
                  _case?.particularCustomer?.lastName
                )}
            </h5>
          </div>
          <div style={{ padding: '0px' }} className="col-2">
            <MembershipLogo code={_case?.membership?.code?.code} />
          </div>
          <div className="col-12">
            <h6 className="incidence-component-vehicle">
              {exists(
                _case?.membership?.vehicle?.plate ||
                _case?.particularCustomer?.vehiclePlate
              )}
              &nbsp;
              {capitalize(
                _case?.membership?.vehicle?.brand ||
                _case?.particularCustomer?.vehicleBrand?.brand
              )}
              &nbsp;
              {capitalize(
                _case?.membership?.vehicle?.model ||
                _case?.particularCustomer?.vehicleModel?.model
              )}
              &nbsp;
              {exists(
                _case?.membership?.vehicle?.year ||
                _case?.particularCustomer?.vehicleYear
              )}
              &nbsp;
              {exists(_case?.particularCustomer?.vehicleColor)}
            </h6>
          </div>
          <br />
          <div className="col-12 incidence-card-text">
            <p>
              <b>Ruta:</b> {capitalize(_case?.originCity?.name)}{' '}
              {_case?.destinyCity?.name
                ? 'a ' + capitalize(_case?.destinyCity?.name)
                : ''}
              <br />
              <b>Avería:</b> {capitalize(_case?.fault?.description)}
              {_case?.lastService?.provider?.contactName && (
                <>
                  <br />
                  <b>
                    Proveedor (
                    {_case?.lastService?.provider?.isInternal
                      ? 'Interno'
                      : 'Externo'}
                    ) :
                  </b>{' '}
                  {capitalize(_case?.lastService?.provider?.contactName)}
                </>
              )}
              {_case?.estimatedTime ? (
                <>
                  <br /> <b>Tiempo estimado:</b>{' '}
                  {exists(_case?.estimatedTime + ' min')}
                </>
              ) : (
                ''
              )}
              <br />
              <b>Operador:</b> {exists(_case?.user?.firstName)}{' '}
              {exists(_case?.user?.lastName)}
            </p>
            <p className="incidence-card-creation-date">
              Última modificación:{' '}
              {exists(
                moment(_case?.lastStage?.updatedAt).format('DD/MM/YYYY LTS')
              )}
              <br /> Creado :{' '}
              {exists(moment(_case?.createdAt).format('DD/MM/YYYY LTS'))}
            </p>
          </div>

          {_case?.lastService?.code && (
            <div className="icon-service-code-container">
              {_case?.lastService?.code && (
                <p>{exists(_case?.lastService?.code)}</p>
              )}
              <SMSServiceLogo
                service={_case?.lastService?.product?.code}
                action={() => handleSMSModal(_case)}
              />
            </div>
          )}
          {_case?.status === enumStatus?.SEARCHING_TOW && (
            <div className="icon-service-code-container">
              {<p></p>}
              <SMSServiceLogo
                service={_case?.lastService?.product?.code}
                action={() => handleSMSModal(_case)}
                grua={'grua'}
              />
            </div>
          )}
          <div
            style={{ marginTop: '12px' }}
            className="buttons-center-container"
          >
            {actionButtons
              ?.filter((a) => a?.status?.includes(_case?.status))
              ?.map((a) => (
                <AltButton
                  key={a?.name}
                  action={() => a?.action(_case)}
                  label={a?.name}
                />
              ))}

            {statusButtons
              ?.filter((a) => a?.status?.includes(_case?.status))
              ?.map((a) => {
                if (_case?.status !== 'Servicio Agendado') {
                  return (
                    <ActionButton
                      key={a?.name}
                      action={() => a?.action(_case, a?.name)}
                      label={a?.name}
                    />
                  );
                }

                if (
                  _case?.status === 'Servicio Agendado' &&
                  _case?.lastService?.provider &&
                  a.name == 'Iniciar Servicio'
                ) {
                  return (
                    <ActionButton
                      key={a?.name}
                      action={() => a?.action(_case, a?.name)}
                      label={a?.name}
                    />
                  );
                }

                if (
                  _case?.status === 'Servicio Agendado' &&
                  !_case?.lastService?.provider &&
                  a.name == 'Asignar grúa'
                ) {
                  return (
                    <ActionButton
                      key={a?.name}
                      action={() => a?.action(_case, a?.name)}
                      label={a?.name}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export const StatusHandler = (props) => {
  const { options, handleOpen, show, _case } = props;

  return (
    <>
      <img src={threedots} alt="threedots" onClick={() => handleOpen(!show)} />
      {show && (
        <div className="incidence-status-options-container">
          {options
            ?.filter((o) => o?.status?.includes(_case?.status))
            ?.map((o) => (
              <div
                key={o.name}
                className="incidence-status-options-option"
                onClick={() => {
                  o.action(_case);
                  handleOpen(false);
                }}
              >
                {o.name}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export const IndicatorsCard = (props) => {
  const { label, src, value } = props;
  return (
    <div className="card indicators-card ">
      <h4>{label}</h4>
      <h3>{value}</h3>
      <div style={{ width: '100%' }} className="buttons-container">
        <span></span>
        <img src={src} alt="src" />
      </div>
    </div>
  );
};

export const OperationCard = (props) => {
  const { src, label, value } = props;

  return (
    <div className="card operations-stat-card">
      <div>
        <img src={src} alt="" />
        <h5>{label}</h5>
      </div>
      <h4>{value}</h4>
    </div>
  );
};

export const SalesCard = (props) => {
  const { redirectTo, src, label, value } = props;

  return (
    <div onClick={redirectTo} className="card sales-card">
      <h4>{label}</h4>
      <h3>{value}</h3>
      <div className="analytics-card-image-container">
        <span></span>
        <img src={src} alt="" />
      </div>
    </div>
  );
};

export const SalesIndicatorCard = (props) => {
  const { label, src, value } = props;
  return (
    <div className="card indicators-card ">
      <h4>{label}</h4>
      <h3>{value}</h3>
      <div style={{ width: '100%' }} className="buttons-container">
        <span></span>
        <img src={src} alt="src" />
      </div>
    </div>
  );
};
