import React, { useEffect, useState, useCallback } from 'react';
import { Input, PaymentSection, FilterDatePicker } from './Input/Input';
import * as forms from '../../../assets/forms/forms';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import {
  phoneCodes,
  locationOptions,
  addressEnum,
  paymentType,
  giftCardType,
  fleetPaymentType,
  loadStrategy,
  planTypes,
  membershipCodes,
} from '../../../assets/helpers/options';
import { Dropzone, FileComponent } from '../Dropzone/Dropzone';
import { idEnums } from '../../../assets/helpers/options';
import { salesRoles } from '../../../assets/helpers/roles';
import { capitalize } from '../../../assets/helpers/funciones';
import { secondQuotaTime } from '../../../assets/helpers/variables';
import { NewPaymentForm } from './NewPaymentForm/NewPaymentForm';
import { vintageYear } from '../../../assets/helpers/const';
import moment from 'moment';
import './Form.scss';
import { notify } from '../../../assets/helpers/toast';
import { useDropzone } from 'react-dropzone';

export const LoginForm = props => {
  const { t, register, errors, action } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.login(t, register, errors).map((l, i) => (
          <Input
            key={i}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        {/* <span className="forgot-password-link">
          <NavLink to={'/forgot-password'}>Olvidé mi contraseña</NavLink>
        </span> */}
        <ActionButton action={action} label={'Ingresar'} />
      </div>
    </form>
  );
};

export const FollowingForm = props => {
  const { t, register, errors, action } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.following(t, register, errors).map((l, i) => (
          <Input
            key={i}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
            data={l}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <ActionButton action={action} label={'Aceptar'} />
      </div>
    </form>
  );
};

export const CancelServiceForm = props => {
  const { register, errors, action, cancel, cancelOptions } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Motivo de cancelación*'}
          type={'select'}
          options={cancelOptions}
          name={'cancelationReason'}
          register={register}
          placeholder={''}
          errors={errors?.cancelationReason?.message}
        />{' '}
        <Input
          bootstrap={'col-12'}
          label={'Observación'}
          type={'textarea'}
          name={'cancelationReasonDescription'}
          register={register}
          placeholder={''}
          errors={errors?.cancelationReasonDescription?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>¿ Esta seguro que desea cancelar el servicio ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const CancelMembershipForm = props => {
  const { register, errors, action, cancel, cancelOptions } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Motivo de anulación*'}
          type={'select'}
          options={cancelOptions}
          name={'annullationReason'}
          register={register}
          placeholder={''}
          errors={errors?.annullationReason?.message}
        />{' '}
        {/* <Input
          bootstrap={'col-12'}
          label={'Observación'}
          type={'textarea'}
          name={'cancelationReasonDescription'}
          register={register}
          placeholder={''}
          errors={errors?.cancelationReasonDescription?.message}
        /> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si, Anular'} />
      </div>
    </form>
  );
};

export const FleetLoadPaymentForm = props => {
  const {
    register,
    errors,
    control,
    fileUploaded,
    setFileUploaded,
    getRootProps,
    getInputProps,
  } = props;
  const quotas = () => {
    let quotas = [];

    for (let i = 1; i < 13; i++) {
      quotas.push({
        name: `${i}`,
        _id: `${i}`,
      });
    }
    return quotas;
  };

  return (
    <form className="form-container">
      <div style={{ maxWidth: '400px' }} className="row">
        <Input
          bootstrap={'col-12'}
          label={'Fecha del pago *'}
          type={'date'}
          name={'date'}
          placeholder={''}
          register={register}
          errors={errors?.date?.message}
          control={control}
          disabled={true}
        />
        {/* <Input
          bootstrap={'col-12'}
          label={'Metodo de Pago *'}
          name={'method'}
          type={'select'}
          options={options?.paymentMethods}
          register={register}
          placeholder={''}
          errors={errors?.method?.message}
        />{' '} */}
        <Input
          bootstrap={'col-12'}
          label={'Número de cuota *'}
          name={'dueNumber'}
          options={quotas()}
          type={'select'}
          register={register}
          placeholder={''}
          errors={errors?.dueNumber?.message}
          disabled={true}
        />{' '}
        {/* <Input
          bootstrap={'col-12'}
          label={'Monto abonado *'}
          name={'number'}
          type={'number'}
          register={register}
          placeholder={''}
          errors={errors?.cancelationReasonDescription?.message}
        /> */}
        <Input
          bootstrap={'col-12'}
          label={'Referencia de pago *'}
          name={'reference'}
          type={'text'}
          register={register}
          placeholder={''}
          errors={errors?.reference?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>Comprobante de pago</h6>
      </div>
      <div className="buttons-container">
        {/* {imageModal?.label && <h6>{imageModal?.label}</h6>} */}
        {fileUploaded ? (
          <FileComponent
            file={fileUploaded}
            remove={() => setFileUploaded('')}
          />
        ) : (
          <Dropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            message={'(PNG, JPG, JEPG, máx. 3MB)'}
          />
        )}
      </div>
    </form>
  );
};

export const FinishServiceForm = props => {
  const { register, errors, action, cancel, options, actionLabel } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Avería real *'}
          type={'select'}
          options={options}
          name={'providerFailureReason'}
          register={register}
          placeholder={''}
          errors={errors?.providerFailureReason?.message}
        />{' '}
        <Input
          bootstrap={'col-12'}
          label={'Explique: *'}
          type={'textarea'}
          name={'providerFailureDescription'}
          register={register}
          placeholder={''}
          errors={errors?.providerFailureDescription?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>¿ Esta seguro que desea finalizar el servicio ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={actionLabel} />
      </div>
    </form>
  );
};

export const NewCraneProviderForm = props => {
  const { t, register, errors, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .CreateExternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Ingresar'} />
      </div>
    </form>
  );
};

export const NewVehicularAssetForm = props => {
  const {
    control,
    register,
    errors,
    action,
    cancel,
    options,
    watch,
    onChange,
  } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Tipo *'}
          type={'select'}
          options={options?.vehicleClassesOptions}
          name={'class'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.class?.message}
          onChange={onChange?.getVehicleBrands}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Marca *'}
          type={'select'}
          options={options?.brandOptions}
          name={'brand'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.brand?.message}
          onChange={onChange?.getVehicleModels}
          disabled={!watch('class') || options?.brandOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Modelo *'}
          type={'select'}
          options={options?.modelOptions}
          name={'model'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.model?.message}
          disabled={!watch('brand') || options?.modelOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Placa *'}
          type={'text'}
          name={'plate'}
          placeholder={''}
          register={register}
          errors={errors?.plate?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Color *'}
          type={'text'}
          name={'color'}
          placeholder={''}
          register={register}
          errors={errors?.color?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Año *'}
          type={'number'}
          name={'year'}
          placeholder={''}
          register={register}
          errors={errors?.year?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Fecha de compra *'}
          type={'date'}
          name={'purchaseDate'}
          placeholder={''}
          register={register}
          errors={errors?.purchaseDate?.message}
          control={control}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Descripción'}
          type={'textarea'}
          name={'description'}
          placeholder={''}
          register={register}
          errors={errors?.description?.message}
        />
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Ingresar'} />
      </div>
    </form>
  );
};

export const EditVehicularAssetForm = props => {
  const {
    control,
    register,
    errors,
    action,
    cancel,
    options,
    watch,
    onChange,
  } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Tipo *'}
          type={'select'}
          options={options?.vehicleClassesOptions}
          name={'class'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.class?.message}
          onChange={e => onChange?.getVehicleBrands(e?.target?.value)}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Marca *'}
          type={'select'}
          options={options?.brandOptions}
          name={'brand'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.brand?.message}
          onChange={e => onChange?.getVehicleModels(e?.target?.value)}
          disabled={!watch('class') || options?.brandOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Modelo *'}
          type={'select'}
          options={options?.modelOptions}
          name={'model'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.model?.message}
          disabled={!watch('brand') || options?.modelOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Placa *'}
          type={'text'}
          name={'plate'}
          placeholder={''}
          register={register}
          errors={errors?.plate?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Color *'}
          type={'text'}
          name={'color'}
          placeholder={''}
          register={register}
          errors={errors?.color?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Año *'}
          type={'number'}
          name={'year'}
          placeholder={''}
          register={register}
          errors={errors?.year?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Fecha de compra *'}
          type={'date'}
          name={'purchaseDate'}
          placeholder={''}
          register={register}
          errors={errors?.purchaseDate?.message}
          control={control}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Descripción'}
          type={'textarea'}
          name={'description'}
          placeholder={''}
          register={register}
          errors={errors?.description?.message}
        />
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const EditExternalProviderForm = props => {
  const { t, register, errors, action, options, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .CreateExternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const EditInternalProviderForm = props => {
  const { t, register, errors, action, options, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .newInternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const NewVehicleForm = props => {
  const { t, control, register, errors, action } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.newVehicle(t, register, errors).map((l, i) => (
          <Input
            key={i}
            title={l?.title}
            bootstrap={l?.bootstrap}
            label={l?.label}
            type={l?.type}
            name={l?.name}
            register={l?.register}
            placeholder={l?.placeholder}
            errors={l?.errors}
            data={l}
            control={control}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={action} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const ApprovalPaymentForm = props => {
  const { t, control, register, errors, action, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.approvalPaymentForm(t, register, errors).map((l, i) => (
          <Input
            key={i}
            title={l?.title}
            bootstrap={l?.bootstrap}
            label={l?.label}
            type={l?.type}
            name={l?.name}
            register={l?.register}
            placeholder={l?.placeholder}
            errors={l?.errors}
            data={l}
            control={control}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const CreateParticularCustomertForm = props => {
  const { t, register, errors, action, options, onChange, getValues } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .CreateParticularCustomertForm(
            t,
            register,
            errors,
            options,
            onChange,
            getValues
          )
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
              disabled={l?.disabled}
            />
          ))}
      </div>
      <div style={{ margin: '24px 0px' }} className="buttons-center-container">
        <AltButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const GiftcardRetailForm = props => {
  const { t, register, control, errors, action, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.giftcardRetailForm(t, register, errors).map((l, i) => (
          <Input
            key={i}
            title={l?.title}
            bootstrap={l?.bootstrap}
            label={l?.label}
            type={l?.type}
            name={l?.name}
            register={l?.register}
            placeholder={l?.placeholder}
            errors={l?.errors}
            data={l}
            control={control}
          />
        ))}
      </div>
      <div style={{ margin: '24px 0px' }} className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Registrar'} />
      </div>
    </form>
  );
};

export const FleetLoadForm = props => {
  const { t, register, control, errors, action, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.FleetLoadForm(t, register, errors).map((l, i) => (
          <Input
            key={i}
            title={l?.title}
            bootstrap={l?.bootstrap}
            label={l?.label}
            type={l?.type}
            name={l?.name}
            register={l?.register}
            placeholder={l?.placeholder}
            errors={l?.errors}
            data={l}
            control={control}
          />
        ))}
      </div>
      <div style={{ margin: '24px 0px' }} className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Registrar'} />
      </div>
    </form>
  );
};

export const GiftcardParticularForm = props => {
  const { t, register, control, errors, action, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.giftcardParticularForm(t, register, errors).map((l, i) => (
          <Input
            key={i}
            title={l?.title}
            bootstrap={l?.bootstrap}
            label={l?.label}
            type={l?.type}
            name={l?.name}
            register={l?.register}
            placeholder={l?.placeholder}
            errors={l?.errors}
            data={l}
            control={control}
          />
        ))}
      </div>
      <div style={{ margin: '24px 0px' }} className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Registrar'} />
      </div>
    </form>
  );
};

export const EditParticularCustomertForm = props => {
  const { t, register, errors, action, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .EditParticularCustomertForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
              disabled={l?.disabled}
            />
          ))}
      </div>
      <div style={{ margin: '24px 0px' }} className="buttons-center-container">
        <AltButton action={action} label={'Editar'} />
      </div>
    </form>
  );
};

// export const ChargeForm = (props) => {
//   const { t, register, errors, action, cancel } = props;

//   return (
//     <form className="form-container">
//       <div className="row">
//         {forms.Charge(t, register, errors).map((l, i) => (
//           <Input
//             key={i}
//             title={l?.title}
//             bootstrap={l?.bootstrap}
//             label={l?.label}
//             type={l?.type}
//             name={l?.name}
//             register={l?.register}
//             placeholder={l?.placeholder}
//             errors={l?.errors}
//             data={l}
//           />
//         ))}
//       </div>
//       <div className="buttons-center-container">
//         <AltButton action={cancel} label={'Cancelar'} />
//         <ActionButton action={action} label={'Guardar'} />
//       </div>
//     </form>
//   );
// };

export const NewInternalProviderForm = props => {
  const { t, register, errors, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .newInternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const RegisterForm = props => {
  const { t, register, errors } = props;

  return (
    <form>
      <div className="row">
        {forms.register(t, register, errors).map((l, i) => (
          <Input
            key={l.name}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
          />
        ))}
      </div>
    </form>
  );
};

export const ScheduleServicesForm = props => {
  const { t, register, errors, action, control } = props;

  return (
    <form>
      <div className="row">
        {forms.scheduleService(t, register, errors).map((l, i) => (
          <Input
            key={l.name}
            data={l}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
            control={control}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <ActionButton action={action} label={'Agendar servicio'} />
      </div>
    </form>
  );
};

export const CreateParticularCaseForm = props => {
  const {
    register,
    handleSubmit,
    errors,
    onChange,
    options,
    watch,
    openMapModal,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-5'}
              label={'Dirección de origen *'}
              type={'select'}
              options={options?.addressOption}
              name={'originLocationStatus'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.originLocationStatus?.message}
              onChange={e => onChange?.selectGarage(e, 'originState')}
            />
            {
              <Input
                bootstrap={'col-4'}
                label={'Ubicacion *'}
                type={'select'}
                options={locationOptions}
                name={'isOriginBasement'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.isOriginBasement?.message}
                disabled={[addressEnum.GARAGE].includes(
                  watch('originLocationStatus')
                )}
              />
            }
            {[addressEnum.OWN, addressEnum.OTHER].includes(
              watch('originLocationStatus')
            ) && watch('isOriginBasement') === 'true' ? (
              <Input
                bootstrap={'col-3'}
                label={'Nivel *'}
                type={'number'}
                name={'basementLevel'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.basementLevel?.message}
              />
            ) : null}
          </div>
          <Input
            bootstrap={'col-12'}
            label={'Estado (Origen)*'}
            type={'select'}
            options={options?.statesOptions}
            name={'originState'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originState?.message}
            onChange={e => onChange?.getCities(e, 'originState')}
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Ciudad (Origen)*'}
            type={'select'}
            options={options?.originCitiesOptions}
            name={'originCity'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originCity?.message}
            onChange={e => onChange?.forceRecalulateDistance(e)}
            disabled={
              !watch('originState') ||
              options?.originCitiesOptions.length === 0 ||
              [addressEnum.GARAGE].includes(watch('originLocationStatus'))
            }
          />
          <Input
            bootstrap={'col-12'}
            label={'Dirección (Origen)*'}
            type={'textarea'}
            name={'originAddress'}
            placeholder={''}
            register={register}
            errors={errors?.originAddress?.message}
            onChange={e => onChange?.forceRecalulateDistance(e)}
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Punto de referencia (Origen)'}
            type={'textarea'}
            name={'originReferencePoint'}
            placeholder={''}
            register={register}
            errors={errors?.originReferencePoint?.message}
          />{' '}
        </div>
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-3'}
              label={'Teléfono *'}
              type={'select'}
              options={phoneCodes}
              name={'contactNumberPrefix'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumberPrefix?.message}
            />{' '}
            <Input
              bootstrap={'col-9'}
              label={''}
              type={'number'}
              name={'contactNumber'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumber?.message}
            />
            <Input
              bootstrap={'col-12'}
              label={'Avería reportada *'}
              type={'select'}
              options={options?.faultsOptions}
              name={'customerFailureReason'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureReason?.message}
            />
            <Input
              bootstrap={'col-12'}
              label={'Comentario adicional (Avería)'}
              type={'textarea'}
              name={'customerFailureDescription'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureDescription?.message}
            />
            <Input
              bootstrap={'col-12'}
              subLabel="Sin dirección destino"
              type={'checkbox'}
              name={'withOutDestinyAddress'}
              placeholder={''}
              register={register}
              errors={errors?.withOutDestinyAddress?.message}
              onChange={onChange?.clearDestinyData}
            />
          </div>
        </div>
        {!watch('withOutDestinyAddress') && (
          <div className="col-12 col-md-4">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Dirección de Destino *'}
                type={'select'}
                options={options?.addressOption}
                name={'destinyLocationStatus'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyLocationStatus?.message}
                onChange={e => onChange?.selectGarage(e, 'destinyState')}
              />

              <Input
                bootstrap={'col-12'}
                label={'Estado (Destino)*'}
                type={'select'}
                options={options?.statesOptions}
                name={'destinyState'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyState?.message}
                onChange={e => onChange?.getCities(e, 'destinyState')}
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />
              <Input
                bootstrap={'col-12'}
                label={'Ciudad (Destino)*'}
                type={'select'}
                options={options?.destinycitiesOptions}
                name={'destinyCity'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyCity?.message}
                onChange={e => onChange?.forceRecalulateDistance(e)}
                disabled={
                  !watch('destinyState') ||
                  options?.destinycitiesOptions.length === 0 ||
                  [addressEnum.GARAGE].includes(watch('destinyLocationStatus'))
                }
              />
              <Input
                bootstrap={'col-12'}
                label={'Dirección (Destino)*'}
                type={'textarea'}
                name={'destinyAddress'}
                placeholder={''}
                register={register}
                errors={errors?.destinyAddress?.message}
                onChange={e => onChange?.forceRecalulateDistance(e)}
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />

              <div className="buttons-center-container">
                {/* <AltButton label={'Ajustar ubicación'} /> */}
                <AltButton label={'Calcular distancia'} action={openMapModal} />
              </div>
              <Input
                bootstrap={'col-12'}
                label={'Distancia de origen a destino (Km)*'}
                type={'number'}
                name={'caseKm'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.caseKm?.message}
                disabled={true}
              />
            </div>
          </div>
        )}
        <div
          className="buttons-center-container"
          style={{ margin: '0px 0px 48px 0px' }}
        >
          <AltButton label={'Guardar'} action={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export const EditParticularCaseForm = props => {
  const {
    register,
    handleSubmit,
    errors,
    onChange,
    options,
    watch,
    openMapModal,
    disabled,
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-5'}
              label={'Dirección de origen*'}
              type={'select'}
              options={options?.addressOption}
              name={'originLocationStatus'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.originLocationStatus?.message}
              onChange={e => onChange?.selectGarage(e, 'originState')}
            />

            <Input
              bootstrap={'col-4'}
              label={'Ubicacion*'}
              type={'select'}
              options={locationOptions}
              name={'isOriginBasement'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.isOriginBasement?.message}
              disabled={watch('originLocationStatus') === addressEnum.GARAGE}
            />
            {[addressEnum.OWN, addressEnum.OTHER].includes(
              watch('originLocationStatus')
            ) && watch('isOriginBasement') === 'true' ? (
              <Input
                bootstrap={'col-3'}
                label={'Nivel *'}
                type={'number'}
                name={'basementLevel'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.basementLevel?.message}
              />
            ) : null}
          </div>
          <Input
            bootstrap={'col-12'}
            label={'Estado (Origen)*'}
            type={'select'}
            options={options?.statesOptions}
            name={'originState'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originState?.message}
            onChange={e => onChange?.getCities(e, 'originState')}
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Ciudad (Origen)*'}
            type={'select'}
            options={options?.originCitiesOptions}
            name={'originCity'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originCity?.message}
            onChange={e => onChange?.forceRecalulateDistance(e)}
            disabled={
              !watch('originState') ||
              options?.originCitiesOptions.length === 0 ||
              [addressEnum.GARAGE].includes(watch('originLocationStatus'))
            }
          />
          <Input
            bootstrap={'col-12'}
            label={'Dirección (Origen)*'}
            type={'textarea'}
            name={'originAddress'}
            placeholder={''}
            register={register}
            errors={errors?.originAddress?.message}
            onChange={e => onChange?.forceRecalulateDistance(e)}
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Punto de referencia (Origen)'}
            type={'textarea'}
            name={'originReferencePoint'}
            placeholder={''}
            register={register}
            errors={errors?.originReferencePoint?.message}
          />{' '}
        </div>
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-3'}
              label={'Teléfono *'}
              type={'select'}
              options={phoneCodes}
              name={'contactNumberPrefix'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumberPrefix?.message}
            />{' '}
            <Input
              bootstrap={'col-9'}
              label={''}
              type={'number'}
              name={'contactNumber'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumber?.message}
            />
            <Input
              bootstrap={'col-12'}
              label={'Avería reportada *'}
              type={'select'}
              options={options?.faultsOptions}
              name={'customerFailureReason'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureReason?.message}
              disabled={disabled}
            />
            <Input
              bootstrap={'col-12'}
              label={'Comentario adicional (Avería)'}
              type={'textarea'}
              name={'customerFailureDescription'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureDescription?.message}
            />
            <Input
              bootstrap={'col-12'}
              subLabel="Sin dirección destino"
              type={'checkbox'}
              name={'withOutDestinyAddress'}
              placeholder={''}
              register={register}
              errors={errors?.withOutDestinyAddress?.message}
              onChange={onChange?.clearDestinyData}
            />
          </div>
        </div>
        {!watch('withOutDestinyAddress') && (
          <div className="col-12 col-md-4">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Dirección de Destino *'}
                type={'select'}
                options={options?.addressOption}
                name={'destinyLocationStatus'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyLocationStatus?.message}
                onChange={e => onChange?.selectGarage(e, 'destinyState')}
              />

              <Input
                bootstrap={'col-12'}
                label={'Estado (Destino)*'}
                type={'select'}
                options={options?.statesOptions}
                name={'destinyState'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyState?.message}
                onChange={e => onChange?.getCities(e, 'destinyState')}
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />
              <Input
                bootstrap={'col-12'}
                label={'Ciudad (Destino)*'}
                type={'select'}
                options={options?.destinycitiesOptions}
                name={'destinyCity'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyCity?.message}
                onChange={e => onChange?.forceRecalulateDistance(e)}
                disabled={
                  !watch('destinyState') ||
                  options?.destinycitiesOptions.length === 0 ||
                  [addressEnum.GARAGE].includes(watch('destinyLocationStatus'))
                }
              />
              <Input
                bootstrap={'col-12'}
                label={'Dirección (Destino)*'}
                type={'textarea'}
                name={'destinyAddress'}
                placeholder={''}
                register={register}
                errors={errors?.destinyAddress?.message}
                onChange={e => onChange?.forceRecalulateDistance(e)}
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />

              <div className="buttons-center-container">
                {/* <AltButton label={'Ajustar ubicación'} /> */}
                <AltButton label={'Calcular distancia'} action={openMapModal} />
              </div>
              <Input
                bootstrap={'col-12'}
                label={'Distancia de origen a destino (Km)*'}
                type={'number'}
                name={'caseKm'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.caseKm?.message}
                disabled={true}
              />
            </div>
          </div>
        )}
        <div
          className="buttons-center-container"
          style={{ margin: '0px 0px 48px 0px' }}
        >
          <AltButton label={'Editar'} action={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export const CreateAVForm = props => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-6',
        errors: errors?.[`reference` + i]?.message,
      });
      array.push({
        // name: 'paymentMethod' + i,
        // label: `Comprobante ${i + 1} *`,
        // type: 'text',
        bootstrap: 'col-6',
        // errors: errors?.[`paymentMethod` + i]?.message,
      });
      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter(a => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Información Adicional</h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Teléfono del Cliente *'}
                type={'text'}
                options={options?.brandOptions}
                name={'contact'}
                register={register}
                placeholder={''}
                errors={errors?.contact?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Año del Vehículo *'}
                type={'text'}
                name={'vehicleYear'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleYear?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Marca del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleBrand'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleBrand?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Modelo del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleModel'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleModel?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Placa del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehiclePlate'}
                register={register}
                placeholder={''}
                errors={errors?.vehiclePlate?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Color del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleColor'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleColor?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Tiempo de Llegada (min) *'}
                type={'text'}
                name={'estimatedTime'}
                register={register}
                placeholder={''}
                errors={errors?.estimatedTime?.message}
                disabled={true}
              />{' '}
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Monto que paga el cliente USD *'}
                type={'number'}
                name={'pvp'}
                register={register}
                placeholder={''}
                errors={errors?.pvp?.message}
                min={'0'}
                onChange={onChange?.restorePaymentMethods}
              />

              {watch('pvp') > 0 && (
                <Input
                  bootstrap={'col-12'}
                  label={'Cantidad de Métodos de Pago'}
                  type={'number'}
                  name={'paymentMethods'}
                  register={register}
                  placeholder={''}
                  errors={errors?.paymentMethods?.message}
                  options={options?.paymentMethods}
                  onChange={onChange?.validateMaxPaymentMethods}
                  min={'1'}
                  max={'5'}
                />
              )}

              {watch('paymentMethods') > 0 && watch('pvp') > 0 && (
                <>
                  {paymentMethods().map((p, index) => (
                    <PaymentSection
                      key={p?.label}
                      payment={p}
                      watch={watch}
                      options={options}
                      register={register}
                      index={p?.index}
                      returnAccountData={returnAccountData}
                      fileUploaded={fileUploaded}
                      setFileUploaded={setFileUploaded}
                      onChange={onChange}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const CreateTowForm = props => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    selectedProvider,
    bsDisabled,
  } = props;

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-6',
        errors: errors?.[`reference` + i]?.message,
      });
      array.push({
        // name: 'paymentMethod' + i,
        // label: `Comprobante ${i + 1} *`,
        // type: 'text',
        bootstrap: 'col-6',
        // errors: errors?.[`paymentMethod` + i]?.message,
      });
      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter(a => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Información Adicional</h2>
      </div>
      <p style={{ marginTop: '12px' }}>
        <b>Proveedor: </b> {selectedProvider?.contactName} &nbsp;&nbsp;&nbsp;
        <b>Contacto: </b>
        {selectedProvider?.phonePrefix}-{selectedProvider?.phoneNumber}
      </p>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Teléfono del Cliente *'}
                type={'text'}
                options={options?.brandOptions}
                name={'contact'}
                register={register}
                placeholder={''}
                errors={errors?.contact?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Año del Vehículo *'}
                type={'text'}
                name={'vehicleYear'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleYear?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Marca del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleBrand'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleBrand?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Modelo del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleModel'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleModel?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Placa del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehiclePlate'}
                register={register}
                placeholder={''}
                errors={errors?.vehiclePlate?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Color del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleColor'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleColor?.message}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Costo del servicio seleccionado USD *'}
                type={'number'}
                options={options?.brandOptions}
                name={'price'}
                register={register}
                placeholder={''}
                errors={errors?.price?.message}
                min={'0'}
              />
              <Input
                bootstrap={'col-12'}
                label={'Monto que paga el cliente USD *'}
                type={'number'}
                name={'pvp'}
                register={register}
                placeholder={''}
                errors={errors?.pvp?.message}
                min={'0'}
                onChange={onChange?.restorePaymentMethods}
              />

              {watch('pvp') > 0 && (
                <Input
                  bootstrap={'col-12'}
                  label={'Cantidad de Métodos de Pago'}
                  type={'number'}
                  name={'paymentMethods'}
                  register={register}
                  placeholder={''}
                  errors={errors?.paymentMethods?.message}
                  options={options?.paymentMethods}
                  onChange={onChange?.validateMaxPaymentMethods}
                  min={'1'}
                  max={'5'}
                />
              )}

              {watch('paymentMethods') > 0 && watch('pvp') > 0 && (
                <>
                  {paymentMethods().map((p, index) => (
                    <PaymentSection
                      key={p?.label}
                      payment={p}
                      watch={watch}
                      options={options}
                      register={register}
                      index={p?.index}
                      returnAccountData={returnAccountData}
                      fileUploaded={fileUploaded}
                      setFileUploaded={setFileUploaded}
                      onChange={onChange}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const RenewMembershipForm = props => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });

      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });
      array.push({
        label: 'IGTF',
        counter: i,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-12',
        errors: errors?.[`reference` + i]?.message,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter(a => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Registar Pago</h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Tipo de pago *'}
                type={'select'}
                name={'paymentType'}
                register={register}
                options={options?.paymentTypesOptions}
                placeholder={''}
                errors={errors?.paymentType?.message}
                min={'0'}
                onChange={onChange?.handlePaymentTypeForm}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Monto que paga el cliente USD *'}
                type={'number'}
                name={'price'}
                register={register}
                placeholder={''}
                errors={errors?.price?.message}
                min={'0'}
                onChange={onChange?.restorePaymentMethods}
                disabled={true}
              />

              {watch('price') >= 0 && (
                <Input
                  bootstrap={'col-12'}
                  label={'Cantidad de Métodos de Pago'}
                  type={'number'}
                  name={'paymentMethods'}
                  register={register}
                  placeholder={''}
                  errors={errors?.paymentMethods?.message}
                  options={options?.paymentMethods}
                  onChange={onChange?.validateMaxPaymentMethods}
                  min={'1'}
                  max={'2'}
                  disabled={true}
                />
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              {watch('paymentType') === paymentType?.FINANCIADO && (
                <>
                  <div className="col-12">
                    <h5>
                      Fechas de pago:
                      <br />
                      1era Cuota {moment(new Date()).format('DD/MM/YYYY')}
                    </h5>
                  </div>
                </>
              )}
              {watch('paymentMethods') > 0 && watch('price') >= 0 && (
                <>
                  {paymentMethods().map((p, index) => (
                    <PaymentSection
                      key={p?.label}
                      payment={p}
                      watch={watch}
                      options={options}
                      register={register}
                      index={p?.index}
                      returnAccountData={returnAccountData}
                      fileUploaded={fileUploaded}
                      setFileUploaded={setFileUploaded}
                      onChange={onChange}
                    />
                  ))}
                </>
              )}
              {watch('paymentType') === paymentType?.FINANCIADO && (
                <>
                  <div className="col-12">
                    <h5>
                      Fechas de pago:
                      <br />
                      2da Cuota{' '}
                      {moment(
                        new Date().setDate(
                          new Date().getDate() + secondQuotaTime
                        )
                      )
                        .format('DD/MM/YYYY')}{' '}
                    </h5>
                  </div>
                  <Input
                    bootstrap={'col-6'}
                    label={'Monto 2 (USD) '}
                    type={'number'}
                    name={'quota2USD'}
                    register={register}
                    placeholder={''}
                    errors={errors?.quota2USD?.message}
                    disabled={true}
                  />{' '}
                  <Input
                    bootstrap={'col-6'}
                    label={'Monto 2 (Bs) '}
                    type={'number'}
                    name={'quota2BsS'}
                    register={register}
                    placeholder={''}
                    errors={errors?.quota2BsS?.message}
                    disabled={true}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const NewPaymentModal = props => {
  const {
    register,
    options,
    errors,
    cancel,
    setValue,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    handleDrop,
    cashBackImage,
    removeFile,
    errorsDropzone,
    control,
    membership,
  } = props;

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter(a => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Registar Pago {watch('modal')}</h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <NewPaymentForm
        register={register}
        options={options}
        errors={errors}
        control={control}
        onChange={onChange}
        watch={watch}
        returnAccountData={returnAccountData}
        fileUploaded={fileUploaded}
        setFileUploaded={setFileUploaded}
        setValue={setValue}
        handleDrop={handleDrop}
        cashBackImage={cashBackImage}
        removeFile={removeFile}
        errorsDropzone={errorsDropzone}
        membership={membership}
      />
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const EditPaymentForm = props => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    onChange,
    paymentType,
    creationDate
  } = props;

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Editar Pago </h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ width: '800px' }} className="form-container">
        <div className="row">
          <Input
            bootstrap={'col-6'}
            label={'Monto USD *'}
            type={'number'}
            name={'amount'}
            register={register}
            placeholder={''}
            errors={errors?.amount?.message}
            min={'0'}
            onChange={onChange?.handleEditAmountChange}
          />
          <Input
            bootstrap={'col-6'}
            label={'Monto BsS *'}
            type={'number'}
            name={'amountBsS'}
            register={register}
            placeholder={''}
            errors={errors?.amountBsS?.message}
            min={'0'}
          />
          <Input
            bootstrap={'col-6'}
            label={'Metodo *'}
            type={'select'}
            name={'method'}
            register={register}
            options={options?.paymentMethods}
            placeholder={''}
            errors={errors?.method?.message}
          />
          <Input
            bootstrap={'col-6'}
            label={'Referencia *'}
            type={'text'}
            name={'reference'}
            register={register}
            placeholder={''}
            errors={errors?.reference?.message}
          />
          {paymentType === 'Cashea' && (
            <Input
              bootstrap={'col-6'}
              label={'Referencia de Cashea'}
              type={'text'}
              name={'referenceCashea'}
              register={register}
              placeholder={''}
              errors={errors?.referenceCashea?.message}
            />
          )}
          <FilterDatePicker
            bootstrap={'col-6'}
            label={'Fecha de pago *'}
            type={'date'}
            name={'creationDate'}
            placeholder={''}
            onChange={onChange?.handleDateChange}
            value={creationDate}
          />
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const EditChangeForm = props => {
  const { register, options, errors, cancel, action, onChange, control } =
    props;

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Editar Pago </h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ width: '800px' }} className="form-container">
        <div className="row">
          <Input
            bootstrap={'col-6'}
            label={'Pagado *'}
            type={'number'}
            name={'paid'}
            register={register}
            placeholder={''}
            errors={errors?.paid?.message}
            min={'0'}
            onChange={onChange?.handleEditAmountChange}
          />
          <Input
            bootstrap={'col-6'}
            label={'Monto $ *'}
            type={'number'}
            name={'amount'}
            register={register}
            placeholder={''}
            errors={errors?.amount?.message}
            min={'0'}
          />
          <Input
            bootstrap={'col-6'}
            label={'Monto BsS *'}
            type={'number'}
            name={'amountBs'}
            register={register}
            placeholder={''}
            errors={errors?.amountBs?.message}
            min={'0'}
          />
          <Input
            bootstrap={'col-6'}
            label={'Metodo *'}
            type={'select'}
            name={'method'}
            options={options?.paymentMethods}
            register={register}
            placeholder={''}
            errors={errors?.method?.message}
          />
          <Input
            bootstrap={'col-6'}
            label={'Referencia *'}
            type={'text'}
            name={'ref'}
            register={register}
            placeholder={''}
            errors={errors?.ref?.message}
          />
          <Input
            bootstrap={'col-6'}
            label={'Fecha de pago *'}
            type={'date'}
            control={control}
            name={'date'}
            register={register}
            placeholder={''}
            errors={errors?.date?.message}
          />
        </div>
      </form>
    </>
  );
};

export const PendingPaymentForm = props => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });

      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
        disabled:
          watch('paymentType') === paymentType?.FINANCIADO ? true : false,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });
      array.push({
        label: 'IGTF',
        counter: i,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-12',
        errors: errors?.[`reference` + i]?.message,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter(a => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };
  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Registar Pago pendiente</h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Tipo de pago *'}
                type={'select'}
                name={'paymentType'}
                register={register}
                options={options?.paymentTypesOptions}
                placeholder={''}
                errors={errors?.paymentType?.message}
                min={'0'}
                onChange={onChange?.handlePaymentTypeForm}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Monto que paga el cliente USD *'}
                type={'number'}
                name={'price'}
                register={register}
                placeholder={''}
                errors={errors?.price?.message}
                min={'0'}
                onChange={onChange?.restorePaymentMethods}
                disabled={true}
              />

              {watch('price') > 0 && (
                <Input
                  bootstrap={'col-12'}
                  label={'Cantidad de Métodos de Pago'}
                  type={'number'}
                  name={'paymentMethods'}
                  register={register}
                  placeholder={''}
                  errors={errors?.paymentMethods?.message}
                  options={options?.paymentMethods}
                  onChange={onChange?.validateMaxPaymentMethods}
                  min={'1'}
                  max={'2'}
                  disabled={watch('paymentType') === paymentType?.FINANCIADO}
                />
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              {watch('paymentType') === paymentType?.FINANCIADO && (
                <>
                  <div className="col-12">
                    <h5>
                      Fechas de pago:
                      <br />
                      2da Cuota
                      {'  ' + moment(watch('dueDate')).format('DD/MM/YYYY')}
                    </h5>
                  </div>
                </>
              )}
              {watch('paymentMethods') > 0 && watch('price') > 0 && (
                <>
                  {paymentMethods().map((p, index) => (
                    <PaymentSection
                      key={p?.label}
                      payment={p}
                      watch={watch}
                      options={options}
                      register={register}
                      index={p?.index}
                      returnAccountData={returnAccountData}
                      fileUploaded={fileUploaded}
                      setFileUploaded={setFileUploaded}
                      onChange={onChange}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const UpgradeMembershipForm = props => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });

      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });
      array.push({
        label: 'IGTF',
        counter: i,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-12',
        errors: errors?.[`reference` + i]?.message,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter(a => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Registar Pago de upgrade</h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Monto que paga el cliente USD *'}
                type={'number'}
                name={'price'}
                register={register}
                placeholder={''}
                errors={errors?.price?.message}
                min={'0'}
                disabled={true}
              />

              {watch('price') > 0 && (
                <Input
                  bootstrap={'col-12'}
                  label={'Cantidad de Métodos de Pago'}
                  type={'number'}
                  name={'paymentMethods'}
                  register={register}
                  placeholder={''}
                  errors={errors?.paymentMethods?.message}
                  options={options?.paymentMethods}
                  onChange={onChange?.validateMaxPaymentMethods}
                  min={'1'}
                  max={'2'}
                  disabled={watch('paymentType') === paymentType?.FINANCIADO}
                />
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              {watch('paymentType') === paymentType?.FINANCIADO && (
                <>
                  <div className="col-12">
                    <h5>
                      Fechas de pago:
                      <br />
                      1era Cuota {moment(new Date()).format('DD/MM/YYYY')}
                    </h5>
                  </div>
                </>
              )}
              {watch('paymentMethods') > 0 && watch('price') > 0 && (
                <>
                  {paymentMethods().map((p, index) => (
                    <PaymentSection
                      key={p?.label}
                      payment={p}
                      watch={watch}
                      options={options}
                      register={register}
                      index={p?.index}
                      returnAccountData={returnAccountData}
                      fileUploaded={fileUploaded}
                      setFileUploaded={setFileUploaded}
                      onChange={onChange}
                    />
                  ))}
                </>
              )}
              {watch('paymentType') === paymentType?.FINANCIADO && (
                <>
                  <div className="col-12">
                    <h5>
                      Fechas de pago:
                      <br />
                      2da Cuota{' '}
                      {moment
                        (
                          new Date().setDate(
                            new Date().getDate() + secondQuotaTime
                          )
                        )
                        .format('DD/MM/YYYY')}{' '}
                    </h5>
                  </div>
                  <Input
                    bootstrap={'col-6'}
                    label={'Monto 2 (USD) '}
                    type={'number'}
                    name={'quota2USD'}
                    register={register}
                    placeholder={''}
                    errors={errors?.quota2USD?.message}
                    disabled={true}
                  />{' '}
                  <Input
                    bootstrap={'col-6'}
                    label={'Monto 2 (Bs) '}
                    type={'number'}
                    name={'quota2BsS'}
                    register={register}
                    placeholder={''}
                    errors={errors?.quota2BsS?.message}
                    disabled={true}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const LandingRegisterStep1Form = props => {
  const { register, errors, onChange, options, watch, validateTruck } = props;

  const yearMessage = year => {
    year = parseFloat(year);

    let text = '';

    if (year >= 2000 && year <= 2005) {
      text =
        'NOTA: El plan tendrá un precio especial por el año del vehículo (se añadirá +20% al precio final)';
    }

    if (year < 2000 && year >= 1990) {
      text =
        'NOTA: El plan tendrá un precio especial por el año del vehículo (se añadirá +50% al precio final)';
    }

    if (text && (!watch('armorType') || watch('armorType') === 'ninguno')) {
      return (
        <h6
          style={{
            fontSize: '14px',
            backgroundColor: '#FFEA00',
            padding: '4px 8px',
            borderRadius: '12px',
          }}
        >
          {text}
        </h6>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <div className="row">
          <Input
            bootstrap={'col-12'}
            label={'Año del vehículo *'}
            type={'number'}
            name={'year'}
            register={register}
            placeholder={''}
            errors={errors?.year?.message}
            min={vintageYear}
            max={new Date().getFullYear() + 1}
            onChange={onChange?.changeVehicleData}
          />
        </div>
        {yearMessage(watch('year'))}{' '}
      </div>
      <Input
        bootstrap={'col-12 col-md-4'}
        label={'Clase de vehículo *'}
        type={'select'}
        name={'class'}
        register={register}
        placeholder={''}
        errors={errors?.class?.message}
        options={options?.vehicleClassesOptions}
        onChange={onChange?.changeVehicleData}
      />{' '}
      <div className="col-12 col-md-4">
        <div className="row">
          <Input
            bootstrap={'col-12'}
            label={'Blindaje'}
            type={'select'}
            name={'armorType'}
            register={register}
            placeholder={''}
            errors={errors?.armorType?.message}
            options={options?.armorTypeOptions}
            onChange={onChange?.changeVehicleData}
          />
        </div>
        {watch('armorType') && watch('armorType') !== 'ninguno' && (
          <h6
            style={{
              fontSize: '14px',
              backgroundColor: '#FFEA00',
              padding: '4px 8px',
              borderRadius: '12px',
            }}
          >
            NOTA: Tienes un monto adicional por el peso de tu vehiculo
          </h6>
        )}
      </div>
      <div className="col-4"></div>
      {validateTruck() && (
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Peso del vehículo *'}
          type={'select'}
          name={'truckWeight'}
          register={register}
          placeholder={''}
          errors={errors?.truckWeight?.message}
          options={options?.vehicleWeightOptions}
          onChange={onChange?.changeVehicleData}
        />
      )}
      <div className="col-4"></div>
    </div>
  );
};

export const DiscountForm = props => {
  const { register, errors, onChange, options, onSubmit } = props;

  return (
    <form>
      <div className="row">
        <div className="col-12 col-md-2"></div>
        <Input
          bootstrap={'col-12 col-md-2'}
          label={'Tipo de código'}
          type={'select'}
          name={'codeType'}
          register={register}
          placeholder={''}
          errors={errors?.codeType?.message}
          options={options?.codeTypeOptions}
          onChange={onChange?.getAvailableMemberships}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Código'}
          type={'text'}
          name={'code'}
          register={register}
          placeholder={''}
          errors={errors?.code?.message}
          onChange={onChange?.transformToUppercase}
        />
        <div className="col-12 col-md-2">
          <div style={{ marginTop: '32px' }} className="buttons-container">
            <ActionButton label={'Canjear'} action={onSubmit} />
          </div>
        </div>
      </div>
    </form>
  );
};

export const LandingFreeDiscountForm = props => {
  const { register, errors, onChange, onSubmit, options } = props;

  return (
    <form>
      <div className="row">
        <div className="col-12 col-md-2"></div>
        <Input
          bootstrap={'col-12 col-md-2'}
          label={'Tipo'}
          type={'select'}
          name={'codeType'}
          options={options?.codeTypeOptions}
          register={register}
          placeholder={''}
          errors={errors?.codeType?.message}
          onChange={onChange?.hadleCodeType}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Código'}
          type={'text'}
          name={'code'}
          register={register}
          placeholder={''}
          errors={errors?.code?.message}
          onChange={onChange?.transformToUppercase}
        />
        <div className="col-12 col-md-2">
          <div style={{ marginTop: '32px' }} className="buttons-container">
            <ActionButton label={'Canjear'} action={onSubmit} />
          </div>
        </div>
      </div>
    </form>
  );
};

export const CreateFleetForm = props => {
  const { register, errors, action, cancel, cancelOptions } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Nombre *'}
          type={'text'}
          options={cancelOptions}
          name={'name'}
          register={register}
          placeholder={''}
          errors={errors?.name?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Código'}
          type={'text'}
          name={'code'}
          register={register}
          placeholder={''}
          errors={errors?.code?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Status'}
          type={'select'}
          options={[
            { name: 'Activo', _id: true },
            { name: 'Inactive', _id: false },
          ]}
          name={'status'}
          register={register}
          placeholder={''}
          errors={errors?.status?.message}
        />
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const CancelFleetForm = props => {
  const { register, errors, action, cancel, cancelOptions } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Motivo de anulación *'}
          type={'select'}
          options={cancelOptions}
          name={'annulationReason'}
          register={register}
          placeholder={''}
          errors={errors?.annulationReason?.message}
        />{' '}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const CreateFleetLoadForm = props => {
  const {
    options,
    register,
    errors,
    action,
    control,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    getRootProps,
    getInputProps,
  } = props;

  const filterPlans = plans => {
    let filteredPlans = plans?.filter(p => p?.name === 'Ninguno');
    return filteredPlans;
  };

  return (
    <form className="form-container">
      <div className="row">
        <div className="col-12 col-md-3"></div>
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Canal *'}
          type={'select'}
          options={options?.salesChannelOptions}
          name={'saleChannel'}
          register={register}
          placeholder={''}
          errors={errors?.saleChannel?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Tipo de Flota *'}
          type={'select'}
          options={options?.fleetTypeOptions}
          name={'type'}
          register={register}
          placeholder={''}
          errors={errors?.type?.message}
          onChange={e => onChange?.handleFleetChange(e)}
        />{' '}
        <div className="col-12 col-md-3"></div>
        <div className="light-gray-line"></div>
        <div className="col-12">
          <h6 style={{ textAlign: 'center' }}>Vigencia del Plan</h6>
        </div>
        <div className="col-12 col-md-3"></div>
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Fecha de Inicio *'}
          type={'date'}
          name={'planStartDate'}
          placeholder={''}
          register={register}
          errors={errors?.planStartDate?.message}
          control={control}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Fecha de Finalizacion *'}
          type={'date'}
          name={'planEndDate'}
          placeholder={''}
          register={register}
          errors={errors?.planEndDate?.message}
          control={control}
          minDate={new Date()}
        />{' '}
        <div className="col-12 col-md-3"></div>
        <div className="col-12">
          <h6 style={{ textAlign: 'center' }}>Planes donde aplica el código</h6>
        </div>
        <div className="col-12">
          <div className="row">
            {options?.membershipOptions?.map((m, i) => {
              return (
                <>
                  <Input
                    bootstrap={'col-6 col-md-3'}
                    subLabel={capitalize(m?.name)}
                    type={'checkbox'}
                    name={m?._id}
                    register={register}
                    placeholder={''}
                    errors={errors[m?._id]?.message}
                    onChange={e =>
                      onChange?.handleCheckboxChange(m?._id + '_RCV')
                    }
                  />{' '}
                  <Input
                    bootstrap={'col-6 col-md-3'}
                    subLabel={capitalize(m?.name)}
                    label={'RCV'}
                    type={'select'}
                    options={
                      m?._id === planTypes?.PLUSF4
                        ? filterPlans(options?.fleetLoadRCVTypesOptions)
                        : options?.fleetLoadRCVTypesOptions
                    }
                    name={m?._id + '_RCV'}
                    register={register}
                    placeholder={''}
                    errors={errors[m?._id + '_RCV']?.message}
                    disabled={!watch(`${m?._id}`)}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="light-gray-line"></div>
        {watch('planStartDate') && watch('planEndDate') && (
          <>
            <div className="col-12">
              <h6 style={{ textAlign: 'center' }}>Detalle de Pago</h6>
            </div>
            <div className="col-12 col-md-3"></div>
            <Input
              bootstrap={'col-12 col-md-3'}
              label={'Monto del contrato USD *'}
              type={'number'}
              name={'contractPrice'}
              register={register}
              placeholder={''}
              errors={errors?.contractPrice?.message}
              min={'0'}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-3'}
              label={'Modo de Pago *'}
              type={'select'}
              options={options?.fleetPaymentTypeOptions}
              name={'paymentMethod'}
              register={register}
              placeholder={''}
              onChange={() =>
                onChange?.handlePaymentMethod(watch('paymentMethod'))
              }
              errors={errors?.paymentMethod?.message}
            />{' '}
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-3"></div>
            <Input
              bootstrap={'col-12 col-md-3'}
              label={'Financiamiento *'}
              type={'select'}
              options={options?.financingTypeOptions}
              name={'financingTerm'}
              register={register}
              placeholder={''}
              onChange={() =>
                onChange?.handleFinancingChange(watch('financingTerm'))
              }
              errors={errors?.financingTerm?.message}
              disabled={watch('paymentMethod') === fleetPaymentType?.DE_CONTADO}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-3'}
              label={'Tipo de Carga *'}
              type={'select'}
              options={options?.loadStrategyOptions}
              name={'loadStrategy'}
              register={register}
              placeholder={''}
              errors={errors?.loadStrategy?.message}
              disabled={!watch('type')}
            />{' '}
            <div className="col-12 col-md-3"></div>
            <div className="col-12"></div>{' '}
            {watch('loadStrategy') === loadStrategy?.LINK && (
              <>
                <h6 style={{ textAlign: 'center' }}>Vigencia del Link</h6>
                <div className="col-12 col-md-3"></div>
                <Input
                  bootstrap={'col-12 col-md-3'}
                  label={'Habilitar el *'}
                  type={'date'}
                  name={'linkValidityFrom'}
                  placeholder={''}
                  register={register}
                  errors={errors?.linkValidityFrom?.message}
                  control={control}
                  minDate={new Date()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-3'}
                  label={'Deshabilitar el *'}
                  type={'date'}
                  name={'linkValidityTo'}
                  placeholder={''}
                  register={register}
                  errors={errors?.linkValidityTo?.message}
                  control={control}
                  minDate={new Date()}
                />{' '}
                <div className="col-12 col-md-3"></div>
              </>
            )}{' '}
            {watch('loadStrategy') === loadStrategy?.ARCHIVO && (
              <div
                style={{ width: '100%' }}
                className="buttons-center-container"
              >
                <div>
                  <h6 style={{ textAlign: 'center' }}>Subir Archivo CSV</h6>
                  <div className="dropzone-container">
                    {fileUploaded ? (
                      <FileComponent
                        file={fileUploaded}
                        remove={() => setFileUploaded('')}
                      />
                    ) : (
                      <Dropzone
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        message={'Adjuntar documento CSV'}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton
          action={() => onChange?.cancelAndRedirect()}
          label={'Cancelar'}
        />
        <ActionButton action={action} label={'Registrar'} />
      </div>
    </form>
  );
};

export const RenewFleetLoadForm = props => {
  const { options, register, errors, action, control, watch, onChange } = props;

  const validateIfSelected = membership => {
    let match = false;

    for (let i = 0; i < options?.membershipOptions?.length; i++) {
      if (watch(options?.membershipOptions[i]?._id)) {
        match = true;
      }
    }

    if (!match) {
      return false;
    }

    if (match && watch(membership?._id)) {
      return false;
    } else {
      return true;
    }
  };

  const filterPlans = plans => {
    let filteredPlans = plans?.filter(p => p?.name === 'Ninguno');
    return filteredPlans;
  };

  return (
    <form className="form-container">
      <div className="row">
        <div className="col-12 col-md-3"></div>
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Canal *'}
          type={'select'}
          options={options?.salesChannelOptions}
          name={'saleChannel'}
          register={register}
          placeholder={''}
          errors={errors?.saleChannel?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Tipo de Flota *'}
          type={'select'}
          options={options?.fleetTypeOptions}
          name={'type'}
          register={register}
          placeholder={''}
          errors={errors?.type?.message}
          onChange={e => onChange?.handleFleetChange(e)}
        />{' '}
        <div className="col-12 col-md-3"></div>
        <div className="light-gray-line"></div>
        <div className="col-12">
          <h6 style={{ textAlign: 'center' }}>Vigencia del Plan</h6>
        </div>
        <div className="col-12 col-md-3"></div>
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Fecha de Inicio *'}
          type={'date'}
          name={'planStartDate'}
          placeholder={''}
          register={register}
          errors={errors?.planStartDate?.message}
          control={control}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-3'}
          label={'Fecha de Finalizacion *'}
          type={'date'}
          name={'planEndDate'}
          placeholder={''}
          register={register}
          errors={errors?.planEndDate?.message}
          control={control}
          minDate={new Date()}
        />{' '}
        <div className="col-12 col-md-3"></div>
        <div className="col-12">
          <h6 style={{ textAlign: 'center' }}>Planes donde aplica el código</h6>
        </div>
        <div className="col-12">
          <div className="row">
            {options?.membershipOptions?.map((m, i) => {
              return (
                <>
                  {
                    <Input
                      bootstrap={'col-6 col-md-3'}
                      subLabel={capitalize(m?.name)}
                      type={'checkbox'}
                      name={m?._id}
                      register={register}
                      placeholder={''}
                      errors={errors[m?._id]?.message}
                      disabled={validateIfSelected(m)}
                      onChange={e => onChange?.handleCheckboxChange(m?._id)}
                    />
                  }
                  <Input
                    bootstrap={'col-6 col-md-3'}
                    subLabel={capitalize(m?.name)}
                    label={'RCV'}
                    type={'select'}
                    options={
                      m?._id === planTypes?.PLUSF4
                        ? filterPlans(options?.fleetLoadRCVTypesOptions)
                        : options?.fleetLoadRCVTypesOptions
                    }
                    name={m?._id + '_RCV'}
                    register={register}
                    placeholder={''}
                    errors={errors[m?._id + '_RCV']?.message}
                    disabled={!watch(`${m?._id}`)}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="light-gray-line"></div>
        {watch('planStartDate') && watch('planEndDate') && (
          <>
            <div className="col-12">
              <h6 style={{ textAlign: 'center' }}>Detalle de Pago</h6>
            </div>
            <div className="col-12 col-md-3"></div>
            <Input
              bootstrap={'col-12 col-md-3'}
              label={'Monto del contrato USD *'}
              type={'number'}
              name={'contractPrice'}
              register={register}
              placeholder={''}
              errors={errors?.contractPrice?.message}
              min={'0'}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-3'}
              label={'Modo de Pago *'}
              type={'select'}
              options={options?.fleetPaymentTypeOptions}
              name={'paymentMethod'}
              register={register}
              placeholder={''}
              onChange={() =>
                onChange?.handlePaymentMethod(watch('paymentMethod'))
              }
              errors={errors?.paymentMethod?.message}
            />{' '}
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-3"></div>
            <Input
              bootstrap={'col-12 col-md-3'}
              label={'Financiamiento *'}
              type={'select'}
              options={options?.financingTypeOptions}
              name={'financingTerm'}
              register={register}
              placeholder={''}
              onChange={() =>
                onChange?.handleFinancingChange(watch('financingTerm'))
              }
              errors={errors?.financingTerm?.message}
              disabled={watch('paymentMethod') === fleetPaymentType?.DE_CONTADO}
            />{' '}
            <div className="col-12 col-md-3"></div>
            <div className="col-12"></div>{' '}
          </>
        )}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton
          action={() => onChange?.cancelAndRedirect()}
          label={'Cancelar'}
        />
        <ActionButton action={action} label={'Registrar'} />
      </div>
    </form>
  );
};

export const RenewInforForm = props => {
  const {
    options,
    register,
    errors,
    action,
    cancel,
    onChange,
    priceInfo,
    watch,
  } = props;

  const validateRCVType = () => {
    const [selectedOption] = options?.planOptions?.filter(
      p => p?._id === watch('code')
    );
    if (selectedOption && selectedOption?.name === membershipCodes?.GOLD) {
      return true;
    } else return false;
  };
  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-6 col-md-6'}
          label={'Plan a Renovar'}
          type={'select'}
          name={'code'}
          register={register}
          placeholder={''}
          options={options?.planOptions}
          errors={errors?.code?.message}
          onChange={() => onChange?.getRCVs('', options?.planOptions)}
        />
        <Input
          bootstrap={'col-6 col-md-6'}
          label={'RCV'}
          type={'select'}
          name={'rcv'}
          register={register}
          placeholder={''}
          options={options?.rcvOptions}
          errors={errors?.rcv?.message}
          disabled={!watch('code')}
          onChange={() =>
            onChange?.calculateRenewalPrice('', options?.planOptions)
          }
        />
        {validateRCVType() && (
          <>
            <Input
              bootstrap={'col-6 col-md-6'}
              label={'Km de Servicio de Grúa'}
              type={'select'}
              name={'km'}
              register={register}
              placeholder={''}
              options={options?.KMServiceOptions}
              errors={errors?.km?.message}
              onChange={() =>
                onChange?.calculateRenewalPrice('', options?.planOptions)
              }
            />
            <div className="col-6"></div>
            <Input
              bootstrap={'col-6 col-md-6 mt-0'}
              subLabel={'Añadir un (1) servicio de grúa ilimitado'}
              type={'checkbox'}
              name={'hasExtendedTow'}
              register={register}
              placeholder={''}
              errors={errors?.hasExtendedTow?.message}
              onChange={() =>
                onChange?.calculateRenewalPrice('', options?.planOptions)
              }
            />
            <div className="col-6"></div>
          </>
        )}
        <Input
          bootstrap={'col-6 col-md-6'}
          label={'Tipo de Código'}
          type={'select'}
          name={'codeType'}
          register={register}
          placeholder={''}
          options={options?.codeTypeOptions}
          errors={errors?.codeType?.message}
          disabled={!watch('code')}
          onChange={() => onChange?.handleCodeChange()}
        />
        <Input
          bootstrap={'col-6 col-md-6'}
          label={'Código'}
          type={'text'}
          name={'codigo'}
          register={register}
          placeholder={''}
          errors={errors?.codigo?.message}
          disabled={!watch('codeType')}
          onChange={() => onChange?.handleDiscountCode()}
        />

        {watch('codeType') &&
          watch('codigo') &&
          watch('codigo')?.length === 6 && (
            <div className="col-12">
              <div className="buttons-center-container">
                <ActionButton
                  label={'Canjear Código'}
                  action={() => onChange?.handleDiscountCode()}
                />
              </div>
            </div>
          )}

        {priceInfo?.finalPrice >= 0 && (
          <>
            <div className="col-3"></div>
            <div className="col-12 col-lg-6">
              <div className="autorenovation-text-container">
                <p style={{ textAlign: 'center' }}>
                  {' '}
                  {priceInfo?.initialValue > 0
                    ? `Subtotal: ${priceInfo?.initialValue} $`
                    : ''}
                </p>
                <p>
                  {' '}
                  {priceInfo?.renewalSurcharge > 0
                    ? `Recargo por Siniestralidad: +${priceInfo?.renewalSurcharge?.toFixed(
                      2
                    )} $`
                    : ''}
                </p>
                <p>
                  {' '}
                  {priceInfo?.kmSurcharge > 0
                    ? `Recargo por Km extra: ${priceInfo?.kmSurcharge?.toFixed(
                      2
                    )} $`
                    : ''}
                </p>
                <p>
                  {' '}
                  {priceInfo?.discountByCode > 0
                    ? `Cupones aplicados: -${priceInfo?.discountByCode?.toFixed(
                      2
                    )} $`
                    : ''}
                </p>
                <p>
                  {' '}
                  {priceInfo?.discountByRenewal > 0
                    ? `Descuento por renovación anticipada: -${priceInfo?.discountByRenewal?.toFixed(
                      2
                    )} $`
                    : ''}
                </p>
                <p>
                  {' '}
                  {priceInfo?.discountByGiftcard > 0
                    ? `Giftcard aplicadas: -${priceInfo?.discountByGiftcard?.toFixed(
                      2
                    )} $`
                    : ''}
                </p>
                <h6>
                  {priceInfo?.finalPrice >= 0
                    ? `Monto a pagar: ${priceInfo?.finalPrice?.toFixed(2)} $`
                    : ''}
                </h6>
              </div>
              <Input
                bootstrap={'col-8 col-md-8 mt-2'}
                subLabel={'Descuento de renovación'}
                type={'checkbox'}
                name={'useDiscount'}
                register={register}
                placeholder={''}
                errors={errors?.useDiscount?.message}
                disabled={false}
                onChange={() => onChange?.handleDiscountPercentage()}
              />
            </div>
            <div className="col-3"></div>
          </>
        )}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Registrar'} />
      </div>
    </form>
  );
};

export const ChangeUserForm = props => {
  const { register, errors, action, cancel, options, control, watch } = props;
  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Nombre*'}
          type={'text'}
          name={'firstName'}
          register={register}
          placeholder={''}
          errors={errors?.firstName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Apellido *'}
          type={'text'}
          name={'lastName'}
          register={register}
          placeholder={''}
          errors={errors?.lastName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Email *'}
          type={'text'}
          name={'email'}
          register={register}
          placeholder={''}
          errors={errors?.email?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Correo Secundario'}
          type={'text'}
          name={'optionalEmail'}
          register={register}
          placeholder={''}
          errors={errors?.optionalEmail?.message}
        />
        <Input
          bootstrap={'col-4 col-sm-1 col-md-2'}
          label={'C.I / RIF*'}
          type={'select'}
          name={'identificationType'}
          register={register}
          placeholder={''}
          options={options?.idCodes}
          errors={errors?.identificationType?.message}
        />
        <Input
          bootstrap={'col-8 col-sm-3 col-md-4'}
          label={'del cliente*'}
          type={'number'}
          name={'identification'}
          register={register}
          placeholder={''}
          errors={errors?.identification?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={`Sexo  ${![idEnums?.J, idEnums?.R, idEnums?.G].includes(
            watch('identificationType')
          )
            ? '*'
            : ''
            }`}
          type={'select'}
          name={'gender'}
          register={register}
          placeholder={''}
          options={options?.genderOptions}
          errors={errors?.gender?.message}
        />{' '}
        <Input
          bootstrap={'col-4 col-sm-2 col-md-3'}
          label={'Teléfono*'}
          type={'select'}
          name={'phonePrefix'}
          register={register}
          placeholder={''}
          options={options?.phoneCodes}
          errors={errors?.phonePrefix?.message}
        />{' '}
        <Input
          bootstrap={'col-8 col-sm-4 col-md-3'}
          label={''}
          type={'number'}
          name={'phoneNumber'}
          register={register}
          placeholder={''}
          errors={errors?.phoneNumber?.message}
        />{' '}
        <Input
          bootstrap={'col-4 col-sm-2 col-md-3'}
          label={'Teléfono Opcional*'}
          type={'select'}
          name={'optionalPhonePrefix'}
          register={register}
          placeholder={''}
          options={options?.phoneCodes}
          errors={errors?.optionalPhonePrefix?.message}
        />{' '}
        <Input
          bootstrap={'col-8 col-sm-4 col-md-3'}
          label={''}
          type={'number'}
          name={'optionalPhoneNumber'}
          register={register}
          placeholder={''}
          errors={errors?.optionalPhoneNumber?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={`Fecha de Nacimiento DD/MM/AAAA ${![idEnums?.J, idEnums?.R, idEnums?.G].includes(
            watch('identificationType')
          )
            ? '*'
            : ''
            } `}
          type={'birthday'}
          name={'birthday'}
          register={register}
          placeholder={''}
          control={control}
          errors={errors?.birthday?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={`Estado Civil  ${![idEnums?.J, idEnums?.R, idEnums?.G].includes(
            watch('identificationType')
          )
            ? '*'
            : ''
            }`}
          type={'select'}
          name={'maritalStatus'}
          register={register}
          placeholder={''}
          options={options?.civilStateOptions}
          errors={errors?.maritalStatus?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Tipo de Afiliacion*'}
          type={'select'}
          options={options?.affiliationTypeOptions}
          name={'affiliationType'}
          register={register}
          placeholder={''}
          errors={errors?.affiliationType?.message}
        />
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const ChangeVehicleForm = props => {
  const {
    register,
    errors,
    action,
    cancel,
    options,
    watch,
    onChange,
    validateTruck,
  } = props;
  return (
    <form className="form-container">
      <div style={{ width: '100%' }} className="row">
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Clase *'}
          type={'select'}
          options={options?.vehicleClassesOptions}
          name={'class'}
          register={register}
          placeholder={''}
          onChange={() => onChange?.getVehicleTypes(watch('class'), true)}
          errors={errors?.class?.message}
        />
        {validateTruck(watch) && (
          <Input
            bootstrap={'col-12 col-md-4'}
            label={'Peso del vehículo *'}
            type={'select'}
            name={'truckWeight'}
            register={register}
            placeholder={''}
            errors={errors?.truckWeight?.message}
            options={options?.vehicleWeightOptions}
            onChange={onChange?.changeVehicleData}
          />
        )}
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Tipo *'}
          type={'select'}
          options={options?.vehicleChangetypesOptions}
          name={'type'}
          register={register}
          placeholder={''}
          errors={errors?.type?.message}
          disabled={!watch('class')}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Año *'}
          type={'number'}
          name={'year'}
          register={register}
          placeholder={''}
          errors={errors?.year?.message}
          min={'1990'}
          max={new Date().getFullYear() + 1}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Marca *'}
          type={'select'}
          options={options?.vehicleBrandOptions}
          name={'brand'}
          register={register}
          placeholder={''}
          errors={errors?.brand?.message}
          onChange={() => onChange?.getVehicleModels(watch('brand'), true)}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Modelo *'}
          type={'select'}
          options={options?.vehicleChangeModelOptions}
          name={'model'}
          register={register}
          placeholder={''}
          errors={errors?.model?.message}
          disabled={!watch('brand')}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Color *'}
          type={'text'}
          name={'color'}
          register={register}
          placeholder={''}
          errors={errors?.color?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Placa *'}
          type={'text'}
          name={'plate'}
          register={register}
          placeholder={''}
          errors={errors?.plate?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Kilometraje del Vehículo *'}
          type={'number'}
          name={'km'}
          register={register}
          placeholder={''}
          errors={errors?.km?.message}
          min={'0'}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Serial Carrocería *'}
          type={'text'}
          name={'bodySerial'}
          register={register}
          placeholder={''}
          errors={errors?.bodySerial?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Blindaje *'}
          type={'select'}
          name={'armored'}
          options={options?.armoredOptions}
          register={register}
          placeholder={''}
          errors={errors?.armored?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Estado *'}
          type={'select'}
          options={options?.statesOptions}
          name={'state'}
          register={register}
          placeholder={''}
          errors={errors?.state?.message}
          onChange={() => onChange?.getCities(watch('state'), true)}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Ciudad *'}
          type={'select'}
          options={options?.vehicleChangeOwnercitiesOptions}
          name={'city'}
          register={register}
          placeholder={''}
          errors={errors?.city?.message}
          onChange={() => onChange?.getMunicipalities(watch('city'), true)}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Municipio'}
          type={'select'}
          options={options?.vehicleChangeMunicipalitiesOptions}
          name={'municipality'}
          register={register}
          placeholder={''}
          errors={errors?.municipality?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Dirección *'}
          type={'textarea'}
          name={'address'}
          register={register}
          placeholder={''}
          errors={errors?.address?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Referencia *'}
          type={'textarea'}
          name={'addressReference'}
          register={register}
          placeholder={''}
          errors={errors?.addressReference?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Tipo de Afiliacion*'}
          type={'select'}
          options={options?.affiliationTypeOptions}
          name={'affiliationType'}
          register={register}
          placeholder={''}
          errors={errors?.affiliationType?.message}
        />
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const CreateSalesUserForm = props => {
  const { register, errors, action, cancel, options, onChange, watch } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Nombre*'}
          type={'text'}
          name={'firstName'}
          register={register}
          placeholder={''}
          errors={errors?.firstName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Apellido *'}
          type={'text'}
          name={'lastName'}
          register={register}
          placeholder={''}
          errors={errors?.lastName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Cedula*'}
          type={'number'}
          name={'identification'}
          register={register}
          placeholder={''}
          errors={errors?.identification?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Email *'}
          type={'text'}
          name={'email'}
          register={register}
          placeholder={''}
          errors={errors?.email?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Canal de Venta *'}
          type={'select'}
          options={options?.saleCannelOptions}
          name={'saleChannel'}
          register={register}
          placeholder={''}
          errors={errors?.saleChannel?.message}
        />
        <div className="col-12 col-md-6">
          <p>
            La contraseña debe:
            <li style={{ fontSize: '12px' }}>
              Tener un <b>minimo de 8 caracteres</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener <b>1 mayuscula</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>2 numeros</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>1 caracter especial</b>
            </li>
          </p>
        </div>
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Contraseña *'}
          type={'password'}
          name={'password'}
          register={register}
          placeholder={''}
          errors={errors?.password?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Confirmar contraseña *'}
          type={'password'}
          name={'confirmPassword'}
          register={register}
          placeholder={''}
          errors={errors?.confirmPassword?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Modulo *'}
          type={'select'}
          name={'module'}
          options={options?.modulesOptions}
          register={register}
          placeholder={''}
          errors={errors?.module?.message}
          onChange={onChange?.getRoles}
          disabled={true}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Rol *'}
          type={'select'}
          options={options?.rolesOptions}
          name={'role'}
          register={register}
          placeholder={''}
          errors={errors?.role?.message}
          disabled={options?.rolesOptions.length <= 0}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Estatus *'}
          type={'select'}
          name={'status'}
          options={options?.userStatusOptions}
          register={register}
          placeholder={''}
          errors={errors?.status?.message}
        />{' '}
        {watch('role') === salesRoles?.SALES_COUNTER && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Tipo *'}
            type={'select'}
            name={'type'}
            options={options?.userTypeOptions}
            register={register}
            placeholder={''}
            errors={errors?.type?.message}
          />
        )}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const EditSalesUserForm = props => {
  const { register, errors, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Nombre*'}
          type={'text'}
          name={'firstName'}
          register={register}
          placeholder={''}
          errors={errors?.firstName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Apellido *'}
          type={'text'}
          name={'lastName'}
          register={register}
          placeholder={''}
          errors={errors?.lastName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Cedula*'}
          type={'number'}
          name={'identification'}
          register={register}
          placeholder={''}
          errors={errors?.identification?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Email *'}
          type={'text'}
          name={'email'}
          register={register}
          placeholder={''}
          errors={errors?.email?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Email *'}
          type={'text'}
          name={'email'}
          register={register}
          placeholder={''}
          errors={errors?.email?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Canal de Venta *'}
          type={'select'}
          options={options?.saleCannelOptions}
          name={'saleChannel'}
          register={register}
          placeholder={''}
          errors={errors?.saleChannel?.message}
        />
        <div className="col-md-6"></div>
        <div className="col-12 col-md-6">
          <p>
            La contraseña debe:
            <li style={{ fontSize: '12px' }}>
              Tener un <b>minimo de 8 caracteres</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener <b>1 mayuscula</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>2 numeros</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>1 caracter especial</b>
            </li>
          </p>
        </div>
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Contraseña *'}
          type={'password'}
          name={'password'}
          register={register}
          placeholder={''}
          errors={errors?.password?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Confirmar contraseña *'}
          type={'password'}
          name={'confirmPassword'}
          register={register}
          placeholder={''}
          errors={errors?.confirmPassword?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Estatus *'}
          type={'select'}
          name={'status'}
          options={options?.userStatusOptions}
          register={register}
          placeholder={''}
          errors={errors?.status?.message}
        />{' '}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const CreateCodeForm = props => {
  const { register, errors, control, action, cancel, onChange, options } =
    props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Codigo *'}
          type={'text'}
          name={'code'}
          register={register}
          placeholder={''}
          onChange={onChange?.transformToUppercase}
          errors={errors?.code?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Campaña *'}
          type={'select'}
          options={options?.campaignsOptions}
          name={'campaign'}
          register={register}
          placeholder={''}
          errors={errors?.campaign?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Canal de venta'}
          type={'select'}
          name={'channel'}
          options={options?.salesChannelOptions}
          register={register}
          placeholder={''}
          errors={errors?.channel?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Estatus'}
          type={'select'}
          name={'status'}
          options={options?.codeStatusOptions}
          register={register}
          placeholder={''}
          errors={errors?.status?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Descuento'}
          type={'number'}
          name={'discountNumber'}
          register={register}
          placeholder={''}
          errors={errors?.discountNumber?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Tipo de Descuento (% o $)'}
          type={'select'}
          name={'discountType'}
          options={options?.discountTypeOptions}
          register={register}
          placeholder={''}
          errors={errors?.discountType?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Cantidad Máxima de usos'}
          type={'number'}
          name={'quantity'}
          register={register}
          placeholder={''}
          errors={errors?.quantity?.message}
          min={'0'}
        />{' '}
        <h6>Planes donde aplica el código</h6>
        <div className="col-12">
          <div className="row">
            {options?.membershipOptions?.map(m => (
              <Input
                bootstrap={'col-12 col-md-6'}
                subLabel={capitalize(m?.name)}
                type={'checkbox'}
                name={m?.name}
                register={register}
                placeholder={''}
                errors={errors?.appliesToNew?.message}
              />
            ))}
          </div>
        </div>
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Aplica a Renovación'}
          type={'checkbox'}
          name={'appliesToRenewal'}
          register={register}
          placeholder={''}
          errors={errors?.appliesToRenewal?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Aplica a Nuevas'}
          type={'checkbox'}
          name={'appliesToNew'}
          register={register}
          placeholder={''}
          errors={errors?.appliesToNew?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Fecha de Inicio *'}
          type={'date'}
          name={'validFrom'}
          placeholder={''}
          register={register}
          errors={errors?.validFrom?.message}
          control={control}
          minDate={new Date()}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Hora de inicio *'}
          type={'time'}
          name={'fromTime'}
          placeholder={''}
          register={register}
          errors={errors?.fromTime?.message}
          control={control}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Fecha de Finalizacion *'}
          type={'date'}
          name={'validTo'}
          placeholder={''}
          register={register}
          errors={errors?.validTo?.message}
          control={control}
          minDate={new Date()}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Hora de Finalizacion *'}
          type={'time'}
          name={'toTime'}
          placeholder={''}
          register={register}
          errors={errors?.toTime?.message}
          control={control}
        />
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Crear Codigo'} />
      </div>
    </form>
  );
};

export const CreateGiftcardSaleForm = props => {
  const {
    register,
    errors,
    control,
    action,
    cancel,
    onChange,
    options,
    watch,
    limit,
    rate,
    setValue,
    fileUploaded,
    setFileUploaded,
  } = props;

  const onDrop = useCallback((acceptedFiles, index) => {
    if (
      !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
        acceptedFiles[0].type
      )
    ) {
      notify('Agregar un formato valido de imagen', 'error');
      return null;
    }
    let maxWeight = 3;
    if (acceptedFiles[0].size * 0.000001 > maxWeight) {
      notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
    } else {
      setFileUploaded(prevState => {
        const newFiles = [...prevState];
        newFiles[index] = acceptedFiles[0];
        return newFiles;
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => onDrop(acceptedFiles, 0),
  });

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: acceptedFiles => onDrop(acceptedFiles, 1),
    });

  const [BsS, setBsS] = useState('');

  const calculatesPriceInBsS = amount => {
    return (amount * rate).toFixed(2);
  };

  useEffect(() => {
    const amountUSD1 = watch('amount0');
    if (amountUSD1) {
      const calculatedBsS = calculatesPriceInBsS(amountUSD1);
      setBsS(calculatedBsS);
      setValue('amountBsS0', calculatedBsS); // Actualiza el valor del campo amountBsS0
    }
    const amountUSD2 = watch('amount1');
    if (amountUSD2) {
      const calculatedBsS = calculatesPriceInBsS(amountUSD2);
      setBsS(calculatedBsS);
      setValue('amountBsS1', calculatedBsS); // Actualiza el valor del campo amountBsS1
    }
  }, [watch('amount1'), watch('amount0'), rate, setValue]);

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Tipo de Venta *'}
          type={'select'}
          options={options?.giftCardTypeOptions}
          name={'saleType'}
          register={register}
          placeholder={''}
          errors={errors?.saleType?.message}
          onChange={e =>
            onChange?.getActiveGiftcards(
              watch('channel'),
              watch('saleType'),
              watch('membership')
            )
          }
        />
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Asesor'}
            type={'select'}
            name={'seller'}
            options={options?.sellers}
            register={register}
            placeholder={''}
            errors={errors?.seller?.message}
          />
        )}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Canal de venta'}
          type={'select'}
          name={'channel'}
          options={options?.salesChannelOptions}
          register={register}
          placeholder={''}
          errors={errors?.channel?.message}
          onChange={e =>
            onChange?.getActiveGiftcards(
              watch('channel'),
              watch('saleType'),
              watch('membership')
            )
          }
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Plan'}
          type={'select'}
          name={'membership'}
          options={options?.membershipOptions}
          register={register}
          placeholder={''}
          errors={errors?.membership?.message}
          onChange={e =>
            onChange?.getActiveGiftcards(
              watch('channel'),
              watch('saleType'),
              watch('membership')
            )
          }
          disabled={!watch('saleType') || !watch('channel')}
        />{' '}
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Código Público *'}
            type={'select'}
            options={options?.publicCodes}
            name={'publicCode'}
            register={register}
            placeholder={''}
            errors={errors?.publicCode?.message}
            disabled={
              !watch('channel') || !watch('saleType') || !watch('membership')
            }
            onChange={onChange?.selectPublicCode}
          />
        )}
        {watch('saleType') !== giftCardType?.PARTICULAR && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={`Cantidad de Giftcards ${limit?.limitGiftcard ? `(max ${limit?.limitGiftcard})` : ``
              }`}
            type={'number'}
            name={'saleQuantity'}
            register={register}
            placeholder={''}
            errors={errors?.saleQuantity?.message}
            min={1}
            max={limit?.limitGiftcard}
            onChange={onChange?.validateLimitGiftcards}
            disabled={
              !watch('channel') ||
              !watch('saleType') ||
              !watch('membership') ||
              limit?.limitGiftcard === '0'
            }
          />
        )}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Fecha de la venta *'}
          type={'date'}
          name={'saleDate'}
          placeholder={''}
          register={register}
          errors={errors?.saleDate?.message}
          control={control}
        />
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <>
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'RCV'}
              type={'checkbox'}
              name={'includesRcv'}
              register={register}
              placeholder={''}
              errors={errors?.includesRcv?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de antiguedad'}
              type={'checkbox'}
              name={'yearSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.yearSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'recarga de Km ilimitado'}
              type={'checkbox'}
              name={'unlimitedKm'}
              register={register}
              placeholder={''}
              errors={errors?.unlimitedKm?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de blindaje'}
              type={'checkbox'}
              name={'armorSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.armorSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de Km extra'}
              type={'checkbox'}
              name={'extraKmSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.extraKmSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de tipo de vehículo'}
              type={'checkbox'}
              name={'classSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.classSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Nombre Cliente *'}
              type={'text'}
              name={'firstName'}
              register={register}
              placeholder={''}
              errors={errors?.firstName?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Apellido Cliente *'}
              type={'text'}
              name={'lastName'}
              register={register}
              placeholder={''}
              errors={errors?.lastName?.message}
            />
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'C.I / RIF*'}
              type={'select'}
              name={'identificationType'}
              register={register}
              placeholder={''}
              options={options?.idCodes}
              errors={errors?.identificationType?.message}
            />
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'identification'}
              register={register}
              placeholder={''}
              errors={errors?.identification?.message}
            />
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'Teléfono*'}
              type={'select'}
              name={'phonePrefix'}
              register={register}
              placeholder={''}
              options={options?.phoneCodes}
              errors={errors?.phonePrefix?.message}
            />
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'phoneNumber'}
              register={register}
              placeholder={''}
              errors={errors?.phoneNumber?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Correo Cliente *'}
              type={'text'}
              name={'email'}
              register={register}
              placeholder={''}
              errors={errors?.email?.message}
            />
          </>
        )}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Valor del plan'}
          type={'number'}
          name={'planAmount'}
          register={register}
          placeholder={''}
          errors={errors?.planAmount?.message}
        />
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Cantidad de Métodos de Pago'}
            type={'select'}
            name={'paymentMethods'}
            register={register}
            options={[
              { _id: 1, name: 'Pago único' },
              { _id: 2, name: 'Pago Mixto' },
            ]}
            placeholder={''}
            errors={errors?.planAmount?.message}
          />
        )}
        {watch('paymentMethods') > 0 &&
          watch('saleType') === giftCardType?.PARTICULAR && (
            <>
              <div style={{ width: '30%' }}></div>
              <Input
                bootstrap={'col-12 col-md-6'}
                label={'Método de pago 1'}
                type={'select'}
                name={'method0'}
                register={register}
                options={options?.paymentMethods}
                placeholder={''}
                errors={errors?.method0?.message}
              />
              <Input
                bootstrap={'col-12 col-md-3'}
                label={'Monto USD 1'}
                type={'number'}
                name={'amount0'}
                register={register}
                placeholder={''}
                errors={errors?.amount0?.message}
              />
              <Input
                bootstrap={'col-12 col-md-3'}
                label={'Monto Bs 1'}
                type={'number'}
                name={'amountBsS0'}
                register={register}
                value={BsS}
                onChange={e => setBsS(e.target.value)}
                placeholder={''}
                errors={errors?.amountBsS0?.message}
              />
              <Input
                bootstrap={'col-12 col-md-6'}
                label={'Referencia 1'}
                type={'text'}
                name={'reference0'}
                register={register}
                placeholder={''}
                errors={errors?.reference0?.message}
              />
              <div className="col-6">
                <label className="dropzone-label">{'Comprobante'}</label>
                {fileUploaded[0] ? (
                  <FileComponent
                    file={fileUploaded[0]}
                    remove={() =>
                      setFileUploaded(files => files.filter((_, i) => i !== 0))
                    }
                  />
                ) : (
                  <Dropzone
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    message={'(PNG, JPG, JEPG, máx. 3MB)'}
                  />
                )}
              </div>
              {watch('paymentMethods') > 1 && (
                <>
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Método de pago 2'}
                    type={'select'}
                    name={'method1'}
                    register={register}
                    options={options?.paymentMethods}
                    placeholder={''}
                    errors={errors?.method1?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-3'}
                    label={'Monto USD 2'}
                    type={'number'}
                    name={'amount1'}
                    register={register}
                    placeholder={''}
                    errors={errors?.amount1?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-3'}
                    label={'Monto Bs 2'}
                    type={'number'}
                    name={'amountBsS1'}
                    register={register}
                    placeholder={''}
                    value={BsS}
                    onChange={e => setBsS(e.target.value)}
                    errors={errors?.amountBsS1?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Referencia 2'}
                    type={'text'}
                    name={'reference1'}
                    register={register}
                    placeholder={''}
                    errors={errors?.reference1?.message}
                  />
                  <div className="col-6">
                    <label className="dropzone-label">{'Comprobante'}</label>
                    {fileUploaded[1] ? (
                      <FileComponent
                        file={fileUploaded[1]}
                        remove={() =>
                          setFileUploaded(files =>
                            files.filter((_, i) => i !== 1)
                          )
                        }
                      />
                    ) : (
                      <Dropzone
                        getRootProps={getRootProps1}
                        getInputProps={getInputProps1}
                        message={'(PNG, JPG, JEPG, máx. 3MB)'}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
            <p>
              {errors?.amountsSumError?.message
                ? errors?.amountsSumError?.message
                : ''}
            </p>
          </div>
        )}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Registrar venta'} />
      </div>
    </form>
  );
};

export const EditGiftcardSaleForm = props => {
  const {
    register,
    errors,
    control,
    action,
    cancel,
    onChange,
    options,
    watch,
    fileUploaded,
    setFileUploaded,
    rate,
    setValue,
  } = props;

  const onDrop = useCallback((acceptedFiles, index) => {
    if (
      !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
        acceptedFiles[0].type
      )
    ) {
      notify('Agregar un formato valido de imagen', 'error');
      return null;
    }
    let maxWeight = 3;
    if (acceptedFiles[0].size * 0.000001 > maxWeight) {
      notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
    } else {
      const fileWithMetadata = { file: acceptedFiles[0], inputUploaded: index };
      setFileUploaded(prevState => {
        const newFiles = [...prevState];
        newFiles[index] = fileWithMetadata;
        return newFiles;
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => onDrop(acceptedFiles, 0),
  });

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: acceptedFiles => onDrop(acceptedFiles, 1),
    });

  const [BsS, setBsS] = useState('');

  const calculatesPriceInBsS = amount => {
    return (amount * rate).toFixed(2);
  };

  useEffect(() => {
    const amountUSD1 = watch('amount0');
    if (amountUSD1) {
      const calculatedBsS = calculatesPriceInBsS(amountUSD1);
      setBsS(calculatedBsS);
      setValue('amountBsS0', calculatedBsS); // Actualiza el valor del campo amountBsS0
    }
    const amountUSD2 = watch('amount1');
    if (amountUSD2) {
      const calculatedBsS = calculatesPriceInBsS(amountUSD2);
      setBsS(calculatedBsS);
      setValue('amountBsS1', calculatedBsS); // Actualiza el valor del campo amountBsS1
    }
  }, [watch('amount1'), watch('amount0'), rate, setValue]);

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Tipo de Venta *'}
          type={'select'}
          options={options?.giftCardTypeOptions}
          name={'saleType'}
          register={register}
          placeholder={''}
          errors={errors?.saleType?.message}
          onChange={e =>
            onChange?.getActiveGiftcards(
              watch('channel'),
              watch('saleType'),
              watch('membership')
            )
          }
          disabled={true}
        />
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Asesor'}
            type={'select'}
            name={'seller'}
            options={options?.sellers}
            register={register}
            placeholder={''}
            errors={errors?.seller?.message}
          />
        )}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Canal de venta'}
          type={'text'}
          name={'channel'}
          register={register}
          placeholder={''}
          errors={errors?.channel?.message}
          onChange={e =>
            onChange?.getActiveGiftcards(
              watch('channel'),
              watch('saleType'),
              watch('membership')
            )
          }
          disabled={true}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Plan'}
          type={'text'}
          name={'membership'}
          register={register}
          placeholder={''}
          errors={errors?.membership?.message}
          onChange={e =>
            onChange?.getActiveGiftcards(
              watch('channel'),
              watch('saleType'),
              watch('membership')
            )
          }
          disabled={true}
        />{' '}
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Código Público *'}
            type={'number'}
            name={'publicCode'}
            register={register}
            placeholder={''}
            errors={errors?.publicCode?.message}
            disabled={true}
            onChange={onChange?.selectPublicCode}
          />
        )}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Valor del plan'}
          type={'number'}
          name={'planAmount'}
          register={register}
          placeholder={''}
          errors={errors?.planAmount?.message}
        />{' '}
        {watch('saleType') !== giftCardType?.PARTICULAR && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={`Cantidad de Giftcards ${options?.limitGiftcard ? `(max ${options?.limitGiftcard})` : ``
              }`}
            type={'number'}
            name={'saleQuantity'}
            register={register}
            placeholder={''}
            errors={errors?.saleQuantity?.message}
            min={1}
            max={options?.limitGiftcard}
            onChange={onChange?.validateLimitGiftcards}
            disabled={
              !watch('channel') ||
              !watch('saleType') ||
              !watch('membership') ||
              options?.limitGiftcard === '0'
            }
          />
        )}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Fecha de la venta *'}
          type={'date'}
          name={'saleDate'}
          placeholder={''}
          register={register}
          errors={errors?.saleDate?.message}
          control={control}
        />
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <>
            <div style={{ width: '30%' }}></div>
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'RCV'}
              type={'checkbox'}
              name={'includesRcv'}
              register={register}
              placeholder={''}
              errors={errors?.includesRcv?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de antiguedad'}
              type={'checkbox'}
              name={'yearSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.yearSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'recarga de Km ilimitado'}
              type={'checkbox'}
              name={'unlimitedKm'}
              register={register}
              placeholder={''}
              errors={errors?.unlimitedKm?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de blindaje'}
              type={'checkbox'}
              name={'armorSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.armorSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de Km extra'}
              type={'checkbox'}
              name={'extraKmSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.extraKmSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              subLabel={'Recarga de tipo de vehículo'}
              type={'checkbox'}
              name={'classSurcharge'}
              register={register}
              placeholder={''}
              errors={errors?.classSurcharge?.message}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Nombre Cliente *'}
              type={'test'}
              name={'firstName'}
              register={register}
              placeholder={''}
              errors={errors?.firstName?.message}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Apellido Cliente *'}
              type={'text'}
              name={'lastName'}
              register={register}
              placeholder={''}
              errors={errors?.lastName?.message}
            />{' '}
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'C.I / RIF*'}
              type={'select'}
              name={'identificationType'}
              register={register}
              placeholder={''}
              options={options?.idCodes}
              errors={errors?.identificationType?.message}
            />{' '}
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'identification'}
              register={register}
              placeholder={''}
              errors={errors?.identification?.message}
            />{' '}
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'Teléfono*'}
              type={'select'}
              name={'phonePrefix'}
              register={register}
              placeholder={''}
              options={options?.phoneCodes}
              errors={errors?.phonePrefix?.message}
            />{' '}
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'phoneNumber'}
              register={register}
              placeholder={''}
              errors={errors?.phoneNumber?.message}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Correo Cliente *'}
              type={'text'}
              name={'email'}
              register={register}
              placeholder={''}
              errors={errors?.email?.message}
            />{' '}
          </>
        )}
        {watch('saleType') === giftCardType?.PARTICULAR && (
          <>
            <div style={{ width: '30%' }}></div>
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Cantidad de Métodos de Pago'}
              type={'select'}
              name={'paymentMethods'}
              register={register}
              options={[
                { _id: 1, name: 'Pago único' },
                { _id: 2, name: 'Pago Mixto' },
              ]}
              placeholder={''}
              errors={errors?.planAmount?.message}
            />
          </>
        )}
        {watch('paymentMethods') > 0 &&
          watch('saleType') === giftCardType?.PARTICULAR && (
            <>
              <div style={{ width: '30%' }}></div>
              <Input
                bootstrap={'col-12 col-md-6'}
                label={'Método de pago 1'}
                type={'select'}
                name={'method0'}
                register={register}
                options={options?.paymentMethods}
                placeholder={''}
                errors={errors?.method0?.message}
              />
              <Input
                bootstrap={'col-12 col-md-3'}
                label={'Monto USD 1'}
                type={'number'}
                name={'amount0'}
                register={register}
                placeholder={''}
                errors={errors?.amount0?.message}
              />
              <Input
                bootstrap={'col-12 col-md-3'}
                label={'Monto Bs 1'}
                type={'number'}
                name={'amountBsS0'}
                register={register}
                value={BsS}
                onChange={e => setBsS(e.target.value)}
                placeholder={''}
                errors={errors?.amountBsS0?.message}
              />
              <Input
                bootstrap={'col-12 col-md-6'}
                label={'Referencia 1'}
                type={'text'}
                name={'reference0'}
                register={register}
                placeholder={''}
                errors={errors?.reference0?.message}
              />
              <div className="col-3">
                <label className="dropzone-label">{'Comprobante'}</label>
                {fileUploaded[0] ? (
                  <FileComponent
                    file={fileUploaded[0].file}
                    remove={() =>
                      setFileUploaded(files => files.filter((_, i) => i !== 0))
                    }
                  />
                ) : (
                  <Dropzone
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    message={'(PNG, JPG, JEPG, máx. 3MB)'}
                  />
                )}
              </div>
              {watch('paymentMethods') > 0 && watch('refImg0') && (
                <div
                  className="col-3"
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <a
                    style={{
                      color: '#F27730',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={watch('refImg0')}
                  >
                    Ver Comprobante
                  </a>
                </div>
              )}
              {watch('paymentMethods') > 1 && (
                <>
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Método de pago 2'}
                    type={'select'}
                    name={'method1'}
                    register={register}
                    options={options?.paymentMethods}
                    placeholder={''}
                    errors={errors?.method1?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-3'}
                    label={'Monto USD 2'}
                    type={'number'}
                    name={'amount1'}
                    register={register}
                    placeholder={''}
                    errors={errors?.amount1?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-3'}
                    label={'Monto Bs 2'}
                    type={'number'}
                    name={'amountBsS1'}
                    register={register}
                    placeholder={''}
                    value={BsS}
                    onChange={e => setBsS(e.target.value)}
                    errors={errors?.amountBsS1?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Referencia 2'}
                    type={'text'}
                    name={'reference1'}
                    register={register}
                    placeholder={''}
                    errors={errors?.reference1?.message}
                  />
                  <div className="col-3">
                    <label className="dropzone-label">{'Comprobante'}</label>
                    {fileUploaded[1] ? (
                      <FileComponent
                        file={fileUploaded[1].file}
                        remove={() =>
                          setFileUploaded(files =>
                            files.filter((_, i) => i !== 1)
                          )
                        }
                      />
                    ) : (
                      <Dropzone
                        getRootProps={getRootProps1}
                        getInputProps={getInputProps1}
                        message={'(PNG, JPG, JEPG, máx. 3MB)'}
                      />
                    )}
                  </div>
                  {watch('paymentMethods') > 1 && watch('refImg1') && (
                    <div
                      className="col-3"
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <a
                        style={{
                          color: '#F27730',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        target="_blank"
                        rel="noreferrer"
                        href={watch('refImg1')}
                      >
                        Ver Comprobante
                      </a>
                    </div>
                  )}
                </>
              )}
            </>
          )}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Editar venta'} />
      </div>
    </form>
  );
};

export const EditCodeForm = props => {
  const { register, errors, control, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Codigo *'}
          type={'text'}
          name={'code'}
          register={register}
          placeholder={''}
          errors={errors?.code?.message}
          disabled={true}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Campaña *'}
          type={'select'}
          options={options?.campaignsOptions}
          name={'campaign'}
          register={register}
          placeholder={''}
          errors={errors?.campaign?.message}
          disabled={true}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Canal de venta'}
          type={'select'}
          name={'channel'}
          options={options?.salesChannelOptions}
          register={register}
          placeholder={''}
          errors={errors?.channel?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Estatus'}
          type={'select'}
          name={'status'}
          options={options?.codeStatusOptions}
          register={register}
          placeholder={''}
          errors={errors?.status?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Descuento'}
          type={'number'}
          name={'discountNumber'}
          register={register}
          placeholder={''}
          errors={errors?.discountNumber?.message}
          disabled={true}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Tipo de Descuento (% o $)'}
          type={'select'}
          name={'discountType'}
          options={options?.discountTypeOptions}
          register={register}
          placeholder={''}
          errors={errors?.discountType?.message}
          disabled={true}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Cantidad Máxima de usos'}
          type={'number'}
          name={'quantity'}
          register={register}
          placeholder={''}
          errors={errors?.quantity?.message}
          min={'0'}
        />{' '}
        <h6>Planes donde aplica el código</h6>
        <div className="col-12">
          <div className="row">
            {options?.membershipOptions?.map(m => (
              <Input
                bootstrap={'col-12 col-md-6'}
                subLabel={capitalize(m?.name)}
                type={'checkbox'}
                name={m?.name}
                register={register}
                placeholder={''}
                errors={errors?.appliesToNew?.message}
              />
            ))}
          </div>
        </div>
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Aplica a Renovación'}
          type={'checkbox'}
          name={'appliesToRenewal'}
          register={register}
          placeholder={''}
          errors={errors?.appliesToRenewal?.message}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Aplica a Nuevas'}
          type={'checkbox'}
          name={'appliesToNew'}
          register={register}
          placeholder={''}
          errors={errors?.appliesToNew?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Fecha de Inicio *'}
          type={'date'}
          name={'validFrom'}
          placeholder={''}
          register={register}
          errors={errors?.validFrom?.message}
          control={control}
          minDate={new Date()}
          disabled={true}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Hora de inicio *'}
          type={'time'}
          name={'fromTime'}
          placeholder={''}
          register={register}
          errors={errors?.fromTime?.message}
          control={control}
          disabled={true}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Fecha de Finalizacion *'}
          type={'date'}
          name={'validTo'}
          placeholder={''}
          register={register}
          errors={errors?.validTo?.message}
          control={control}
          minDate={new Date()}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Hora de Finalizacion *'}
          type={'time'}
          name={'toTime'}
          placeholder={''}
          register={register}
          errors={errors?.toTime?.message}
          control={control}
        />
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Editar Codigo'} />
      </div>
    </form>
  );
};

export const ActivateGiftcardForm = props => {
  const { register, errors, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Codigo *'}
          type={'select'}
          name={'saleChannel'}
          options={options?.salesChannelOptions}
          register={register}
          placeholder={''}
          errors={errors?.saleChannel?.message}
        />{' '}
        <Input
          bootstrap={'col-12'}
          label={'Tipo de venta *'}
          type={'select'}
          name={'saleType'}
          options={options?.giftCardTypeOptions}
          register={register}
          placeholder={''}
          errors={errors?.saleType?.message}
        />{' '}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Activar'} />
      </div>
    </form>
  );
};

export const EditPaymentDuesForm = props => {
  const {
    register,
    errors,
    cancel,
    action,
    control,
    trigger,
    watch,
    getValues,
    setValue,
  } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const finalPrice = parseFloat(watch('finalPrice'));

  //crea una funcion que sume los valores de los pagos
  const sumPayments = useCallback(() => {
    let total = 0;
    const payments = ['firstPaymentDue', 'secondPaymentDue', 'thirdPaymentDue'];
    payments.forEach(payment => {
      const value = parseFloat(watch(payment));
      if (!isNaN(value)) {
        total += value;
      }
    });
    setTotalPrice(total);
  }, [watch]);

  const firstPaymentDue = watch('firstPaymentDue');
  const secondPaymentDue = watch('secondPaymentDue');
  const thirdPaymentDue = watch('thirdPaymentDue');

  //funcion que detecte si hay dos cuotas con un valor, y si es asi, que rellene la tercera con el valor equivalente para completar  el total de la deuda
  const fillThirdPayment = useCallback(() => {
    const firstPayment = parseFloat(watch('firstPaymentDue')) || 0;
    const secondPayment = parseFloat(watch('secondPaymentDue')) || 0;
    const thirdPayment = parseFloat(watch('thirdPaymentDue')) || 0;

    const payments = [firstPayment, secondPayment, thirdPayment];
    const filledPayments = payments.filter(payment => payment > 0);

    if (filledPayments.length === 2) {
      const value = finalPrice - (firstPayment + secondPayment);
      if (value >= 0) {
        setValue(
          'thirdPaymentDue',
          value > 0 ? parseFloat(value).toFixed(2) : 0
        );
      }
    } else if (filledPayments.length === 3) {
      const value = finalPrice - (firstPayment + secondPayment);
      if (value >= 0) {
        setValue(
          'thirdPaymentDue',
          value > 0 ? parseFloat(value).toFixed(2) : 0
        );
      }
    } else {
      setValue(
        'thirdPaymentDue',
        finalPrice > 0 ? parseFloat(finalPrice).toFixed(2) : 0
      );
    }
  }, [watch, finalPrice, setValue]);

  useEffect(() => {
    sumPayments();
  }, [sumPayments, firstPaymentDue, secondPaymentDue, thirdPaymentDue]);

  useEffect(() => {
    fillThirdPayment();
  }, [
    firstPaymentDue,
    secondPaymentDue,
    thirdPaymentDue,
    fillThirdPayment,
    sumPayments,
  ]);

  const handleInputChange = async field => {
    const result = await trigger(field);
    if (result) {
      sumPayments();
    }
  };

  return (
    <form className="form-container">
      <div className="row">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <div
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
          >
            <h2>Editar cuotas</h2>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <div
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
          >
            <h6 style={{ marginRight: '10px' }}>Monto deuda USD</h6>
          </div>

          <Input
            bootstrap={'col-12 col-md-2'}
            label={''}
            type={'text'}
            name={'finalPrice'}
            register={register}
            placeholder={''}
            disabled={true}
            control={control}
          />
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              bootstrap={'col-12 col-md-4'}
              label={'Pago Inicial USD'}
              type={'text'}
              name={'firstPaymentDue'}
              register={register}
              placeholder={''}
              errors={errors?.firstPaymentDue?.message}
              min={0}
              control={control}
              onChange={() => handleInputChange('firstPaymentDue')}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Fecha de corte 1'}
              type={'date'}
              name={'firstPaymentDate'}
              register={register}
              placeholder={''}
              errors={errors?.firstPaymentDate?.message}
              control={control}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              bootstrap={'col-12 col-md-4'}
              label={'Cuota 1 USD'}
              type={'text'}
              name={'secondPaymentDue'}
              register={register}
              placeholder={''}
              errors={errors?.secondPaymentDue?.message}
              control={control}
              onChange={() => handleInputChange('secondPaymentDue')}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Fecha de corte 2'}
              type={'date'}
              name={'secondPaymentDate'}
              register={register}
              placeholder={''}
              errors={errors?.secondPaymentDate?.message}
              control={control}
            />
          </div>
          <div
            className="div"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Input
              bootstrap={'col-12 col-md-4'}
              label={'Cuota 2 USD'}
              type={'text'}
              name={'thirdPaymentDue'}
              register={register}
              placeholder={''}
              errors={errors?.thirdPaymentDue?.message}
              control={control}
              onChange={() => handleInputChange('thirdPaymentDue')}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Fecha de corte 3'}
              type={'date'}
              name={'thirdPaymentDate'}
              register={register}
              placeholder={''}
              errors={errors?.thirdPaymentDate?.message}
              control={control}
            />
          </div>
          {totalPrice > getValues('finalPrice') ? (
            <p
              style={{
                color: 'red',
                fontSize: '14px',
                textAlign: 'center',
                width: '100%',
              }}
            >
              La suma de los montos no debe exceder el valor permitido
            </p>
          ) : totalPrice < getValues('finalPrice') ? (
            <p
              style={{
                color: 'red',
                fontSize: '14px',
                textAlign: 'center',
                width: '100%',
              }}
            >
              La suma de los montos no debe ser menor al valor permitido
            </p>
          ) : null}
        </div>
      </div>

      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};
