import { useEffect, useState } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton } from '../../../common/Buttons/Buttons';
import { paymentType } from '../../../../assets/helpers/options';
import {
  PaymentsTable,
  PendingPaymentsTable,
} from '../../../common/Tables/Tables';
import {
  paymentsTableHeader,
  pendingPaymentsTableHeader,
  casheaPaymentsTableHeader,
} from '../../../../assets/helpers/tables.js';
import {
  PaymentModal,
  EditPaymentModal,
  EditPaymentDuesModal,
} from '../../../common/Modals/Modals';
import { paymentsService } from '../../../../services/paymentsService';
import { rateService } from '../../../../services/rateService';
import { productService } from '../../../../services/productService';
import { currencyRateService } from '../../../../services/currencyRateService';
import { notify } from '../../../../assets/helpers/toast';
import {
  MethodValidation,
  getCorrectDate,
  NaNtoNumber,
  filterOption,
  returnIGTF,
} from '../../../../assets/helpers/funciones';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { OderCardHeader } from './OrderComponents';
import {
  paymentTypeOptions as paymentTypesOptions,
  planStatus,
  paymentModalTypes,
  userType,
} from '../../../../assets/helpers/options';
import authService from '../../../../services/authService';
import { salesRoles } from '../../../../assets/helpers/roles';
import moment from 'moment';

import './OrderComponent.scss';
import { membershipService } from '../../../../services/membershipService.js';

const allowAdmin = () => {
  if ([salesRoles.SALES_ADMIN].includes(authService?.getUserType())) {
    return false;
  } else {
    return true;
  }
};

const allowEditPayment = () => {
  if (
    [salesRoles.SALES_ADMIN, salesRoles?.SALES_ADMIN_AUX].includes(
      authService?.getUserType()
    )
  ) {
    return false;
  } else {
    return true;
  }
};

const getDuefromSuccessPayments = membership => {
  const { payments, priceInfo } = membership;

  let due = 0;

  if (payments.length > 0) {
    // sum all payment.totalAmount from payments array toFixed(2)
    let totalPaid = payments.reduce((acc, payment) => {
      return acc + payment.totalAmount;
    }, 0);

    // subtract totalPaid from priceInfo.finalPrice toFixed(2)
    due = priceInfo.finalPrice - totalPaid;

    return due.toFixed(2);
  }

  return due;
};

export const PaymentsCard = props => {
  const { label, membership, reloadMembership } = props;
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [openEditPaymentDueModal, setOpenEditPaymentDueModal] = useState(false);
  const [openEditPaymentModal, setOpenEditPaymentModal] = useState({
    isOpen: false,
    payment: '',
  });
  const [fileUploaded, setFileUploaded] = useState([]);
  const [dollarRate, setDollarRate] = useState('');
  const [bsDisabled, setbsDisabled] = useState(true);
  const [creationDate, setCreationDate] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);

  const { setValue } = useForm({
    resolver: yupResolver(schemas.updateMembershipForm()),
  });

  const { setValue: setValue2, watch: watch2 } = useForm({
    resolver: yupResolver(schemas.updatePaymentForm()),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    getValues: getValues3,
    resetField: resetField3,
    watch: watch3,
    reset: reset3,
    control: control3,
  } = useForm({
    resolver: yupResolver(schemas.renewMembershipSchema()),
  });

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    setValue: setValue4,
    watch: watch4,
    reset: reset4,
    control: control4,
  } = useForm({
    resolver: yupResolver(schemas.editPaymentSchema()),
  });

  const {
    register: register5,
    handleSubmit: handleSubmit5,
    formState: { errors: errors5 },
    getValues: getValues5,
    setValue: setValue5,
    watch: watch5,
    reset: reset5,
    control: control5,
    trigger: trigger5,
  } = useForm({
    resolver: yupResolver(
      schemas.updatePaymentDuesSchema(getDuefromSuccessPayments(membership))
    ),
  });

  const preloadForm = () => {
    // DATOS DEL PLAN
    setValue('affiliationType', membership?.affiliationType);
    setValue('contractType', membership?.contractType);
    setValue('contactInCampaign', membership?.contactInCampaign);
    // FECHAS ASOCIADAS
    setValue(
      'createdAt',
      membership?.createdAt ? getCorrectDate(membership?.createdAt) : ''
    );
    setValue(
      'beginDate',
      membership?.beginDate ? getCorrectDate(membership?.beginDate) : ''
    );
    setValue(
      'endDate',
      membership?.endDate ? getCorrectDate(membership?.endDate) : ''
    );
    setValue(
      'rcvBeginDate',
      membership?.rcvBeginDate ? getCorrectDate(membership?.rcvBeginDate) : ''
    );
    setValue(
      'rcvEndDate',
      membership?.rcvEndDate ? getCorrectDate(membership?.rcvEndDate) : ''
    );

    if (membership?.endDateFromLastMembership) {
      setValue(
        'endDateFromLastMembership',
        membership?.endDateFromLastMembership
          ? getCorrectDate(membership?.endDateFromLastMembership)
          : ''
      );
    }
    if (membership?.rcvEndDateFromLastMembership) {
      setValue(
        'rcvEndDateFromLastMembership',
        membership?.rcvEndDateFromLastMembership
          ? getCorrectDate(membership?.rcvEndDateFromLastMembership)
          : ''
      );
    }
    // DATOS DEL PAGO
    if (membership?.membershipStatus !== 'INCOMPLETO') {
      setValue2('discountCode', membership?.discountCode);
      setValue2(
        'discountByCode',
        membership?.priceInfo?.discountByCode
          ? membership?.priceInfo?.discountByCode + ' $'
          : ''
      );

      setValue2('giftcardCode', membership?.giftcardCode);
      setValue2(
        'discountByGiftcard',
        membership?.priceInfo?.discountByGiftcard
          ? membership?.priceInfo?.discountByGiftcard + ' $'
          : ''
      );
    }

    setValue2('paymentStatus', membership?.paymentStatus);
    setValue2(
      'finalPrice',
      parseFloat(membership?.priceInfo?.finalPrice?.toFixed(2))
    );
    setValue2(
      'finalPriceInBsS',
      parseFloat(membership?.priceInfo?.finalPriceInBsS?.toFixed(2))
    );
    setValue2('paymentType', membership?.paymentType);

    if (membership?.discountCode) {
      setValue2('code', membership?.discountCode);
      setValue2('discountCode', membership?.discountCode);
      setValue2(
        'discountByCode',
        membership?.priceInfo?.discountByCode
          ? membership?.priceInfo?.discountByCode + ' $'
          : ''
      );
    }

    if (membership?.giftcardCode) {
      setValue2('giftcardCode', membership?.giftcardCode);
      setValue2(
        'discountByGiftcard',
        membership?.priceInfo?.discountByGiftcard
          ? membership?.priceInfo?.discountByGiftcard + ' $'
          : ''
      );
    }

    setValue2('paymentMethodsQuantity', membership?.payments?.length);
    if (membership?.membershipStatus === 'INCOMPLETO') {
      setValue2('paymentMethodsQuantity', 1);
    }

    if (membership?.payments?.length > 0) {
      for (let i = 0; i < membership?.payments?.length; i++) {
        setValue2(`paymentAmount${i}`, membership?.payments[i]?.totalAmount);
        setValue2(
          `paymentAmountBsS${i}`,
          membership?.payments[i]?.totalAmountInBsS?.toFixed(2)
        );
        Promise.allSettled([getPaymentMethods()]).then(r => {
          setTimeout(() => {
            setValue2(`paymentMethod${i}`, membership?.payments[i]?.methodId);
          }, 2500);
        });
        setValue2(`paymentMethod${i}`, membership?.payments[i]?.methodId);
        setValue2(`paymentReference${i}`, membership?.payments[i]?.reference);
        setValue2(
          `casheaReference${i}`,
          membership?.payments[i]?.referenceCashea
        );
      }
    }

    if (membership?.paymentType === paymentType?.FINANCIADO) {
      Promise.allSettled([getPaymentMethods(), getDollarRate()]).then(r => {
        setTimeout(() => {
          for (let i = 0; i < membership?.paymentDues?.length; i++) {
            let [selectedPayment] = membership?.payments?.filter(
              p => p?._id === membership?.paymentDues[i]?.payment[0]
            );

            if (selectedPayment) {
              setValue2(
                `paymentDues[${i}].amount`,
                selectedPayment.totalAmount
              );
              setValue2(
                `paymentDues[${i}].amountBs`,
                selectedPayment.totalAmountInBsS?.toFixed(2)
              );
              setValue2(
                `paymentDues[${i}].reference`,
                selectedPayment.reference
              );
              setValue2(
                `paymentDues[${i}].paymentMethod`,
                selectedPayment?.methodId
              );
            } else {
              setValue2(
                `paymentDues[${i}].amount`,
                membership?.paymentDues[i]?.amount?.toFixed(2)
              );
              setValue2(
                `paymentDues[${i}].amountBs`,
                (membership?.paymentDues[i]?.amount * dollarRate)?.toFixed(2)
              );
              setValue2(
                `paymentDues[${i}].paymentMethod`,
                membership?.paymentDues[i]?.paymentMethod
              );
            }

            if (selectedPayment?.length > 0) {
              setValue2(
                `paymentDues[${i}].reference`,
                selectedPayment[0]?.reference
              );
            }

            setValue2(
              `paymentDues[${i}].payment`,
              membership?.paymentDues[i]?.payment[0]
                ? 'Pago realizado'
                : 'Cuenta por cobrar'
              //Aqui colocar iuna funcion que ponga el estatus 'Pago realizado', 'Cuenta por cobrar' o pago vencido dependiendo de la fecha del pago
            );

            setValue2(
              `paymentDues[${i}].paymentDate`,
              membership?.paymentDues[i]?.paymentDate
                ? moment(membership?.paymentDues[i]?.paymentDate)
                  .format('DD/MM/YYYY')
                : ''
            );
            setValue2(
              `paymentDues[${i}].dueDate`,
              membership?.paymentDues[i]?.dueDate
                ? moment(membership?.paymentDues[i]?.dueDate)
                  .format('DD/MM/YYYY')
                : ''
            );
          }
        }, 1500);
      });
    }
  };

  let loadPaymentForms = (params, paymentMethods) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 1; i <= parseInt(paymentMethods); i++) {
      let data = {};

      if (i === 1 && params?.igtf1) {
        data.igtf = true;
      }
      if (i === 2 && params?.igtf2) {
        data.igtf = true;
      }
      let amount1IGTF = NaNtoNumber(parseFloat(watch3('amount1IGTF')));
      let amount1_2IGTF = NaNtoNumber(parseFloat(watch3('amount1_2IGTF')));
      let amount2IGTF = NaNtoNumber(parseFloat(watch3('amount2IGTF')));

      data.amountIGTF = parseFloat(params[`amount` + i.toString()]);

      // Add IGTF 1 and raise flag
      if (i === 1 && amount1IGTF > 0) {
        data.igtf = true;
        data.amountIGTF =
          amount1IGTF + parseFloat(params[`amount` + i.toString()]);
      }

      // Add IGTF 1 in 2 or 2 and raise flag
      if (i !== 1 && (amount1_2IGTF > 0 || amount2IGTF > 0)) {
        data.igtf = true;
        data.amountIGTF =
          amount2IGTF + parseFloat(params[`amount` + i.toString()]);

        if (watch3('mix')) {
          data.amountIGTF = data.amountIGTF + amount1_2IGTF;
        }
      }

      data.amountIGTF = data.amountIGTF?.toFixed(2);

      data.method = params[`method` + i.toString()];
      data.reference = params[`reference` + i.toString()];
      data.amount = params[`amount` + i.toString()];
      data.amountBsS = params[`amountBsS` + i.toString()];
      data.zelleOwner = params[`zelleOwner` + i.toString()];

      payments.push({
        ...data,
      });
    }

    // "REmove unwanted params from JSON"
    let words = [
      'method',
      'reference',
      'amount',
      'amountBsS',
      'paymentMethod',
      'igtf',
      'amountIGTF',
    ];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const appendFile = (data, paymentMethodsQuantity, tipodePago, price) => {
    const formData = new FormData();
    formData.append('paymentType', data?.paymentType);
    formData.append('membership', membership?._id);

    for (var i = 0; i < data?.payments.length; i++) {
      formData.append(`payments[${i}][amount]`, data?.payments[i].amountIGTF);

      if (data?.payments[i]?.igtf && returnIGTF() > 0) {
        formData.append(`payments[${i}][igtf]`, data?.payments[i]?.igtf);
      }

      formData.append(
        `payments[${i}][amountBsS]`,
        (data?.payments[i].amountIGTF * dollarRate)?.toFixed(2)
      );
      formData.append(`payments[${i}][method]`, data?.payments[i].method);
      formData.append(`payments[${i}][reference]`, data?.payments[i].reference);
      if (data?.payments[i]?.method === '646955f7fccc5f07cd2b8d48') {
        // for zelle payments
        formData.append(
          `payments[${i}][accHolder]`,
          data?.payments[i]?.zelleOwner
        );
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(paymentMethodsQuantity) &&
      parseFloat(paymentMethodsQuantity) > 0 &&
      MethodValidation(data?.payments, fileUploaded, paymentMethods)
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }

    if (parseFloat(price) && tipodePago !== paymentType?.FINANCIADO) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum < parseFloat(price)) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }

    for (let i = 0; i < parseInt(paymentMethodsQuantity); i++) {
      if (fileUploaded[i]) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    return formData;
  };

  const payRewenMembership = handleSubmit3(async data => {
    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost, watch3('paymentMethods'));
    let fileToPost = appendFile(
      dataToPost,
      getValues3('paymentMethods'),
      watch3('paymentType'),
      watch3('price')
    );
    if (!isLoading && fileToPost) {
      setIsLoading(true);
      paymentsService
        .createMembershipPayment(fileToPost)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago registrado con exito`, 'success');
            closePaymentModal();
            // history.push('/services');
            setIsLoading(false);
            reloadMembership();
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const payPendingPayment = handleSubmit3(async data => {
    let dataToPost = { ...data };
    // delete dataToPost?.price;
    dataToPost = loadPaymentForms(dataToPost, watch3('paymentMethods'));
    let fileToPost = appendFile(
      dataToPost,
      watch3('paymentMethods'),
      watch3('paymentType'),
      watch3('price')
    );
    if (!isLoading && fileToPost) {
      setIsLoading(true);
      paymentsService
        .createPendingPayment(fileToPost)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago pendiente registrado con exito`, 'success');
            closePaymentModal();
            reloadMembership();
            // history.push('/services');
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const updatePaymentDues = handleSubmit5(async data => {
    let dataToPost = {
      firstPaymentDue: {
        amount: parseFloat(data.firstPaymentDue),
        date: data.firstPaymentDate,
      },
      secondPaymentDue: {
        amount: parseFloat(data.secondPaymentDue),
        date: data.secondPaymentDate,
      },
      thirdPaymentDue: {
        amount: parseFloat(data.thirdPaymentDue),
        date: data.thirdPaymentDate,
      },
    };

    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .updatePaymentDues(dataToPost, membership?._id)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Cuotas actualizadas con exito`, 'success');
            handleCloseEditPaymentDueModal();
            reloadMembership();
            // history.push('/services');
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const restorePaymentMethods = () => {
    if (parseFloat(watch3('price')) === 0) {
      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        resetField3(`method${i}`);
        resetField3(`reference${i}`);
        resetField3(`paymentMethod${i}`);
        resetField3(`amount${i}`);
        resetField3(`amountBsS${i}`);
      }
      resetField3('paymentMethods');
    }
    // setValue3()
  };

  const handleAmountChange = (e, index) => {
    const { value } = e?.target;

    setValue3(`amountBsS${index}`, (value * dollarRate)?.toFixed(2));

    if (watch3('paymentType') === paymentType?.PENDING) {
      let quota2USD = parseFloat(watch3('price'));

      if (watch3('paymentMethods') > 1) {
        let amount2 = quota2USD - NaNtoNumber(parseFloat(watch3(`amount1`)));
        if (amount2 > 0 && amount2 < parseFloat(watch3('price'))) {
          setValue3('amount2', amount2);
          setValue3('amountBsS2', (amount2 * dollarRate)?.toFixed(2));
        }
      }

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (
        NaNtoNumber(parseFloat(watch3(`amount1`))) > parseFloat(watch3('price'))
      ) {
        notify('El montos excede el costo de la membresia', 'info');
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        return null;
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (watch3('paymentType') === paymentType?.DE_CONTADO) {
      let quota2USD = parseFloat(watch3('price'));

      setValue3(
        'amount2',
        quota2USD - NaNtoNumber(parseFloat(watch3(`amount1`)))
      );
      setValue3('amountBsS2', (watch3(`amount2`) * dollarRate)?.toFixed(2));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.RENOVACION]?.includes(watch3('modal'))
    ) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD > 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        notify('El monto de la segunda cuota debe ser mayor a 0$', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.PENDIENTE]?.includes(watch3('modal'))
    ) {
      let price = parseFloat(watch3('price'));

      setValue3(`amount2`, price - NaNtoNumber(parseFloat(watch3('amount1'))));
      setValue3(`amountBsS2`, (watch3('amount2') * dollarRate)?.toFixed(2));
    }
  };

  const handlePaymentMethodsChange = () => {
    if (parseFloat(watch3('paymentMethods')) === 2) {
      setValue3('mix', true);
      setValue3('method1', '');
      setValue3('amount1', '');
      setValue3('amountBsS1', '');
      setValue3('method2', '');
      setValue3('amount2', '');
      setValue3('amountBsS2', '');
    } else {
      setValue3('mix', false);
    }
  };

  const handleSecondAmountChange = (e, index) => {
    let method = paymentMethods?.filter(
      o => o?._id === watch3(`method${index}`)
    )[0];

    if (method && method?.currency !== 'BsS' && watch3('mix')) {
      setValue3(
        'amount2IGTF',
        ((watch3('amount2') * returnIGTF()) / 100)?.toFixed(2)
      );
    }

    setValue3('amountBsS2', (watch3('amount2') * dollarRate)?.toFixed(2));

    if (watch3('paymentType') === paymentType?.PENDING) {
      let quota2USD = parseFloat(watch3('price'));

      if (watch3('paymentMethods') > 1) {
        let amount1 = quota2USD - NaNtoNumber(parseFloat(watch3(`amount2`)));
        if (amount1 > 0 && amount1 < parseFloat(watch3('price'))) {
          setValue3('amount1', amount1);
          setValue3('amountBsS1', (watch3(`amount1`) * dollarRate)?.toFixed(2));
        }
      }

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (
        NaNtoNumber(parseFloat(watch3(`amount2`))) > parseFloat(watch3('price'))
      ) {
        notify('El montos excede el costo de la membresia', 'info');
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        return null;
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (watch3('paymentType') === paymentType?.DE_CONTADO) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.RENOVACION]?.includes(watch3('modal'))
    ) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD > 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        notify('El monto de la segunda cuota debe ser mayor a 0$', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.PENDIENTE]?.includes(watch3('modal'))
    ) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD < 0) {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        notify('La suma de los montos supera el costo a facturar', 'info');
      }
    }
  };

  const handleEditAmountChange = () => {
    setValue4('amountBsS', watch4('amount') * dollarRate);
  };

  const handleDateChange = e => {
    setCreationDate(e);
    getDollarRateByDate(e);
  };

  const getDollarRateByDate = date => {
    let params = {
      currency: 'USD',
      date: moment(date).format('YYYY-MM-DD'),
      limit: 20,
    };

    currencyRateService
      .getCurrencyRates(params)
      .then(response => {
        if (response.status === 200) {
          setValue4(
            'amountBsS',
            (watch4('amount') * response?.data?.result[0]?.rate)?.toFixed(2)
          );
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(error => { });
    return true;
  };

  const getDollarRate = async () => {
    rateService
      .getDollarRate()
      .then(response => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setbsDisabled(true);

          if (membership?.paymentType === paymentType?.FINANCIADO) {
            setValue2(
              `paymentDues[0].amountBs`,
              (
                response?.data?.rate * membership?.paymentDues[0]?.amount
              )?.toFixed(2)
            );
            setValue2(
              `paymentDues[1].amountBs`,
              (
                response?.data?.rate * membership?.paymentDues[1]?.amount
              )?.toFixed(2)
            );
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('sales')
      .then(response => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const openMembershipPendingPaymentModal = index => {
    setOpenPayModal(true);
    setValue3('modal', paymentModalTypes?.PENDIENTE);
    setValue3('price', parseFloat(watch2('finalPrice'))?.toFixed(2));
    setValue3('paymentType', watch2('paymentType'));
    setValue3('paymentMethods', 1);

    // Here we preload the payment form depending on the Payment Type
    handlePaymentTypeForm();
    setValue3('mix', false);

    if (membership?.paymentType === paymentType?.FINANCIADO) {
      setValue3('amount1', membership?.paymentDues[1]?.amount?.toFixed(2));
      setValue3('price', membership?.paymentDues[1]?.amount?.toFixed(2));
      setValue3(
        'amountBsS1',
        (membership?.paymentDues[1]?.amount * dollarRate)?.toFixed(2)
      );
    }

    if (membership?.paymentType === paymentType?.PENDING) {
      setValue3('amount1', watch2('finalPrice')?.toFixed(2));
      setValue3('amountBsS1', watch2('finalPrice') * dollarRate)?.toFixed(2);
    }
    if (membership?.paymentType === paymentType?.CASHEA) {
      const CASHEA = filterOption(paymentMethods, 'CASHEA');

      setValue3('method1', CASHEA?._id);
      setValue3('amount1', membership?.paymentDues[index]?.amount?.toFixed(2));
      setValue3('price', membership?.paymentDues[index]?.amount?.toFixed(2));
      setValue3(
        'amountBsS1',
        (membership?.paymentDues[index]?.amount * dollarRate)?.toFixed(2)
      );
    }
  };

  const handleOpenEditPaymentDueModal = () => {
    setOpenEditPaymentDueModal(true);
    if (membership.paymentDues.length > 0) {
      setValue5('finalPrice', getDuefromSuccessPayments(membership));
      setValue5('firstPaymentDue', membership?.paymentDues[1]?.amount);
      setValue5(
        'firstPaymentDate',
        new Date(membership?.paymentDues[1]?.dueDate)
      );
      setValue5('secondPaymentDue', membership?.paymentDues[2]?.amount);
      setValue5(
        'secondPaymentDate',
        new Date(membership?.paymentDues[2]?.dueDate)
      );
      setValue5('thirdPaymentDue', membership?.paymentDues[3]?.amount);
      setValue5(
        'thirdPaymentDate',
        new Date(membership?.paymentDues[3]?.dueDate)
      );
    }
  };

  const handleCloseEditPaymentDueModal = () => {
    setOpenEditPaymentDueModal(false);
  };

  const handlePaymentTypeForm = () => {
    if (watch3('paymentType') === paymentType?.DE_CONTADO) {
      setValue3('paymentMethods', 1);
      setValue3('amount1', watch3('price'));
      setValue3(
        'amountBsS1',
        (NaNtoNumber(parseFloat(watch3('price'))) * dollarRate)?.toFixed(2)
      );
    }

    if (watch3('paymentType') === paymentType?.FINANCIADO) {
      setValue3('paymentMethods', 1);
    }
  };

  const closePaymentModal = () => {
    setOpenPayModal(false);
    setFileUploaded([]);
    reset3();
  };

  const handleEditPaymentModal = payment => {
    setOpenEditPaymentModal(prevState => {
      return {
        ...prevState,
        isOpen: !openEditPaymentModal?.isOpen,
        payment: payment ? payment : '',
      };
    });

    if (payment) {
      setValue4('amount', payment?.totalAmount);
      setValue4('amountBsS', payment?.totalAmountInBsS?.toFixed(2));
      setValue4('method', payment?.methodId);
      setValue4('reference', payment?.reference);
      setValue4('referenceCashea', payment?.referenceCashea);
      let fecha = new Date(payment?.createdAt);
      setCreationDate(fecha?.setDate(fecha.getDate()));
    } else {
      reset4();
    }
  };

  useEffect(() => {
    getDollarRate();
    getPaymentMethods();
    preloadForm();
  }, [open, membership]);

  const editPayment = handleSubmit4(async data => {
    const formData = new FormData();
    formData.append('membership', membership?._id);
    formData.append(`payments[0][id]`, openEditPaymentModal?.payment?._id);
    formData.append(`payments[0][method]`, watch4(`method`));
    formData.append(`payments[0][reference]`, watch4(`reference`));
    formData.append(`payments[0][amount]`, watch4(`amount`));
    formData.append(`payments[0][amountBsS]`, watch4(`amountBsS`));
    formData.append(
      `payments[0][creationDate]`,
      moment(creationDate)?.format('YYYY-MM-DD')
    );
    if (membership?.paymentType === paymentType?.CASHEA) {
      formData.append(
        `payments[0][referenceCashea]`,
        watch4(`referenceCashea`)
      );
    }

    if (!isLoading && formData) {
      setIsLoading(true);
      paymentsService
        .editPayment(formData)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago editado con exito`, 'success');
            handleEditPaymentModal();
            reloadMembership();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const Paymentaction = () => {
    if (watch3('modal') === paymentModalTypes?.PENDIENTE) {
      return payPendingPayment;
    }
    if (watch3('modal') === paymentModalTypes?.RENOVACION) {
      return payRewenMembership;
    }
  };

  // Internal counter cant see affiliation cards
  if (
    [salesRoles?.SALES_COUNTER].includes(authService?.getUserType()) &&
    membership?.membershipStatus === planStatus?.INCOMPLETED &&
    authService?.getUser()?.type === userType?.INTERNO
  ) {
    return null;
  }

  // Admin Aux cant see affiliation card
  if (
    [salesRoles?.SALES_ADMIN_AUX].includes(authService?.getUserType()) &&
    membership?.membershipStatus === planStatus?.INCOMPLETED
  ) {
    return null;
  }

  return (
    <>
      <LoadSpinner show={isLoading} />
      <PaymentModal
        show={openPayModal}
        register={register3}
        openPayModal={openPayModal}
        handleClose={() => closePaymentModal()}
        payRewenMembership={Paymentaction()}
        errors={errors3}
        control={control3}
        setValue={setValue3}
        options={{
          paymentMethods: paymentMethods,
          paymentTypesOptions: [
            ...paymentTypesOptions,
            { name: 'Cashea', _id: 'Cashea' },
          ],
          dollarRate: dollarRate,
        }}
        onChange={{
          handlePaymentTypeForm: handlePaymentTypeForm,
          restorePaymentMethods: restorePaymentMethods,
          handleAmountChange: handleAmountChange,
          handleSecondAmountChange: handleSecondAmountChange,
          handlePaymentMethodsChange: handlePaymentMethodsChange,
        }}
        watch={watch3}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
        membership={membership}
      />

      <EditPaymentDuesModal
        show={openEditPaymentDueModal}
        handleClose={() => handleCloseEditPaymentDueModal()}
        register={register5}
        getValues={getValues5}
        errors={errors5}
        trigger={trigger5}
        watch={watch5}
        setValue={setValue5}
        control={control5}
        reset={reset5}
        action={updatePaymentDues}
      />

      <EditPaymentModal
        modal={openEditPaymentModal}
        register={register4}
        openPayModal={openPayModal}
        action={editPayment}
        handleClose={() => handleEditPaymentModal()}
        payRewenMembership={Paymentaction()}
        paymentType={membership?.paymentType}
        errors={errors4}
        setValue={setValue4}
        control={control4}
        options={{
          paymentMethods: paymentMethods,
          paymentTypesOptions: paymentTypesOptions,
          dollarRate: dollarRate,
        }}
        creationDate={creationDate}
        onChange={{
          handleEditAmountChange: handleEditAmountChange,
          handleDateChange: handleDateChange,
        }}
        watch={watch4}
      />

      <div className="order-card">
        <OderCardHeader open={open} setOpen={setOpen} label={label} />

        {open && (
          <>
            <PaymentsTable
              role={authService?.getUserType()}
              header={
                membership?.paymentType === paymentType?.CASHEA
                  ? casheaPaymentsTableHeader
                  : paymentsTableHeader
              }
              membership={membership}
              handleEditPaymentModal={handleEditPaymentModal}
            />

            <PendingPaymentsTable
              header={pendingPaymentsTableHeader}
              membership={membership}
              dollarRate={dollarRate}
              openMembershipPendingPaymentModal={
                openMembershipPendingPaymentModal
              }
              handleOpenEditPaymentDueModal={() =>
                handleOpenEditPaymentDueModal()
              }
              openEditPaymentDueModal={openEditPaymentDueModal}
            />
          </>
        )}
      </div>
    </>
  );
};

export const FinancedPaymentForm = props => {
  const {
    membership,
    register2,
    errors2,
    watch2,
    options,
    action,
    editPendingPayment,
    handleDueAmountChange = { handleDueAmountChange },
  } = props;

  const getPaymentsRef = (membership, i) => {
    let selectedRef = membership?.payments?.filter(
      p => p?._id === membership?.paymentDues[i]?.payment[0]
    );

    if (selectedRef?.length > 0) {
      return (
        <div className="row">
          <Input
            bootstrap={'col-6'}
            label={'Referencia'}
            type={'text'}
            options={options?.paymentMethods}
            name={`paymentDues[${i}].reference`}
            register={register2}
            placeholder={''}
            errors={errors2?.[`paymentDues[${i}].reference`]?.message}
            disabled={allowEditPayment()}
          />
          <div className="col-6">
            <div className="ref-image-container">
              {selectedRef[0]?.referenceImg && (
                <a
                  target="_blank"
                  href={selectedRef[0]?.referenceImg}
                  rel="noreferrer"
                >
                  <h6>Ver Comprobante</h6>
                </a>
              )}
            </div>
          </div>
          {allowAdmin && (
            <div style={{ marginBottom: '12px' }} className="col-6">
              <ActionButton
                label={'Editar Pago'}
                action={() => editPendingPayment(i)}
              />
            </div>
          )}
        </div>
      );
    } else return null;
  };

  if (membership?.paymentDues?.length > 0) {
    return (
      <>
        {membership?.paymentDues?.map((m, i) => (
          <div className="row">
            <div className="light-gray-line"></div>
            <h6>
              Cuota ({i + 1}){' '}
              {membership?.paymentDues[i].dueDate
                ? moment(membership?.paymentDues[i].dueDate)
                  .format('DD/MM/YYYY')
                : ''}
            </h6>
            <Input
              bootstrap={'col-6'}
              label={'Monto'}
              type={'number'}
              name={`paymentDues[${i}].amount`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].amount`]?.message}
              min={1}
              max={2}
              onChange={handleDueAmountChange(
                watch2(`paymentDues[${i}].amount`),
                watch2(`paymentDues[${i}].payment`),
                i
              )}
              disabled={allowEditPayment()}
            />{' '}
            <Input
              bootstrap={'col-6'}
              label={'Monto bolivares'}
              type={'number'}
              name={`paymentDues[${i}].amountBs`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].amountBs`]?.message}
              min={1}
              max={2}
              disabled={allowEditPayment()}
            />
            <Input
              bootstrap={'col-6'}
              label={'Metodo de Pago'}
              type={'select'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].paymentMethod`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].paymentMethod`]?.message}
              disabled={allowEditPayment()}
            />{' '}
            <Input
              bootstrap={'col-6'}
              label={'Estatus de Pago'}
              type={'text'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].payment`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].payment`]?.message}
              disabled={true}
            />
            {getPaymentsRef(membership, i)}
            <Input
              bootstrap={'col-6'}
              label={'Fecha de Pago'}
              type={'text'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].paymentDate`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].paymentDate`]?.message}
            />
            <Input
              bootstrap={'col-6'}
              label={'Fecha de Corte'}
              type={'text'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].dueDate`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].dueDate`]?.message}
              disabled={true}
            />
            {(m?.payment?.length === 0 || !m?.payment) && (
              <ActionButton label={'Realizar pago pendiente'} action={action} />
            )}
            <br />
          </div>
        ))}
      </>
    );
  } else return null;
};
