import React, { useState, useEffect } from 'react';
import { Header } from '../../../common/Header/Header';
import { vehicleService } from '../../../../services/vehicleService';
import { servicesService } from '../../../../services/servicesService';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../assets/helpers/toast';
import { capitalize } from '../../../../assets/helpers/funciones';
import { VehiclesMembershipTable } from '../../../common/Tables/Tables';
import { vehiclesMembershipTableHeader } from '../../../../assets/helpers/tables';
import { MembershipHistoryModal } from '../../../common/Modals/Modals';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import './OrderComponent.scss';

export const OrderDetail = () => {
  const { membershipId, vehicleId } = useParams();
  const [vehicle, setVehicle] = useState('');
  const [serviceHistory, setServiceHistory] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);
  const history = useHistory();

  const redirectToEdit = (orderId) => {
    history.push(`/afiliations/edit-order/${orderId}`);
  };

  const getMemberships = () => {
    vehicleService
      .getMemberships(vehicleId)
      .then((response) => {
        if (response.status === 200) {
          setVehicle(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getLastServices = (membershipId) => {
    if (membershipId) {
      servicesService
        .getFinishedServices(membershipId)
        .then((response) => {
          if (response.status === 200) {
            setServiceHistory(response?.data);
          } else {
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => { });
    } else {
      setServiceHistory([]);
    }
  };

  const handleHistoryModal = (membership) => {
    setHistoryModal(!historyModal);
    getLastServices(membership?._id);
  };

  useEffect(() => {
    getMemberships();
  }, []);

  return (
    <>
      <Header />
      <MembershipHistoryModal
        modal={historyModal}
        handleModal={handleHistoryModal}
        data={serviceHistory}
      />
      <div className="container">
        <div className="card incidence-card">
          <div className="title-center-component">
            <h4>{vehicle?.vehicle?.plate}</h4>
            <h5>
              {capitalize(vehicle?.customer?.firstName)}{' '}
              {capitalize(vehicle?.customer?.lastName)}
            </h5>
          </div>
          <br />
          <div className="light-gray-line"></div>
          <div className="buttons-center-container">
            <h5>Detalles del Cliente</h5>
          </div>
          <ClientDetail
            vehicle={vehicle?.vehicle}
            customer={vehicle?.customer}
          />
          <br />
          <div className="light-gray-line"></div>
          <br />
          <div className="buttons-center-container">
            <h5>Detalles de Membresias</h5>
          </div>
          <VehiclesMembershipTable
            header={vehiclesMembershipTableHeader}
            data={vehicle?.memberships}
            handleHistoryModal={handleHistoryModal}
            redirectToEdit={redirectToEdit}
          />
          <br />
          <div className="light-gray-line"></div>
          <br />
          <div className="buttons-center-container">
            <h5>Resumen de Membresías</h5>
          </div>

          <div className="renovations-counter-container">
            <h6>
              Renovaciones / rescates realizadas:{' '}
              {vehicle?.affiliationTypeCounter?.rescue}
            </h6>
            <h6>
              Renovaciones continuas: {vehicle?.affiliationTypeCounter?.renewed}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export const ClientDetail = (props) => {
  const { vehicle, customer } = props;
  return (
    <>
      <div className="filter-button-container"></div>
      <div className="buttons-container"></div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Nombre y Apellido:</b> {capitalize(customer?.firstName)}{' '}
            {capitalize(customer?.lastName)}
            <br />
            <b>Identificaión:</b> {customer?.identificationType}-
            {customer?.identification}
            <br />
            <b>Número de Teléfono:</b> {customer?.phonePrefix}-
            {customer?.phoneNumber}
            <br />
            <b>Fecha de Nacimiento:</b>{' '}
            {moment(customer?.birthday).format('DD/MM/YYYY')}
            <br />
            <b>Correo:</b> {customer?.email?.toLowerCase()}
            <br />
          </p>
        </div>
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Dirección :</b>
            <ul>
              <li>
                <b>Estado: </b>
                {capitalize(vehicle?.state)}
              </li>
              <li>
                <b>Ciudad: </b>
                {capitalize(vehicle?.city)}
              </li>{' '}
              <li>
                <b>Municipio: </b>
                {capitalize(vehicle?.municipality)}
              </li>{' '}
              <li>
                <b>Dirección: </b>
                {vehicle?.address} {vehicle?.addressReference}
              </li>{' '}
            </ul>
          </p>
        </div>
        <div className="col-12 col-lg-6 col-xl-4"></div>
      </div>
    </>
  );
};
