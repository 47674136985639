import { useState, useEffect } from 'react';
import { ActionButton } from '../../../common/Buttons/Buttons';
import canje from '../../../../images/canje.svg';
import { capitalize } from '../../../../assets/helpers/funciones';
import moment from 'moment';
import './LandingForms.scss';

export const SuccesPurchase = (props) => {
  const [user, setUser] = useState('');
  const [vehicle, setVehicle] = useState('');

  const preloadData = () => {
    let userData = localStorage.getItem('personalData');
    let vehicleData = localStorage.getItem('vehicle');
    userData = JSON.parse(userData);
    vehicleData = JSON.parse(vehicleData);

    setUser(userData);
    setVehicle(vehicleData);
  };

  const finish = () => {
    window.location.reload();
    localStorage.removeItem('personalData');
    localStorage.removeItem('vehicle');
  };

  useEffect(() => {
    preloadData();
  }, []);

  if (!user || !vehicle) {
    return null;
  } else {
    return (
      <>
        <div className="landing-form-container">
          <img src={canje} alt="" />
          <h2>Registro exitoso!</h2>
          <br />
          <div className="purchase-container">
            <PurchaseSection
              label={'Fecha de Compra'}
              text={moment(new Date()).format('DD-MM-YYYY')}
            />
            <PurchaseSection
              label={'Nombre'}
              text={`${user?.firstName}  ${user?.lastName}`}
            />
            <PurchaseSection label={'E-mail'} text={`${user?.email}`} />
            <PurchaseSection
              label={'Dirección'}
              text={`${capitalize(user?.stateName?.name)}, ${capitalize(
                user?.cityName?.name
              )}, ${capitalize(user?.municipalityName?.name)} ${capitalize(
                user?.address
              )}`}
            />{' '}
            <PurchaseSection
              label={'Vehículo '}
              text={`${vehicle?.modelName} ${vehicle?.brandName} ${vehicle?.year} ${vehicle?.color} ${vehicle?.plate}`}
            />
          </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <ActionButton label={'Finalizar'} action={() => finish()} />
          </div>
        </div>
      </>
    );
  }
};

export const PurchaseSection = (props) => {
  const { label, text } = props;

  if (label && text) {
    return (
      <div className="purchase-section-component">
        <h6>{label}</h6>
        <h6>{text}</h6>
      </div>
    );
  } else return null;
};
