import { useState, useEffect } from 'react';
import { ActionButton } from '../../../common/Buttons/Buttons';
import canje from '../../../../images/canje.svg';
import { capitalize, returnIGTF, NaNtoNumber } from '../../../../assets/helpers/funciones';
import moment from 'moment';
import './LandingForms.scss';

export const SuccesPurchase = (props) => {
  const [user, setUser] = useState('');
  const [payment, setPayment] = useState('');
  const [vehicle, setVehicle] = useState('');

  const preloadData = () => {
    let userData = sessionStorage.getItem('personalData');
    let paymentData = sessionStorage.getItem('payment');
    let vehicleData = sessionStorage.getItem('vehicle');
    userData = JSON.parse(userData);
    paymentData = JSON.parse(paymentData);
    vehicleData = JSON.parse(vehicleData);

    setUser(userData);
    setPayment(paymentData);
    setVehicle(vehicleData);
  };

  const finish = () => {
    window.location.reload();
    sessionStorage.removeItem('personalData');
    sessionStorage.removeItem('payment');
    sessionStorage.removeItem('vehicle');
  };

  useEffect(() => {
    preloadData();
  }, []);

  if (!user || !vehicle) {
    return null;
  } else {
    return (
      <>
        <div className="landing-form-container">
          <img src={canje} alt="" />
          <h2>¡Compra exitosa!</h2>
          <p>Resumen de Compra</p>
          <br />
          <div className="purchase-container">
            <PurchaseSection
              label={'Fecha de Compra'}
              text={moment(new Date()).format('DD-MM-YYYY')}
            />
            <PurchaseSection
              label={'Nombre'}
              text={`${user?.firstName}  ${user?.lastName}`}
            />
            <PurchaseSection label={'E-mail'} text={`${user?.email}`} />
            <PurchaseSection
              label={'Dirección'}
              text={`${capitalize(vehicle?.stateName)}, ${capitalize(
                vehicle?.cityName
              )}, ${capitalize(vehicle?.municipalityName)} ${capitalize(
                vehicle?.address
              )}`}
            />{' '}
            <PurchaseSection
              label={'Vehículo '}
              text={`${vehicle?.modelName} ${vehicle?.brandName} ${vehicle?.year} ${vehicle?.color} ${vehicle?.plate}`}
            />
            {payment && (
              <div>
                <PurchaseSection
                  label={'Tipo de pago'}
                  text={`${payment?.paymentType}`}
                />
                <PurchaseSection
                  label={'Cantidad de Pagos'}
                  text={payment?.allPayments?.length}
                />
                <PaymentSection
                  label={'Método de pago'}
                  payments={payment?.payments}
                />
                <TotalSection label={'Total'} payments={payment?.payments} />
              </div>
            )}
          </div>
          <div style={{ textAlign: "justify", marginTop: "24px", marginBottom: "12px" }} className="purchase-container">
            <h6>Hemos enviado el resumen de tu compra a tu correo electrónico. Por ese medio te notificaremos cuando la compra haya sido aprobada y te enviaremos todos los documentos correspondientes. ¡Gracias por confiar en nosotros, ya puedes manejar tranquilo!</h6>
          </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <ActionButton label={'Finalizar'} action={() => finish()} />
          </div>
        </div>
      </>
    );
  }
};

export const PurchaseSection = (props) => {
  const { label, text } = props;

  if (label && text) {
    return (
      <div className="purchase-section-component">
        <h6>{label}</h6>
        <h6>{text}</h6>
      </div>
    );
  } else return null;
};

export const TotalSection = (props) => {
  const { label, payments } = props;

  let totalPrice = 0;
  let iftg = false;

  // if (payments?.length > 1) {
  for (let i = 0; i < payments.length; i++) {
    if (payments[i]?.igtf) {
      totalPrice = totalPrice + (parseFloat(payments[i]?.amount) + NaNtoNumber(parseFloat(payments[i]?.amountIGTF)));
      parseFloat(payments[i]?.amount);
      iftg = true;
    } else {
      totalPrice = totalPrice + parseFloat(payments[i]?.amount);
    }
    // }
  }
  totalPrice = totalPrice.toFixed(2);

  if (label && payments) {
    return (
      <div className="purchase-section-component">
        <h6>{label}</h6>
        <h6>
          ${totalPrice} {iftg ? ` Incluye IGTF (${returnIGTF()}%)` : ''}
        </h6>
      </div>
    );
  } else return null;
};

export const PaymentSection = (props) => {
  const { label, payments } = props;

  const returnAmmount = (payment) => {
    return "$" + (parseFloat(payment?.amount) + NaNtoNumber(parseFloat(payment?.amountIGTF)))
  }

  if (label && payments) {
    return (
      <div className="purchase-section-component">
        <h6>{label}</h6>
        {/* <h6>{payments?.map()}</h6> */}
        <h6 style={{ textAlign: 'right' }}>
          {payments?.map((p) => (
            <span key={p?.method}>
              {capitalize(p?.methodName) +
                '  ' +
                (p?.currency === 'BsS'
                  ? p?.amountBsS + ' ' + p?.currency
                  : returnAmmount(p))}
              <br></br>
            </span>
          ))}
        </h6>
      </div>
    );
  } else return null;
};
