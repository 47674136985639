import { Input } from '../../../common/Forms/Input/Input';
import { FileComponent } from '../../../common/Dropzone/Dropzone';
import {
  paymentMethodsLimit,
  paymentType as paymentTypeOption,
} from '../../../../assets/helpers/options';
import { casheaQuotaTime } from '../../../../assets/helpers/variables';
import moment from 'moment';

export const SecondQuotaComponent = props => {
  const {
    watch,
    casheaPayment,
    register,
    errors,
    getUSDFromBs,
    getBsfromUSD,
    options,
    secondQuotaTime,
    paymentImage1,
  } = props;

  const quotas = [1, 1, 1];

  if (
    watch('paymentType') === paymentTypeOption?.CASHEA &&
    casheaPayment?.transactionId
  ) {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <h6>
              1° Cuota
              {'  ' +
                moment(new Date().setDate(new Date().getDate()))
                  .format('DD/MM/YYYY')}
            </h6>
          </div>
          <Input
            bootstrap={'col-12 col-md-2'}
            label={'Monto Pago USD *'}
            type={'number'}
            name={'amount1'}
            register={register}
            placeholder={''}
            errors={errors?.amount1?.message}
            disabled={true}
            onChange={getBsfromUSD}
            min={'0'}
          />
          <Input
            bootstrap={'col-12 col-md-2'}
            label={'Monto Pago BsS *'}
            type={'number'}
            name={'amountBsS1'}
            register={register}
            placeholder={''}
            errors={errors?.amountBsS1?.message}
            disabled={true}
            onChange={getUSDFromBs}
            min={'0'}
          />
          <Input
            bootstrap={'col-12 col-md-2'}
            label={'Id de transaccion *'}
            type={'text'}
            name={'reference1'}
            register={register}
            placeholder={''}
            errors={errors?.reference1?.message}
            disabled={true}
            min={'0'}
          />
          <Input
            bootstrap={'col-12 col-md-2'}
            label={'Referencia Cashea *'}
            type={'text'}
            name={'referenceCashea1'}
            register={register}
            placeholder={''}
            errors={errors?.reference1?.message}
            disabled={true}
            min={'0'}
          />
          <Input
            bootstrap={'col-12 col-md-2'}
            label={'Metodo de pago'}
            type={'select'}
            name={'method1'}
            register={register}
            options={options?.paymemtMethodOptions}
            placeholder={''}
            errors={errors?.method1?.message}
            disabled={true}
            min={'0'}
          />
          <div className="col-12 col-md-3">
            {paymentImage1?.name && <FileComponent file={paymentImage1} />}
          </div>{' '}
        </div>

        {quotas.map((quota, i) => (
          <div className="row">
            <div className="col-12">
              <div className="col-12">
                <h4>Fechas de pago</h4>
              </div>
              <h6>
                {i + 2}° Cuota
                {'  ' +
                  moment(
                    new Date().setDate(
                      new Date().getDate() + (i + 1) * casheaQuotaTime
                    )
                  )
                    .format('DD/MM/YYYY')}{' '}
                <span style={{ color: 'red' }}></span>
              </h6>
            </div>
            <Input
              bootstrap={'col-12 col-md-2'}
              label={'Monto Pago 2 USD *'}
              type={'number'}
              name={'financialAmount2'}
              register={register}
              options={paymentMethodsLimit}
              placeholder={''}
              errors={errors?.financialAmount2?.message}
              disabled={true}
              onChange={getBsfromUSD}
              min={'0'}
            />
            <Input
              bootstrap={'col-12 col-md-2'}
              label={'Monto Pago 2 BsS *'}
              type={'number'}
              name={'financialAmountBsS2'}
              register={register}
              options={paymentMethodsLimit}
              placeholder={''}
              errors={errors?.financialAmountBsS2?.message}
              disabled={true}
              onChange={getUSDFromBs}
              min={'0'}
            />
          </div>
        ))}
      </>
    );
  }

  if (watch('paymentType') === paymentTypeOption?.FINANCIADO) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="col-12">
            <h4>Fechas de pago</h4>
          </div>
          <h6>
            2da Cuota
            {'  ' +
              moment(new Date().setDate(new Date().getDate() + secondQuotaTime))
                .format('DD/MM/YYYY')}
          </h6>
        </div>
        <Input
          bootstrap={'col-12 col-md-2'}
          label={'Monto Pago 2 USD *'}
          type={'number'}
          name={'financialAmount2'}
          register={register}
          options={paymentMethodsLimit}
          placeholder={''}
          errors={errors?.financialAmount2?.message}
          disabled={true}
          onChange={getBsfromUSD}
          min={'0'}
        />
        <Input
          bootstrap={'col-12 col-md-2'}
          label={'Monto Pago 2 BsS *'}
          type={'number'}
          name={'financialAmountBsS2'}
          register={register}
          options={paymentMethodsLimit}
          placeholder={''}
          errors={errors?.financialAmountBsS2?.message}
          disabled={true}
          onChange={getUSDFromBs}
          min={'0'}
        />
      </div>
    );
  } else {
    return null;
  }
};
