import membershipClasic from '../../../../images/membershipClasic.png';
import membershipGold from '../../../../images/membershipGold.svg';
import membershipRetro from '../../../../images/membershipRetro.svg';
import membershipFree from '../../../../images/membershipFree.png';
import membershipPlus from '../../../../images/membershipPlus.png';
import membershipMotorider from '../../../../images/membershipMotorider.svg';
import membershipBasico from '../../../../images/membershipBasico.svg';
import membershipRCVElite from '../../../../images/membershipRCVElite.svg';
import rideryLogo from '../../../../images/rideryLogo.svg';
import step1WizSelected from '../../../../images/step1WizSelected.svg';
import step2WizSelected from '../../../../images/step2WizSelected.svg';
import paymentMethodRetail from '../../../../images/paymentMethodRetail.svg';
import motoriderAV from '../../../../images/motoriderAV.svg';
import step3WizSelected from '../../../../images/step3WizSelected.svg';
import step4WizSelected from '../../../../images/step4WizSelected.svg';
import step5WizSelected from '../../../../images/step5WizSelected.svg';
import paymentMethodCash from '../../../../images/paymentMethodCash.svg';
import TDCs from '../../../../images/TDCs.svg';
import stripeLogo from '../../../../images/stripeLogo.svg';
import paymentMethodTDC from '../../../../images/paymentMethodTDC.svg';
import paymentMethodCashea from '../../../../images/paymentMethodCashea.svg';
import paymentMethodPaypal from '../../../../images/paymentMethodPaypal.svg';
import paymentMethodPagoMovil from '../../../../images/paymentMethodPagoMovil.svg';
import paymentMethodPOV from '../../../../images/paymentMethodPOV.svg';
import paymentMethodReserve from '../../../../images/paymentMethodReserve.svg';
import paymentMethodTransfer from '../../../../images/paymentMethodTransfer.svg';
import paymentMethodZelle from '../../../../images/paymentMethodZelle.svg';
import binancePayMethod from '../../../../images/binancePayMethod.svg';
import { paymentMethods, planTypes } from '../../../../assets/helpers/options';
import { returnIGTF } from '../../../../assets/helpers/funciones';
import canje from '../../../../images/canje.svg';
import './LandingComponents.scss';

export const WizzardBar = props => {
  const { step, currentStep } = props;
  let uncompleted = '';

  if (currentStep < step) {
    uncompleted = 'uncompleted';
  }
  return <div className={`wizzard-bar wizzard-bar-${uncompleted}`}></div>;
};

export const WizzardButton = props => {
  const { src, step, currentStep, setcurentStep } = props;

  let uncompleted = '';

  if (currentStep < step) {
    uncompleted = 'uncompleted';
  }

  return (
    <img
      onClick={() => setcurentStep(step)}
      className={`wizzard-logo-button  wizzard-logo-button-${uncompleted}`}
      src={src}
    />
  );
};

export const WizzardProgressBar = props => {
  const { curentStep, setcurentStep } = props;

  return (
    <div className={`wizzard-container`}>
      <WizzardButton
        src={step1WizSelected}
        step={1}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={2} currentStep={curentStep} />
      <WizzardButton
        src={step2WizSelected}
        step={2}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={3} currentStep={curentStep} />
      <WizzardButton
        src={step3WizSelected}
        step={3}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={4} currentStep={curentStep} />
      <WizzardButton
        src={step4WizSelected}
        step={4}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={5} currentStep={curentStep} />
      <WizzardButton
        src={step5WizSelected}
        step={5}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
    </div>
  );
};

export const MembershipButton = props => {
  const { membership, selectedMembership, selectMembership, noPlanAlert } =
    props;
  let selected = '';

  if (membership?._id === selectedMembership?._id) {
    selected = 'selected';
  }

  return (
    <div
      onClick={() =>
        selectMembership(
          membership?._id === selectedMembership?._id ? '' : membership
        )
      }
      className={
        `plan-button-container` +
        ` plan-button-container-${selected}` +
        ` noPlanAlert-${noPlanAlert}`
      }
    >
      <MembershipImage membership={membership} />
      {/* <h6>{membership?.code}</h6> */}
      <h6 style={{ marginTop: '12px' }}>
        {membership?.finalPriceBeforeDiscounts > membership?.finalPrice ? (
          <span style={{ color: 'gray', textDecoration: 'line-through' }}>
            {'   $' + membership?.finalPriceBeforeDiscounts}
          </span>
        ) : (
          ''
        )}
        {membership?.finalPriceBeforeDiscounts > membership?.finalPrice ? (
          <span
            style={{ fontSize: '18px', marginLeft: '12px', color: '#f39158' }}
          >
            {'   $' + membership?.finalPrice?.toFixed(2)}
          </span>
        ) : (
          <>${membership?.finalPrice?.toFixed(2)}</>
        )}
      </h6>
      {membership?.rcvSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo {" "}
          {/* de ${membership?.rcvSurcharge} */}
          por RCV básico
        </h6>
      )}
      {membership?.armorTypeSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo de ${membership?.armorTypeSurcharge} por blindaje del vehiculo
        </h6>
      )}
      {membership?.yearSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo de ${membership?.yearSurcharge} antiguedad del vehiculo
        </h6>
      )}
      {membership?.extendedTowSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo de ${membership?.extendedTowSurcharge} por un servicio de grúa
          extendido
        </h6>
      )}
      {membership?.kmSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo de ${membership?.kmSurcharge} por Km extra
        </h6>
      )}
    </div>
  );
};

export const MembershipImage = props => {
  const { membership } = props;
  let src = '';
  switch (membership?.code) {
    case 'CLASSIC':
      src = membershipClasic;
      break;
    case 'PLUS':
      src = membershipPlus;
      break;
    case 'PLUSF4':
      src = membershipPlus;
      break;
    case planTypes?.GOLDF4:
      src = membershipGold;
      break;
    case 'GOLD':
      src = membershipGold;
      break;
    case 'RETRO':
      src = membershipRetro;
      break;
    case 'MOTORIDER':
      src = membershipMotorider;
      break;
    case 'RCV':
      src = membershipBasico;
      break;
    case 'RCV_ELITE':
      src = membershipRCVElite;
      break;
    case 'RIDERY':
      src = rideryLogo;
      break;
    case 'MOTORIDERAV':
      src = motoriderAV;
      break;
    case 'FREE':
      src = membershipFree;
      break;

    default:
  }
  return <img src={src} alt="plan" className="membership-button-logo" />;
};

export const GiftcardMessage = props => {
  const { giftCard } = props;
  return (
    <div className="gifcard-message-component">
      <img src={canje} alt="plan" />
      <h6>
        ¡Tarjeta válida! Plan: <b>{giftCard?.membership?.code + '  '}</b>
        Adquirida en <b>{giftCard?.channel}</b>
      </h6>
    </div>
  );
};

export const DiscountMessage = props => {
  const { discountCode } = props;
  return (
    <div className="gifcard-message-component">
      <img src={canje} alt="plan" />
      <h6>
        Tienes {discountCode?.discountNumber}
        <b>{discountCode?.discountType === 'Porcentaje' ? '%' : '$'}</b> de
        descuento en los planes{' '}
        <b>
          {discountCode?.appliesToMemberships?.map((m, i) => (
            <span key={m?.code}>
              {m?.code}
              {i < discountCode?.appliesToMemberships?.length - 1 ? ', ' : '.'}
            </span>
          ))}
        </b>
      </h6>
    </div>
  );
};

export const PaymentMethodCard = props => {
  const { method, openModal } = props;
  let igtf = false;

  let src = '';
  switch (method?.name) {
    case paymentMethods?.CASHEA:
      src = paymentMethodCashea;
      igtf = false;
      break;
    case paymentMethods?.TDC:
      src = paymentMethodTDC;
      igtf = true;
      break;
    case paymentMethods?.STRIPE:
      src = paymentMethodTDC;
      igtf = true;

      break;
    case paymentMethods?.PAGO_EN_COMERCIO:
      src = paymentMethodRetail;
      igtf = false;
      break;
    case paymentMethods?.ZELLE:
      src = paymentMethodZelle;
      igtf = true;

      break;
    case paymentMethods?.TRANSFERENCIA_MERCANTIL:
      src = paymentMethodTransfer;
      break;

    case paymentMethods?.TRANSFERENCIA_BANCAMIGA:
      src = paymentMethodTransfer;
      break;
    case paymentMethods?.TRANSFERENCIA_GENERICO:
      src = paymentMethodTransfer;
      break;
    case paymentMethods?.PAGO_MÓVIL_BNC:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.TRANSFERENCIA_BANESCO:
      src = paymentMethodTransfer;
      break;
    case paymentMethods?.PAGO_MÓVIL:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.PAGO_MÓVIL_BANCAMIGA:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.PAGO_MÓVIL_GENERICO:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.PAYPAL:
      src = paymentMethodPaypal;
      igtf = true;

      break;
    case paymentMethods?.RESERVE:
      src = paymentMethodReserve;
      igtf = true;

      break;
    case paymentMethods?.CASH_USD:
      src = paymentMethodCash;
      igtf = true;
      break;
    case paymentMethods?.CASH:
      src = paymentMethodCash;
      igtf = true;
      break;
    case paymentMethods?.CASH_EUR:
      src = paymentMethodCash;
      igtf = true;

      break;
    case paymentMethods?.PUNTO_DE_VENTA:
      src = paymentMethodPOV;
      break;
    case paymentMethods?.BINANCE:
      src = binancePayMethod;
      igtf = true;

      break;
    default:
  }

  const filterMethodName = method => {
    if (
      [
        paymentMethods?.ZELLE,
        paymentMethods?.PAYPAL,
        paymentMethods?.BINANCE,
      ]?.includes(method)
    ) {
      return null;
    } else return method;
  };

  const patchName = method => {
    if (method === paymentMethods?.TDC) {
      return 'PAGO CON TARJETA';
    } else {
      return filterMethodName(method);
    }
  };

  return (
    <div
      onClick={() => openModal()}
      className={`membership-button-container plan-button-container`}
    >
      <img src={src} alt="plan" className="payment-method-button-logo" />
      <h6 style={{ marginTop: '12px' }}>{patchName(method?.name)}</h6>

      {igtf && (
        <div className="membership-button-iftg-label">
          <p>
            <b>IGTF</b>+{returnIGTF()}%
          </p>
        </div>
      )}

      {method?.name === paymentMethods?.STRIPE ? (
        <img src={TDCs} alt="TDCS" className="TDCS" />
      ) : (
        ''
      )}
      {method?.name === paymentMethods?.STRIPE ? (
        <img src={stripeLogo} alt="stripeLogo" className="stripeLogo" />
      ) : (
        ''
      )}
    </div>
  );
};
