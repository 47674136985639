import { useState, useEffect, useRef } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { DiscountCodesTable } from '../../common/Tables/Tables';
import { discountTableHeade } from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { discountCodesServices } from '../../../services/discountCodesServices';
import { saleChannelService } from '../../../services/saleChannelService';
import { campaignService } from '../../../services/campaignService';
import { membershipCatalogService } from '../../../services/membershipCatalogService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { useHistory } from 'react-router-dom';
import { filterParams } from '../../../assets/helpers/funciones';
import {
  codeStatusOptions,
  discountTypeOptions,
  membershipCodes,
  planTypes,
} from '../../../assets/helpers/options';
import {
  CreateDiscountCodeModal,
  EditDiscountCodeModal,
} from '../../common/Modals/Modals';
import moment from 'moment';
import './DiscountCode.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const DiscountCode = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const history = useHistory();

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.filterExportableSchema(t)),
  });

  const [sinceDate, setSinceDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [salesChannelOptions, setSalesChannelOptions] = useState([]);
  const [campaignsOptions, setCampaignsOptions] = useState([]);
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [createCodeModal, setCreateCodeModal] = useState({
    isOpen: false,
  });
  const [editCodeModal, setEditCodeModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    setValue: setValue2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    control: control2,
    reset: reset2,
  } = useForm({
    resolver: yupResolver(schemas.EditDiscountCodeSchema()),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const editCode = handleSubmit2(async data => {
    let dataToPost = { ...data };

    let today = new Date();
    let toDate = new Date(dataToPost?.validTo);

    if (today > toDate) {
      notify('Ingrese una fecha de finalizacion valida', 'info');
      return null;
    }

    dataToPost.validFrom = moment(dataToPost?.validFrom)
      .format('YYYY-MM-DD');
    dataToPost.validFrom = new Date(
      dataToPost.validFrom + 'T' + dataToPost?.fromTime
    );

    dataToPost.validTo = moment(dataToPost?.validTo).format('YYYY-MM-DD');
    dataToPost.validTo = new Date(
      dataToPost.validTo + 'T' + dataToPost?.toTime
    );

    let membresias = [...membershipOptions];
    let values = [
      { name: planTypes?.CLASSIC, value: data?.CLASSIC },
      { name: planTypes?.GOLD, value: data?.GOLD },
      { name: planTypes?.MOTORIDER, value: data?.MOTORIDER },
      { name: planTypes?.PLUS, value: data?.PLUS },
      { name: planTypes?.PLUSF4, value: data?.PLUSF4 },
      { name: planTypes?.GOLDF4, value: data?.GOLDF4 },
      { name: planTypes?.MOTORIDERAV, value: data?.MOTORIDERAV },
      { name: planTypes?.RETRO, value: data?.RETRO },
      { name: planTypes?.GOLDENP, value: data?.GOLDENP },
      { name: planTypes?.F2, value: data?.F2 },
    ];

    let appliesToMemberships = [];

    for (let i = 0; i < membresias?.length; i++) {
      for (let j = 0; j < values.length; j++) {
        if (membresias[i]?.name === values[j]?.name && values[j]?.value) {
          appliesToMemberships.push(membresias[i]?._id);
        }
      }
    }

    dataToPost.appliesToMemberships = appliesToMemberships;

    delete dataToPost.fromTime;
    delete dataToPost.validFrom;
    delete dataToPost.toTime;
    delete dataToPost.discountType;
    delete dataToPost.discountNumber;
    delete dataToPost.campaign;
    delete dataToPost.code;
    delete dataToPost.CLASSIC;
    delete dataToPost.GOLD;
    delete dataToPost.MOTORIDER;
    delete dataToPost.PLUS;
    delete dataToPost.PLUSF4;
    delete dataToPost.MOTORIDERAV;
    delete dataToPost.GOLDF4;
    delete dataToPost.RETRO;
    delete dataToPost.GOLDENP;
    delete dataToPost.F2;

    if (!isLoading) {
      setIsloading(true);
      discountCodesServices
        .editDiscountCodes(
          filterParams(dataToPost),
          editCodeModal?.selected?._id
        )
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Codigo editado con éxito`, 'success');
            setIsloading(false);
            handleEditCodeForm();
            close();
            getCodes();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  });

  const preloadForm = code => {
    setValue2('code', code?.code);
    setValue2('channel', code?.channel?._id);
    setValue2('campaign', code?.campaign?._id);
    setValue2('discountNumber', code?.discountNumber);
    setValue2('discountType', code?.discountType);
    setValue2('status', code?.status);

    setValue2('appliesToNew', code?.appliesToNew);
    setValue2('appliesToRenewal', code?.appliesToRenewal);
    setValue2('validFrom', new Date(code?.validFrom));
    setValue2('validTo', new Date(code?.validFrom));

    for (let i = 0; i < code?.appliesToMemberships?.length; i++) {
      setValue2(code?.appliesToMemberships[i]?.code, true);
    }

    setValue2('fromTime', moment(code?.validFrom).format('HH:mm'));
    setValue2('toTime', moment(code?.validTo).format('HH:mm'));
  };

  const close = () => {
    handleEditCodeForm();
    reset2();
  };


  const selectitemsPerpage = event => {
    setDataTable(prevState => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setDataTable(prevState => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * dataTable?.limit,
      };
    });
  };

  const redirectToOrder = orderId => {
    history.push(`/afiliations/order/${orderId}`);
  };

  useEffect(() => {
    getCodes();
    getSalesChannel();
    getAvailableMemberships();
    getCampaigns();
  }, [
    dataTable?.page,
    dataTable?.offset,
    dataTable?.limit,
    dataTable?.type,
    dataTable?.sinceDate,
    dataTable?.untilDate,
  ]);

  const getSalesChannel = () => {
    saleChannelService
      .getSalesChannel()
      .then(response => {
        if ([200, 201].includes(response?.status)) {
          setSalesChannelOptions(response?.data?.saleChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getCampaigns = () => {
    campaignService
      .getCampaigns()
      .then(response => {
        if ([200, 201].includes(response?.status)) {
          setCampaignsOptions(response?.data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getAvailableMemberships = () => {
    membershipCatalogService
      .getAvailableMemberships()
      .then(response => {
        let data = [];
        for (let i = 0; i < response?.data?.length; i++) {
          data.push({
            name: response?.data[i]?.code,
            _id: response?.data[i]?._id,
          });
        }

        data = data?.filter(
          d =>
            ![
              membershipCodes?.FREE,
              membershipCodes?.RCV,
              membershipCodes?.RCV_ELITE,
            ]?.includes(d?.name)
        );
        if ([200, 201].includes(response?.status)) {
          setMembershipOptions(data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getCodes = () => {
    setDataTable(prevState => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
    };

    discountCodesServices
      .getDiscountCodes(params)
      .then(response => {
        if (response.status === 200) {
          setDataTable(prevState => {
            return {
              ...prevState,
              data: response?.data?.discounts,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable(prevState => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch(error => {
        setDataTable(prevState => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const openDetail = fleet => {
    history.push(`/modules/fleet-register/detail/${fleet?._id}`);
  };

  const handleCreateCodeForm = () => {
    setCreateCodeModal(prevState => {
      return {
        ...prevState,
        isOpen: !createCodeModal?.isOpen,
      };
    });
  };

  const handleEditCodeForm = code => {
    setEditCodeModal(prevState => {
      return {
        ...prevState,
        isOpen: !editCodeModal?.isOpen,
        selected: code ? code : '',
      };
    });
    if (code) {
      preloadForm(code);
    }
  };

  return (
    <>
      <CreateDiscountCodeModal
        modal={createCodeModal}
        handleModal={handleCreateCodeForm}
        getCodes={getCodes}
        options={{
          salesChannelOptions: salesChannelOptions,
          codeStatusOptions: codeStatusOptions,
          discountTypeOptions: discountTypeOptions,
          membershipOptions: membershipOptions,
          campaignsOptions: campaignsOptions,
        }}
      />
      <EditDiscountCodeModal
        modal={editCodeModal}
        handleModal={setEditCodeModal}
        getCodes={getCodes}
        options={{
          salesChannelOptions: salesChannelOptions,
          codeStatusOptions: codeStatusOptions,
          discountTypeOptions: discountTypeOptions,
          membershipOptions: membershipOptions,
          campaignsOptions: campaignsOptions,
        }}
        setValue={setValue2}
        control={control2}
        register={register2}
        errors={errors2}
        close={close}
        action={editCode}
      />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">Códigos de descuento:</h1>
          <ActionButton
            action={() => handleCreateCodeForm()}
            label={'Generar código'}
          />
        </div>
        <div className="breadcrumb-container">
          {/* <div className="exportables-buttons-container"></div> */}
          <div className="exportables-buttons-container">
            <div style={{ marginTop: '12px' }}>
              <SelectPerPage
                label={'Registros por página'}
                options={itemsPerpage}
                value={dataTable?.limit}
                onChange={selectitemsPerpage}
              />
            </div>
          </div>
          <span></span>
        </div>
        <DiscountCodesTable
          header={discountTableHeade}
          data={dataTable}
          handlePageChange={handlePageChange}
          redirectToOrder={redirectToOrder}
          tableRef={tableRef}
          openDetail={openDetail}
          handleEditCodeForm={handleEditCodeForm}
        />
      </div>
    </>
  );
};
