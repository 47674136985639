import { useEffect, useState } from 'react';
import logo from '../../../images/logo.svg';
import home from '../../../images/home.svg';
import services from '../../../images/services.svg';
import config from '../../../images/config.svg';
import plus from '../../../images/plus.svg';
import threedots from '../../../images/threedots.svg';
import navOptionArrow from '../../../images/navOptionArrow.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { membershipService } from '../../../services/membershipService';
import authService from '../../../services/authService';
import {
  capitalize,
  convertToHyphenatedLowerCase,
  renameMembreshipCrossStatus,
} from '../../../assets/helpers/funciones';
import { planStatus } from '../../../assets/helpers/options';
import { notify } from '../../../assets/helpers/toast';
import {
  readRole,
  salesRoles,
  transcriptRoles,
} from '../../../assets/helpers/roles';
import { LogOutModal } from '../../../components/common/Modals/Modals';
import auth from '../../../services/authService';
import './Header.scss';

const GIFTCARDsALES = 'Venta de Giftcards';
const CURRENCYRATE = 'Tasa de cambio';
const RENOVATIONMODULE = 'Módulo de Renovación';

const {
  SALES_DEVELOPER,
  SALES_ADMIN,
  SALES_SUPERVISOR,
  SALES_HUNTER,
  SALES_FARMER,
  SALES_COUNTER,
  SALES_MARKETING,
  SALES_ADMIN_AUX,
  RCV,
} = salesRoles;

const linksVentas = [
  {
    name: 'Dashboard',
    link: 'dashboard',
    src: home,
    levels: [
      {
        name: 'Analíticas',
        url: 'analytics',
        sublevels: [
          {
            name: 'Base de Afiliaciones',
            url: 'afiliations/status/all/none',
            // roles: [SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR],
          },
        ],
        roles: [
          SALES_DEVELOPER,
          SALES_ADMIN,
          SALES_SUPERVISOR,
          SALES_HUNTER,
          SALES_FARMER,
          SALES_COUNTER,
          SALES_ADMIN_AUX,
        ],
      },
      {
        name: 'Módulo de Renovación',
        url: 'afiliations/renewal/all',
        roles: [
          SALES_DEVELOPER,
          SALES_ADMIN,
          SALES_SUPERVISOR,
          SALES_HUNTER,
          SALES_FARMER,
          SALES_COUNTER,
        ],
      },

      {
        name: 'Exportables',
        url: 'exportable',
        roles: [
          SALES_DEVELOPER,
          SALES_ADMIN,
          SALES_SUPERVISOR,
          SALES_HUNTER,
          SALES_FARMER,
          SALES_ADMIN_AUX,
          RCV,
        ],
      },
    ],
    roles: [
      SALES_DEVELOPER,
      SALES_ADMIN,
      SALES_SUPERVISOR,
      SALES_HUNTER,
      SALES_FARMER,
      SALES_COUNTER,
      SALES_ADMIN_AUX,
      RCV,
    ],
  },
  {
    name: 'Módulos',
    link: 'modules',
    src: services,
    roles: [
      SALES_DEVELOPER,
      SALES_ADMIN,
      SALES_SUPERVISOR,
      SALES_HUNTER,
      SALES_FARMER,
      SALES_COUNTER,
      SALES_ADMIN_AUX,
      SALES_MARKETING,
    ],
    levels: [
      {
        name: 'Códigos de Giftcards',
        url: 'giftcard-codes',
        roles: [
          SALES_DEVELOPER,
          SALES_ADMIN,
          SALES_SUPERVISOR,
          SALES_MARKETING,
        ],
      },
      {
        name: GIFTCARDsALES,
        url: 'giftcard-sales',
        roles: [
          SALES_DEVELOPER,
          SALES_ADMIN,
          SALES_SUPERVISOR,
          SALES_MARKETING,
          // Solo Venta
          SALES_ADMIN_AUX,
          SALES_HUNTER,
          SALES_FARMER,
          SALES_COUNTER,
        ],
      },
      {
        name: 'Registro de flota',
        url: 'fleet-register',
        roles: [SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR],
      },
    ],
  },
  {
    name: 'Configuración',
    link: 'config',
    src: config,
    levels: [
      {
        name: 'Historial de Actualizaciones',
        url: 'update-history',
        roles: [SALES_DEVELOPER, SALES_ADMIN],
      },
      {
        name: 'Parametros',
        url: 'parameters',
        roles: [SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR],
      },
      {
        name: CURRENCYRATE,
        url: 'exchange-rate',
        roles: [
          SALES_DEVELOPER,
          SALES_ADMIN,
          SALES_SUPERVISOR,
          SALES_HUNTER,
          SALES_FARMER,
          SALES_COUNTER,
          SALES_ADMIN_AUX,
        ],
      },
      { name: 'Usuarios', url: 'users', roles: [SALES_DEVELOPER, SALES_ADMIN] },
      {
        name: 'Descuentos',
        url: 'discounts',
        roles: [SALES_DEVELOPER, SALES_ADMIN],
      },
      // {
      //   name: 'Gmail Auth',
      //   url: 'email-auth',
      //   roles: [SALES_ADMIN],
      // },
    ],
    roles: [
      SALES_DEVELOPER,
      SALES_ADMIN,
      SALES_SUPERVISOR,
      SALES_HUNTER,
      SALES_FARMER,
      SALES_COUNTER,
      SALES_ADMIN_AUX,
    ],
  },
  {
    name: 'Nueva afiliación',
    link: 'new-membership',
    src: plus,
    roles: [
      SALES_DEVELOPER,
      SALES_ADMIN,
      SALES_SUPERVISOR,
      SALES_HUNTER,
      SALES_FARMER,
      SALES_COUNTER,
      SALES_ADMIN_AUX,
    ],
  },
];

export const LevelComponent = props => {
  const { history, link, level, closeFirstLevel, userRole } = props;

  const redirectToFirstLevel = () => {
    history.push(`/` + link.link + `/${level?.url}`);
    closeFirstLevel();
  };

  const redirectToSecondLevel = sublevel => {
    history.push(`/` + sublevel.url);
    closeFirstLevel();
    window.location.reload();
  };
  // Si el subnivel tiene roles asignados y no incluye alguno en si lista no muestra el subnivel
  //si el subnivel no tiene roles asignados muestralo siempre
  if (level.roles?.length > 0 && !level.roles.includes(auth.getUserType())) {
    return null;
  }

  // Counter interno no puede ver el modulo de renovacion
  // if (
  //   [userRole?.roles[0]?.code].includes(SALES_COUNTER) &&
  //   userRole?.type === 'Interno' &&
  //   [RENOVATIONMODULE]?.includes(level?.name)
  // ) {
  //   return null;
  // }
  // Counter Externo no puede "cambio de tasa" y "venta de gift cards"
  if (
    [userRole?.roles[0]?.code].includes(SALES_COUNTER) &&
    userRole?.type === 'Externo' &&
    [GIFTCARDsALES, CURRENCYRATE]?.includes(level?.name)
  ) {
    return null;
  }

  return (
    <div>
      <div
        onClick={() => redirectToFirstLevel()}
        className="header-sublevel-option"
        key={level.name}
      >
        {level.name}
      </div>
      {level?.sublevels?.map(s => (
        <SubLevelComponent action={redirectToSecondLevel} sublevel={s} />
      ))}
    </div>
  );
};

export const SubLevelComponent = props => {
  const { action, sublevel } = props;

  if (
    sublevel?.roles?.length > 0 &&
    !sublevel?.roles?.includes(auth.getUserType())
  ) {
    return null;
  } else {
    return (
      <div
        onClick={() => action(sublevel)}
        className="header-sublevel-option"
        style={{ fontSize: '12px', textAlign: 'left' }}
        key={sublevel.name}
      >
        - {sublevel.name}
      </div>
    );
  }
};

export const LinkComponent = props => {
  const { history, index, link, openFirstLevel, closeFirstLevel, userRole } =
    props;

  let selected = '';

  if (window?.location?.href?.includes(link.link)) {
    selected = true;
  }

  // Desbloquear para esconder los elementos del header segun rol

  // if (!link?.roles?.includes(auth.getUserType())) {
  //   return null;
  // }

  if (link?.roles?.length > 0 && !link?.roles?.includes(auth.getUserType())) {
    return null;
  }
  if (link?.levels?.length > 0) {
    return (
      <>
        {link?.isOpen && (
          <div
            className="header-sublevel-container"
            onClick={() => closeFirstLevel()}
          ></div>
        )}
        <div
          className={`link-component link-component-${selected}`}
          onClick={
            link.isOpen ? () => closeFirstLevel() : () => openFirstLevel(index)
          }
        >
          <img className="link-component-logo" src={link.src} alt="icon" />
          <span>{link.name}</span>
          <img
            className="link-component-arrow"
            src={navOptionArrow}
            alt="navOptionArrow"
          />

          {link?.isOpen && (
            <div className="header-sublevel-section">
              {link.levels?.map(l => (
                <LevelComponent
                  key={l.url}
                  link={link}
                  level={l}
                  closeFirstLevel={closeFirstLevel}
                  history={history}
                  userRole={userRole}
                />
              ))}
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div
        className={`link-component link-component-${selected}`}
        onClick={() => closeFirstLevel()}
      >
        <NavLink to={`/` + link.link}>
          <img className="link-component-logo" src={link.src} alt="icon" />
          <span>{link.name}</span>
        </NavLink>
      </div>
    );
  }
};

export const Header = () => {
  const history = useHistory();
  const [linkOptions, setLinkOptions] = useState(linksVentas);
  const [userRole, setUserRole] = useState('');
  const [logOutModal, setLogOutModal] = useState({
    isOpen: false,
  });

  let user = localStorage.getItem('user');
  user = JSON.parse(user);

  const openFirstLevel = index => {
    let links = linkOptions;
    for (let i = 0; i < links?.length; i++) {
      links[i].isOpen = false;
    }
    links[index].isOpen = true;

    setLinkOptions(prevState => {
      return [...links];
    });
  };

  const closeFirstLevel = index => {
    let links = linkOptions;
    for (let i = 0; i < links?.length; i++) {
      links[i].isOpen = false;
    }

    setLinkOptions(prevState => {
      return [...links];
    });
  };

  const redirectHome = () => {
    if (history.location.pathname === '/dashboard/analytics') {
      window.location.reload();
    } else {
      history.push('/dashboard/analytics');
    }
  };

  const getStatus = () => {
    let links = [...linksVentas];

    if (auth.getUserType()) {
      membershipService
        .getStatuses()
        .then(response => {
          if (response.status === 200) {
            let data = [];
            for (let i = 0; i < response?.data.length; i++) {
              if (
                ![
                  'ESPERANDO VENCIMIENTO',
                  'DENEGADO',
                  'RESCATADO',
                  'RENOVADO',
                  planStatus?.INCOMPLETED,
                  planStatus?.WAITING,
                ].includes(response?.data[i]?.name)
              ) {
                data.push({
                  url: `afiliations/status/${convertToHyphenatedLowerCase(
                    renameMembreshipCrossStatus(response?.data[i]?.name)
                  )}/${response?.data[i]?._id}`,
                  name: capitalize(
                    renameMembreshipCrossStatus(response?.data[i]?.name)
                  ),
                });
              }
            }

            links[0].levels[0].sublevels = [
              links[0]?.levels[0]?.sublevels[0],
              ...data,
            ];
            setLinkOptions(links);
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => {});
      return true;
    }
  };

  const handleLogoutModal = () => {
    setLogOutModal(prevState => {
      return {
        ...prevState,
        isOpen: !logOutModal?.isOpen,
      };
    });
  };
  const signOut = () => {
    handleLogoutModal();
    localStorage.clear();
    history.push('/login');
  };

  const getUser = () => {
    const response = authService?.getUser();
    setUserRole(response);
  };

  useEffect(() => {
    getUser();
    getStatus();
  }, []);

  if (!user?.firstName) {
    return null;
  } else {
    return (
      <>
        <LogOutModal
          modal={logOutModal}
          handleModal={handleLogoutModal}
          action={signOut}
        />
        <div className="header-component">
          <div className="header-links-container">
            <div className="navbar-options-container">
              <img
                onClick={() => redirectHome()}
                src={logo}
                alt="logo"
                className="header-logo"
              />
              {linkOptions?.map((l, i) => (
                <LinkComponent
                  key={l.name}
                  index={i}
                  link={l}
                  openFirstLevel={openFirstLevel}
                  closeFirstLevel={closeFirstLevel}
                  history={history}
                  userRole={userRole}
                />
              ))}
            </div>
            <div className="header-name-settins-container">
              <h6>
                {user?.firstName} {user?.lastName} (
                {transcriptRoles(user?.roles[0]?.code)})
                <span
                  style={{ marginLeft: '12px', textTransform: 'uppercase' }}
                >
                  {readRole(user?.roles[0]?.code)}
                </span>
              </h6>
              <img
                onClick={() => handleLogoutModal()}
                src={threedots}
                alt={'threedots'}
              ></img>
            </div>
          </div>
        </div>
      </>
    );
  }
};
