import { useCallback } from "react";
import { Input } from "../Input/Input";
import copyImage from "../../../../images/copyImage.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notify } from "../../../../assets/helpers/toast";
import { Dropzone, FileComponent } from "../../../common/Dropzone/Dropzone";
import { useDropzone } from "react-dropzone";
import { IGTFMessage } from "./IGTFMessage";
import {
  NaNtoNumber,
  filterOption,
  validateIfShowPM,
} from "../../../../assets/helpers/funciones";
import { paymentType } from "../../../../assets/helpers/options";
import {
  paymentMethods,
  initialOptions,
  banksOptions,
} from "../../../../assets/helpers/options";
import "./NewPaymentForm.scss";

export const PaymentSection1 = (props) => {
  const {
    register,
    options,
    watch,
    errors,
    fileUploaded,
    setFileUploaded,
    returnAccountData,
    index,
    onChange,
    acceptIGTFMethod,
    denyIGTFMethod,
    control,
    membership,
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        !["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
          file.type
        )
      ) {
        notify("Agregar un formato valido de imagen", "error");
        return null;
      }
      let maxWeight = 3;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, "error");
      } else {
        let files = fileUploaded;
        files[index - 1] = acceptedFiles[0];

        setFileUploaded([...files]);
      }
    });
  }, []);

  const removeFile = (index) => {
    let files = fileUploaded;
    files[index] = "";
    setFileUploaded([...files]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const calculateTotalAmount1 = () => {
    let amount = 0;
    let amount1IGTF = NaNtoNumber(parseFloat(watch("amount1IGTF")));
    let amount1 = NaNtoNumber(parseFloat(watch("amount1")));

    if (watch("amount1")) {
      amount = amount1IGTF + amount1;
      return amount?.toFixed(2);
    }
  };

  const filterPaymentMethods = (options) => {
    let filteredOptions = options?.filter(
      (o) =>
        ![
          paymentMethods?.CASH,
          paymentMethods?.CASH_BS,
          paymentMethods?.CASH_EUR,
          paymentMethods?.CASHEA,
          // paymentMethods?.STRIPE,
        ]?.includes(o?.name)
    );

    return filteredOptions;
  };

  return (
    <>
      <hr />
      {watch(`method${index}`) ? (
        <CopyToClipboard
          text={returnAccountData(
            options.paymentMethods,
            watch(`method${index}`)
          )}
        >
          <div className="col-2 copy-payment-data-image-button-container">
            <img
              className="copy-payment-data-image-button"
              src={copyImage}
              alt="copyImage"
            />
            <h6>Copiar datos de pago</h6>
          </div>
        </CopyToClipboard>
      ) : (
        <div className="col-2"></div>
      )}

      <Input
        bootstrap={"col-2"}
        label={`Metodo ${index} *`}
        type={"select"}
        name={`method${index}`}
        register={register}
        placeholder={""}
        options={filterPaymentMethods(options?.paymentMethods)}
        errors={errors?.[`method${index}`]?.message}
        disabled={false}
        onChange={() =>
          onChange?.handleIGTF(
            options?.paymentMethods?.filter(
              (o) => o?._id === watch(`method${index}`)
            )[0]
          )
        }
      />
      {watch("amount1IGTF") && (
        <Input
          bootstrap={"col-12 col-md-2"}
          label={"IGTF Pago 1 USD *"}
          type={"number"}
          name={"amount1IGTF"}
          register={register}
          placeholder={""}
          errors={errors?.amount1IGTF?.message}
          disabled={true}
          min={"0"}
        />
      )}

      {watch("paymentType") === paymentType?.CASHEA && (
        <Input
          bootstrap={"col-12 col-md-2"}
          label={"Inicial *"}
          type={"select"}
          name={"initial"}
          options={initialOptions?.filter((o, i) => i !== 0)}
          register={register}
          placeholder={""}
          onChange={(e) => onChange?.handleCasheaAmount()}
          errors={errors?.initial?.message}
          min={"0"}
        />
      )}

      {watch("paymentType") === paymentType?.CASHEA && (
        <Input
          bootstrap={"col-2"}
          label={`Referencia de Cashea *`}
          type={"text"}
          name={`referenceCashea`}
          register={register}
          placeholder={""}
          errors={errors?.[`referenceCashea`]?.message}
          disabled={
            watch(`referenceCashea`) &&
            watch("paymentType") !== paymentType?.CASHEA
          }
          min={"0"}
        />
      )}

      {watch("paymentType") === paymentType?.CASHEA &&
        membership.payments.length === 0 && (
          <Input
            bootstrap={"col-2"}
            label={`Referencia de Cashea *`}
            type={"text"}
            name={`referenceCashea`}
            register={register}
            placeholder={""}
            errors={errors?.[`referenceCashea`]?.message}
            disabled={
              watch(`referenceCashea`) &&
              watch("paymentType") !== paymentType?.CASHEA
            }
            min={"0"}
          />
        )}
      {watch(`method${index}`) === "646955f7fccc5f07cd2b8d48" && (
        <Input
          bootstrap={"col-2"}
          label={`Nombre de titular Zelle *`}
          type={"text"}
          name={`zelleOwner1`}
          register={register}
          placeholder={""}
          errors={errors?.[`zelleOwner0`]?.message}
          disabled={
            watch(`zelleOwner0`) &&
            watch(`method${index}`) !== "646955f7fccc5f07cd2b8d48"
          }
          min={"0"}
        />
      )}

      <Input
        bootstrap={"col-2"}
        label={`Monto ${index} (USD) *`}
        type={"number"}
        name={`amount${index}`}
        register={register}
        placeholder={""}
        errors={errors?.[`amount${index}`]?.message}
        onChange={(e) => onChange?.handleAmountChange(e, index)}
        disabled={
          (watch(`reference${index}`) && fileUploaded[index - 1]) ||
          watch("amount1IGTF") ||
          watch("amount1_2IGTF") ||
          (watch("paymentMethods") == "1" &&
            watch("paymentType") !== paymentType?.FINANCIADO)
        }
        min={"0"}
      />
      <Input
        bootstrap={"col-2"}
        label={`Monto ${index} (Bs) *`}
        type={"number"}
        name={`amountBsS${index}`}
        register={register}
        placeholder={""}
        errors={errors?.[`amountBsS${index}`]?.message}
        disabled={
          (watch(`reference${index}`) && fileUploaded[index - 1]) ||
          watch("amount1IGTF") ||
          watch("amount1_2IGTF") ||
          (watch("paymentMethods") == "1" &&
            watch("paymentType") !== paymentType?.FINANCIADO)
        }
        min={"0"}
      />
      {validateIfShowPM(options?.paymentMethods, watch(`method${index}`)) && (
        <>
          <Input
            bootstrap={"col-2"}
            label={"Nro. Teléfono asociado a pago móvil"}
            type={"number"}
            name={`phone${index}`}
            placeholder={"4143579865"}
            register={register}
            errors={errors?.[`phone${index}`]?.message}
            disabled={false}
          />
          <Input
            bootstrap={"col-2"}
            label={"Fecha de Pago"}
            type={"date"}
            name={`date${index}`}
            register={register}
            errors={errors?.[`date${index}`]?.message}
            control={control}
            disabled={false}
          />
          <Input
            bootstrap={"col-2"}
            label={"¿Qué banco utilizaste?"}
            type={"select"}
            name={`bank${index}`}
            register={register}
            placeholder={""}
            options={banksOptions}
            errors={errors?.[`bank${index}`]?.message}
            disabled={false}
          />
        </>
      )}

      {watch("method1") &&
        watch("method1") ===
          filterOption(options?.paymentMethods, paymentMethods?.CASH_USD)
            ?._id &&
        !watch("ShowIGTFMoldal") && (
          <Input
            bootstrap={"col-2"}
            label={`Monto Recibido $ *`}
            type={"number"}
            name={`paid`}
            register={register}
            placeholder={""}
            errors={errors?.[`paid`]?.message}
          />
        )}

      {watch("ShowIGTFMoldal") && (
        <div className="col-12">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <IGTFMessage
                amount={watch(`amount${1}`)}
                method={
                  options?.paymentMethods?.filter(
                    (o) => o?._id === watch(`method${1}`)
                  )[0]
                }
                acceptIGTFMethod={acceptIGTFMethod}
                denyIGTFMethod={denyIGTFMethod}
              />
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      )}
      {watch(`method${index}`) && (
        <RefPaymentSecion1
          index={index}
          calculateTotalAmount1={calculateTotalAmount1}
          register={register}
          errors={errors}
          watch={watch}
          fileUploaded={fileUploaded}
          removeFile={removeFile}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          options={options}
          method={
            options?.paymentMethods?.filter(
              (o) => o?._id === watch(`method${index}`)
            )[0]
          }
        />
      )}
    </>
  );
};

export const RefPaymentSecion1 = (props) => {
  const {
    index,
    calculateTotalAmount1,
    register,
    errors,
    fileUploaded,
    removeFile,
    getRootProps,
    getInputProps,
    method,
    watch,
    options,
  } = props;

  const validateIfShowImage = () => {
    let method = options?.paymentMethods?.filter(
      (o) => o?._id === watch(`method${index}`)
    )[0];

    return method?.name;
  };

  if (!watch("ShowIGTFMoldal") || method?.currency === "BsS") {
    return (
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <div className="new-payment-amount-component">
              <h3>Monto 1 total:</h3>
              <div>
                <h3>
                  {calculateTotalAmount1()
                    ? calculateTotalAmount1() + " $"
                    : ""}
                </h3>
                <h3>
                  {calculateTotalAmount1()
                    ? (calculateTotalAmount1() * options?.dollarRate)?.toFixed(
                        2
                      ) + " BsS"
                    : ""}
                </h3>
              </div>
            </div>
          </div>
          <Input
            bootstrap={"col-2"}
            label={`Referencia ${index} *`}
            type={"text"}
            name={`reference${index}`}
            register={register}
            placeholder={""}
            errors={errors?.[`reference${index}`]?.message}
            disabled={
              watch(`reference${index}`) &&
              fileUploaded[index - 1] &&
              watch("paymentType") !== paymentType?.CASHEA
            }
            min={"0"}
          />

          {/* {![paymentMethods?.TDC]?.includes(validateIfShowImage()) && ( */}
          <div className="col-3">
            <label className="dropzone-label">{"Comprobante"}</label>
            {fileUploaded[index - 1] ? (
              <FileComponent
                file={fileUploaded[index - 1]}
                remove={() => removeFile(index - 1)}
              />
            ) : (
              <Dropzone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                message={"(PNG, JPG, JEPG, máx. 3MB)"}
              />
            )}
          </div>
          {/* )} */}
          <div className="col-3"></div>
        </div>
      </div>
    );
  } else return true;
};
